import styled from 'styled-components';

export const SaveButton = styled.button`
  cursor: pointer;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;

  outline: 1px transparent;
  padding: 0 8px;
  height: 34px;
  color: #e0e0e0;
  background: transparent;
  background: rgba(255, 255, 255, 0.08);
  margin-left: 16px;

  :hover {
    background: rgba(255, 255, 255, 0.1);
  }

  span {
    margin: 0 2px 0 4px;
    font-size: 12px;
  }

  &:disabled {
    background: none !important;
    cursor: not-allowed;
  }
`;

export const MenuToggleButton = styled.button`
  color: #e5e5e5;
  background-color: #333;
  border: 1px solid #444;
  border-left: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 36px;
  cursor: pointer;
  transition: color 150ms;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transform: rotate(90deg);
  }

  &:hover {
    color: #f5f5f5;
    background-color: #363636;
  }

  &:active {
    color: #008cea;
  }
`;

interface MenuProps {
  isOpen: boolean;
}

export const Menu = styled.div<MenuProps>`
  position: absolute;
  z-index: -1;
  top: 44px;
  left: 260px;
  color: #e5e5e5;
  background-color: #333;
  border: 1px solid #444;
  border-radius: 6px;
  min-width: 100px;
  padding: 4px 0;
  display: flex;
  flex-direction: column;

  transition: transform 0.2s;
  transform: ${({ isOpen }) => (isOpen ? 'none' : 'scaleY(0)')};
  transform-origin: 0 0;

  :after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 34px;
    margin-left: -10px;
    border: 10px solid transparent;
    border-bottom-color: #333;
  }
`;

export const MenuButton = styled.button`
  color: #e5e5e5;
  background-color: #333;
  border: none;
  width: 100%;
  padding: 8px 40px 8px 16px;
  display: flex;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: #f5f5f5;
    background-color: #555;
  }

  &:active {
    color: #008cea;
  }

  &:disabled {
    color: #aaa;
    background-color: #333;
  }
`;

import { useEffect } from 'react';
import Navbar from 'features/editor/components/Navbar';
import LeftPanel from 'features/editor/components/LeftPanel';
import ViewContainer from 'features/editor/components/ViewContainer';
import NewFileModal from 'features/editor/components/NewFileModal';
import MouseTooltip from 'features/editor/components/MouseTooltip';
import ZoomControls from 'features/editor/components/ZoomControls';
import GridLineToggle from 'features/editor/components/GridLineToggle';
import UndoRedoButtons from 'features/editor/components/UndoRedoButtons';
import ObjectPanel from 'features/editor/components/ObjectPanel';
import ExportPanel from 'features/editor/components/ExportPanel';
import PanModeToggle from 'features/editor/components/PanModeToggle';
import { handleWheelEvent } from 'features/editor/utils/EventListeners';

const checkIfTextItem = (
  target: HTMLElement,
  maxDepth: number,
  curDepth?: number
) => {
  if ((curDepth || 0) >= maxDepth) {
    return (
      target?.classList?.contains('text-item-wrapper') ||
      target?.classList?.contains('editor-input')
    );
  }

  if (
    target?.classList?.contains('text-item-wrapper') ||
    target?.classList?.contains('editor-input')
  ) {
    return true;
  }

  const parent = target.parentNode as HTMLElement | null;
  if (!parent) return false;
  const rec = checkIfTextItem(parent, maxDepth, (curDepth || 0) + 1) as boolean;

  return rec;
};

export const Workspace = () => {
  useEffect(() => {
    document.addEventListener(
      'gesturestart',
      (e) => {
        e.preventDefault();
      },
      { passive: false }
    );
    document.addEventListener(
      'wheel',
      (e) => {
        if (checkIfTextItem(e.target as HTMLElement, 4)) {
          handleWheelEvent(e);
          return;
        }
        e.preventDefault();
      },
      { passive: false }
    );
    return () => {
      document.addEventListener(
        'gesturestart',
        (e) => {
          e.preventDefault();
        },
        { passive: false }
      );
      document.removeEventListener('wheel', (e) => {
        e.preventDefault();
      });
    };
  }, []);
  return (
    <>
      <Navbar />
      <LeftPanel />
      <ObjectPanel />
      <ExportPanel />
      <ViewContainer />
      <NewFileModal />
      <ZoomControls />
      <GridLineToggle />
      <UndoRedoButtons />
      <PanModeToggle />
      <MouseTooltip />
    </>
  );
};

import { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocalStore } from 'state';
import { TextItemMap } from 'state/models/TextItems';
import TextItem from '../TextItem';
import { blurAll } from 'helpers/blurAll';
import { SaveStatus } from 'state/types';
import { trackTextUpdated } from 'analytics/editor';

const TextItems = () => {
  const [focusedTextItem, setFocusedTextItem] = useState<string | null>(null);
  const { file, overridedTextItems, applyOverrides, setSaveStatus } =
    useLocalStore();
  const textItems = file.undoable.textItems;

  const onFocus = () => {
    if (document.activeElement?.classList.contains('editor-input')) {
      const itemId = document.activeElement.id;
      setFocusedTextItem(itemId);
    }
  };

  const onBlur = useCallback(() => {
    // check for overrides
    if (focusedTextItem) {
      const focusedItemId = focusedTextItem;

      const initialData = textItems?.getTextItemData(focusedItemId);
      const overrideData = overridedTextItems.getTextItemData(focusedItemId);

      if (!overrideData) {
        setFocusedTextItem(null);
        return;
      }

      if (!overrideData.textContent) {
        if (initialData) setSaveStatus(SaveStatus.unsaved);
        textItems?.deleteTextItem(focusedItemId);
        overridedTextItems.clear();
        setFocusedTextItem(null);
        return;
      }

      // checking equality by comparing only text content will have issues
      const isEqual = initialData?.textContent === overrideData.textContent;

      if (!isEqual) {
        trackTextUpdated({ textContent: overrideData.textContent });
        setSaveStatus(SaveStatus.unsaved);
        applyOverrides();
      }
      setFocusedTextItem(null);
      blurAll();
    }
  }, [
    applyOverrides,
    focusedTextItem,
    overridedTextItems,
    setSaveStatus,
    textItems,
  ]);

  useEffect(() => {
    window.addEventListener('focus', onFocus, true);
    window.addEventListener('blur', onBlur, true);

    return () => {
      window.removeEventListener('focus', onFocus, true);
      window.removeEventListener('blur', onBlur, true);
    };
  }, [onBlur]);

  if (!textItems && !overridedTextItems) {
    return null;
  }

  return (
    <>
      {textItems &&
        Object.values(JSON.parse(textItems.json) as TextItemMap).map((item) => {
          const isFocused = focusedTextItem === item.id;
          return <TextItem key={item.id} item={item} isFocused={isFocused} />;
        })}

      {Object.values(JSON.parse(overridedTextItems.json) as TextItemMap)
        .filter((i) => !textItems?.getTextItemData(i.id))
        .map((item) => {
          const isFocused = focusedTextItem === item.id;
          return (
            <TextItem
              key={item.id}
              item={item}
              isFocused={isFocused}
              isOverride={true}
            />
          );
        })}
    </>
  );
};

export default observer(TextItems);

import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Stairs = <ObjectMap>{
  STAIRS_11_TREAD: {
    key: 'STAIRS_11_TREAD',
    title: 'Stairs',
    variant: '11 treads',
    type: ObjectType.stairs,
    snapX: ObjectSnap.grid,
    snapY: ObjectSnap.grid,
    snapRotation: 0,
    sizes: {
      us: [{ label: "8' ceiling", width: 36, height: 108 }],
      metric: [{ label: '2.4m ceiling', width: 914, height: 2740 }],
    },
  },
  STAIRS_12_TREAD: {
    key: 'STAIRS_12_TREAD',
    title: 'Stairs',
    variant: '12 treads',
    type: ObjectType.stairs,
    snapX: ObjectSnap.grid,
    snapY: ObjectSnap.grid,
    snapRotation: 0,
    sizes: {
      us: [{ label: "9' ceiling", width: 36, height: 120 }],
      metric: [{ label: '2.7m ceiling', width: 914, height: 3040 }],
    },
  },
  STAIRS_13_TREAD: {
    key: 'STAIRS_13_TREAD',
    title: 'Stairs',
    variant: '13 treads',
    type: ObjectType.stairs,
    snapX: ObjectSnap.grid,
    snapY: ObjectSnap.grid,
    snapRotation: 0,
    sizes: {
      us: [{ label: "10' ceiling", width: 36, height: 132 }],
      metric: [{ label: '3m ceiling', width: 914, height: 3350 }],
    },
  },
  STAIRS_2_TREAD: {
    key: 'STAIRS_2_TREAD',
    title: 'Stairs',
    variant: '2 treads',
    type: ObjectType.stairs,
    snapX: ObjectSnap.grid,
    snapY: ObjectSnap.grid,
    snapRotation: 0,
    sizes: {
      us: [{ label: '20" run', width: 36, height: 20 }],
      metric: [{ label: '50 cm run', width: 914, height: 500 }],
    },
  },
  STAIRS_3_TREAD: {
    key: 'STAIRS_3_TREAD',
    title: 'Stairs',
    variant: '3 treads',
    type: ObjectType.stairs,
    snapX: ObjectSnap.grid,
    snapY: ObjectSnap.grid,
    snapRotation: 0,
    sizes: {
      us: [{ label: '30" run', width: 36, height: 30 }],
      metric: [{ label: '76 cm run', width: 914, height: 760 }],
    },
  },
  STAIRS_5_TREAD: {
    key: 'STAIRS_5_TREAD',
    title: 'Stairs',
    variant: '5 treads',
    type: ObjectType.stairs,
    snapX: ObjectSnap.grid,
    snapY: ObjectSnap.grid,
    snapRotation: 0,
    sizes: {
      us: [{ label: '48" run', width: 36, height: 48 }],
      metric: [{ label: '120 cm run', width: 914, height: 1210 }],
    },
  },
  STAIRS_SPIRAL: {
    key: 'STAIRS_SPIRAL',
    title: 'Spiral Stairs',
    type: ObjectType.stairs,
    snapX: ObjectSnap.grid,
    snapY: ObjectSnap.grid,
    snapRotation: 0,
    lockHeight: true,
    preserveAspectRatio: true,
    sizes: {
      us: [
        { label: 'd: 42"', width: 42, height: 42 },
        { label: 'd: 48"', width: 48, height: 48 },
        { label: 'd: 54"', width: 54, height: 54 },
        { label: 'd: 60"', width: 60, height: 60 },
        { label: 'd: 66"', width: 66, height: 66 },
        { label: 'd: 72"', width: 72, height: 72 },
      ],
      metric: [
        { label: 'd: 107 cm', width: 1070, height: 1070 },
        { label: 'd: 122 cm', width: 1220, height: 1220 },
        { label: 'd: 137 cm', width: 1370, height: 1370 },
        { label: 'd: 152 cm', width: 1520, height: 1520 },
        { label: 'd: 168 cm', width: 1680, height: 1680 },
        { label: 'd: 183 cm', width: 1830, height: 1830 },
      ],
    },
  },
  STAIRS_LANDING: {
    key: 'STAIRS_LANDING',
    title: 'Stair Landing',
    type: ObjectType.stairs,
    snapX: ObjectSnap.grid,
    snapY: ObjectSnap.grid,
    snapRotation: 0,
    sizes: {
      us: [
        { label: 'l: 36", w: 36"', width: 36, height: 36 },
        { label: 'l: 72", w: 36"', width: 36, height: 72 },
      ],
      metric: [
        { label: 'l: 91 cm, w: 91 cm', width: 914, height: 914 },
        { label: 'l: 183 cm, w: 91 cm', width: 914, height: 1829 },
      ],
    },
  },
};

import mixpanel, { Dict } from 'mixpanel-browser';

export const trackCreateNewLocalProject = (data?: Dict) => {
  try {
    mixpanel.track('Project_CreatedLocally', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackProjectLoaded = (data?: Dict) => {
  try {
    mixpanel.track('Project_Loaded', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackProjectLoadFailed = (data?: Dict) => {
  try {
    mixpanel.track('Project_LoadFailed', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackProjectSaved = (data?: Dict) => {
  try {
    mixpanel.track('Project_Saved', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackProjectSavedAsNewProject = (data?: Dict) => {
  try {
    mixpanel.track('Project_SavedAsNewProject', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackProjectTitleChanged = (data?: Dict) => {
  try {
    mixpanel.track('Project_TitleChanged', data);
  } catch (error) {
    console.error(error);
  }
};

import { types, SnapshotOut } from 'mobx-state-tree';
import { hideGrid, showGrid } from 'features/editor/utils/Paper/Layers';
import { trackPanModeToggled } from 'analytics/editor';

const View = types
  .model({
    panMode: types.boolean,
    showGrid: types.boolean,
    showDistanceToWalls: types.boolean,
    hoveredCellOutlineColor: types.maybeNull(types.string),
    zoom: types.number,
    isChanging: types.boolean,
    cursor: types.string,
  })
  .actions((self) => ({
    enablePan() {
      self.panMode = true;
      trackPanModeToggled({
        panModeActive: true,
      });
    },
    disablePan() {
      self.panMode = false;
      trackPanModeToggled({
        panModeActive: false,
      });
    },
    setShowGrid(value: boolean) {
      self.showGrid = value;
    },
    setShowDistanceToWalls(value: boolean) {
      self.showDistanceToWalls = value;
    },
    setHoveredCellOutlineColor(value: string | null) {
      self.hoveredCellOutlineColor = value;
      if (!value) self.showDistanceToWalls = false;
    },
    setZoom(value: number) {
      self.zoom = value;
    },
    setCursor(value: string) {
      self.cursor = value;
    },
    setIsChanging(value: boolean) {
      self.isChanging = value;
    },
  }));

export type ViewSnapshotOut = SnapshotOut<typeof View>;

export const handleViewChanges = (snapshot: ViewSnapshotOut) => {
  if (snapshot.showGrid) showGrid();
  else hideGrid();
};

export default View;

import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Doors = <ObjectMap>{
  //
  // Doors
  //
  SINGLE_DOOR: {
    key: 'SINGLE_DOOR',
    title: 'Single Door',
    variant: '90°',
    type: ObjectType.door,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.grid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: '30" opening', width: 30, height: 30 },
        { label: '24" opening', width: 24, height: 24 },
        { label: '28" opening', width: 28, height: 28 },
        { label: '34" opening', width: 34, height: 34 },
        { label: '36" opening', width: 36, height: 36 },
      ],
      metric: [
        { label: '762mm opening', width: 762, height: 762 },
        { label: '610mm opening', width: 610, height: 610 },
        { label: '711mm opening', width: 711, height: 711 },
        { label: '864mm opening', width: 864, height: 864 },
        { label: '914mm opening', width: 914, height: 914 },
      ],
    },
    wallOpening: true,
    lockHeight: true,
    preserveAspectRatio: true,
  },
  SINGLE_DOOR_45: {
    key: 'SINGLE_DOOR_45',
    title: 'Single Door',
    variant: '45°',
    type: ObjectType.door,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.grid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: '30" opening', width: 30, height: 20 },
        { label: '24" opening', width: 24, height: 16 },
        { label: '28" opening', width: 28, height: 19 },
        { label: '34" opening', width: 34, height: 23 },
        { label: '36" opening', width: 36, height: 24 },
      ],
      metric: [
        { label: '762mm opening', width: 762, height: 507 },
        { label: '610mm opening', width: 610, height: 406 },
        { label: '711mm opening', width: 711, height: 474 },
        { label: '864mm opening', width: 864, height: 575 },
        { label: '914mm opening', width: 914, height: 609 },
      ],
    },
    wallOpening: true,
    lockHeight: true,
    preserveAspectRatio: true,
  },
  FRENCH_DOORS: {
    key: 'FRENCH_DOORS',
    title: 'French Doors',
    variant: 'double',
    type: ObjectType.door,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.grid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: '48" opening', width: 48, height: 24 },
        { label: '60" opening', width: 60, height: 30 },
        { label: '72" opening', width: 72, height: 36 },
      ],
      metric: [
        { label: '1219mm opening', width: 1219, height: 610 },
        { label: '1524mm opening', width: 1524, height: 762 },
        { label: '1829mm opening', width: 1829, height: 915 },
      ],
    },
    wallOpening: true,
    lockHeight: true,
    preserveAspectRatio: true,
  },
  SLIDING_DOORS: {
    key: 'SLIDING_DOORS',
    title: 'Sliding Doors',
    variant: 'double',
    type: ObjectType.door,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.grid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: '60" opening', width: 60, height: 0 },
        { label: '72" opening', width: 72, height: 0 },
        { label: '84" opening', width: 84, height: 0 },
        { label: '96" opening', width: 96, height: 0 },
      ],
      metric: [
        { label: '1524mm opening', width: 1524, height: 0 },
        { label: '1829mm opening', width: 1829, height: 0 },
        { label: '2134mm opening', width: 2134, height: 0 },
        { label: '2438mm opening', width: 2438, height: 0 },
      ],
    },
    lockHeight: true,
  },
  POCKET_DOOR: {
    key: 'POCKET_DOOR',
    title: 'Pocket Door',
    variant: 'single',
    type: ObjectType.door,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.grid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: '24" opening', width: 48, height: 0 },
        { label: '28" opening', width: 56, height: 0 },
        { label: '30" opening', width: 60, height: 0 },
        { label: '32" opening', width: 64, height: 0 },
        { label: '36" opening', width: 72, height: 0 },
        { label: '40" opening', width: 80, height: 0 },
      ],
      metric: [
        { label: '610mm opening', width: 1220, height: 0 },
        { label: '711mm opening', width: 1422, height: 0 },
        { label: '762mm opening', width: 1524, height: 0 },
        { label: '813mm opening', width: 1626, height: 0 },
        { label: '914mm opening', width: 1828, height: 0 },
        { label: '1016mm opening', width: 2032, height: 0 },
      ],
    },
    lockHeight: true,
  },
  POCKET_DOOR_DOUBLE: {
    key: 'POCKET_DOOR_DOUBLE',
    title: 'Pocket Door',
    variant: 'double',
    type: ObjectType.door,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.grid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: '48" opening', width: 48 * 2, height: 0 },
        { label: '56" opening', width: 56 * 2, height: 0 },
        { label: '60" opening', width: 60 * 2, height: 0 },
        { label: '64" opening', width: 64 * 2, height: 0 },
        { label: '72" opening', width: 72 * 2, height: 0 },
        { label: '80" opening', width: 80 * 2, height: 0 },
      ],
      metric: [
        { label: '1219mm opening', width: 1219 * 2, height: 0 },
        { label: '1422mm opening', width: 1422 * 2, height: 0 },
        { label: '1524mm opening', width: 1524 * 2, height: 0 },
        { label: '1626mm opening', width: 1626 * 2, height: 0 },
        { label: '1829mm opening', width: 1829 * 2, height: 0 },
        { label: '2032mm opening', width: 2032 * 2, height: 0 },
      ],
    },
    lockHeight: true,
  },
  GARAGE_DOOR: {
    key: 'GARAGE_DOOR',
    title: 'Garage Door',
    variant: '1 car',
    type: ObjectType.door,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.grid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: "w: 7'", width: 84, height: 96 },
        { label: "w: 8'", width: 96, height: 96 },
        { label: "w: 9'", width: 108, height: 96 },
      ],
      metric: [
        { label: 'w: 2134mm', width: 2134, height: 2438 },
        { label: 'w: 2438mm', width: 2438, height: 2438 },
        { label: 'w: 2743mm', width: 2743, height: 2438 },
      ],
    },
    wallOpening: true,
    lockHeight: false,
    preserveAspectRatio: false,
  },
  GARAGE_DOOR_2_CAR: {
    key: 'GARAGE_DOOR_2_CAR',
    title: 'Garage Door',
    variant: '2 car',
    type: ObjectType.door,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.grid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: "w: 14'", width: 168, height: 96 },
        { label: "w: 16'", width: 192, height: 96 },
        { label: "w: 18'", width: 216, height: 96 },
      ],
      metric: [
        { label: 'w: 4267mm', width: 4267, height: 2438 },
        { label: 'w: 4877mm', width: 4877, height: 2438 },
        { label: 'w: 5486mm', width: 5486, height: 2438 },
      ],
    },
    wallOpening: true,
    lockHeight: false,
    preserveAspectRatio: false,
  },
};

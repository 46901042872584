import { observer } from 'mobx-react-lite';
import { useLocalStore } from 'state';
import { Wrapper, ZoomButton } from './styles';
import UndoIcon from 'features/editor/icons/UndoIcon';
import RedoIcon from 'features/editor/icons/RedoIcon';
import { trackRedo, trackUndo } from 'analytics/editor';
import Tooltip from 'ui/components/Tooltip';
import { getMetaKeyCharacter } from 'helpers/getMetaKeyCharacter';

const UndoRedoButtons = () => {
  const { activeProject, history, ui } = useLocalStore();

  return (
    <>
      {activeProject ? (
        <Wrapper shiftX={ui.objectPanelOpen ? ui.objectPanelWidth : 0}>
          <div id="inner">
            <Tooltip
              content="Undo"
              direction="top"
              keyShortcut={`${getMetaKeyCharacter()}Z`}
              disabled={!history.canUndo}
            >
              <ZoomButton
                id="zoom-button-first"
                disabled={!history.canUndo}
                onClick={() => {
                  history.undo();
                  trackUndo({
                    buttonClick: true,
                  });
                }}
              >
                <UndoIcon width={18} height={18} color="#666" />
              </ZoomButton>
            </Tooltip>
            <Tooltip
              content="Redo"
              direction="top"
              keyShortcut={`${getMetaKeyCharacter({ shift: true })}Z`}
              disabled={!history.canRedo}
            >
              <ZoomButton
                id="zoom-button-last"
                disabled={!history.canRedo}
                onClick={() => {
                  history.redo();
                  trackRedo({
                    buttonClick: true,
                  });
                }}
              >
                <RedoIcon width={18} height={18} color="#666" />
              </ZoomButton>
            </Tooltip>
          </div>
        </Wrapper>
      ) : null}
    </>
  );
};

export default observer(UndoRedoButtons);

const PlusIcon = ({ width = 24, height = 24, color = '#000000' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    height={height}
    width={width}
    viewBox="0 0 24 24"
    fill={color}
  >
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
  </svg>
);

export default PlusIcon;

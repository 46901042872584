import LZUTF8 from 'lzutf8';

export const APP_BAR_HEIGHT_PX = 48;
export const LEFT_PANEL_WIDTH = 350;
export const TOOL_BAR_WIDTH_PX = 50;
export const TOOL_BAR_TOGGLE_HEIGHT_PX = 20;

export const MAX_ZOOM_LEVEL = 2.0;

export const TRANSITION_TIME = 0.3;

export const PPI = 300;
export const PPMM = 12;

export const MOUSE_TOOLTIP_OFFSET_TOP = 24;
export const MOUSE_TOOLTIP_OFFSET_LEFT = 24;

export const FILE_ENCODING: LZUTF8.CompressedEncoding = 'ByteArray';

export const FREE_TIER_PROJECT_LIMIT = 3;

import styled from 'styled-components';

interface StyledLineProps {
  top: number;
  left: number;
  length: number;
  angle: number;
  color: string;
  offset: number;
}

export const StyledLine = styled.div.attrs((props: StyledLineProps) => ({
  style: {
    top: props.top,
    left: props.left,
    width: props.length,
    backgroundColor: props.color,
    transform: `translate${
      props.angle === 0 || props.angle === 180 ? 'Y' : 'X'
    }(${props.offset}px) rotate(${props.angle}deg) scaleY(0.75)`,
  },
}))<StyledLineProps>`
  position: fixed;
  z-index: 999998;
  height: 4px;
  transform-origin: 0 0;
`;

interface LabelProps {
  top: number;
  left: number;
  color: string;
  angle: number;
  offset: number;
}

export const Label = styled.div.attrs((props: LabelProps) => ({
  style: {
    top: props.top,
    left: props.left,
    backgroundColor: props.color,
    transform:
      props.angle === 0 || props.angle === 180
        ? `translateY(${props.offset - 42}px) translateX(${-32}px)`
        : `translateX(${props.offset - 78}px) translateY(${-16}px)`,
  },
}))<LabelProps>`
  position: fixed;
  white-space: nowrap;
  z-index: 999999;
  padding: 4px 6px;
  border-radius: 4px;

  font-size: 16px;
  font-weight: 600;
  color: #fff;

  transform-origin: 0 0;
  border: 2px solid #fff;
`;

interface AnchorProps {
  top: number;
  left: number;
  size: number;
  angle: number;
  offset: number;
}

export const Anchor = styled.div.attrs((props: AnchorProps) => ({
  style: {
    top:
      props.top -
      props.size / 2 +
      (props.angle === 0 || props.angle === 180 ? props.offset : 0),
    left:
      props.left -
      props.size / 2 +
      (props.angle === 90 || props.angle === 270 ? props.offset : 0),
  },
}))<AnchorProps>`
  position: fixed;
  z-index: 999999;
  transform-origin: 50% 50%;
`;

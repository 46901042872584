import { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import TimeAgo from 'react-timeago';
import { applySnapshot } from 'mobx-state-tree';
import { decompress } from 'lzutf8';
import { toast } from 'react-toastify';
import { useLocalStore } from 'state';
import FileIcon from 'features/editor/icons/FileIcon';
import {
  StyledLeftPanel,
  StyledFileMenuWrapper,
  StyledFileMenuHeader,
  StyledFileList,
  StyledFileListItem,
  StyledFileListItemTitle,
  StyledFileListItemSubtitle,
  EmptyFileBinWrapper,
  ScrollArea,
  FileListItemWrapper,
} from './styles';
import EmptyFolderGraphic from 'features/editor/icons/EmptyFolder';
import { useAuth } from 'contexts/AuthContext';
import { SaveStatus } from 'state/types';
import { getFilePath } from 'helpers/getFilePath';
import { storage, database } from '../../../../firebase';
import { FILE_ENCODING } from 'features/editor/config';
import { ProjectSnapshotOut } from 'state/models/UserProjects';
import { FileSnapshotIn } from 'state/models/File';
import { fitToScreen } from 'features/editor/utils/Paper/View';
import { trackProjectLoaded, trackProjectLoadFailed } from 'analytics/project';
import DeleteIcon from 'features/editor/icons/DeleteIcon';
import TierPanel from '../TierPanel';

const LeftPanel = () => {
  const { currentUser } = useAuth();
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);
  const {
    ui: { leftPanelOpen, setCreateFileModal, setLoginModal },
    userProjects: projects,
    removeUserProject,
    saveStatus,
    setSaveStatus,
    file,
    loadActiveProject,
    clearHistory,
    history,
    activeProject,
    resetUndoable,
    overridedTextItems,
    overridedObjects,
  } = useLocalStore();

  const fileToJs = (file: any) => {
    const jsonStr = decompress(file, {
      inputEncoding: FILE_ENCODING,
    }) as string;

    return JSON.parse(jsonStr) as FileSnapshotIn;
  };

  const loadProject = (project: ProjectSnapshotOut) => {
    if (!currentUser?.uid) return;
    overridedTextItems.clear();
    overridedObjects.clear();
    resetUndoable();

    const filePath = getFilePath(currentUser.uid, project.id);
    const filePathRef = storage.ref(filePath);

    filePathRef
      .getDownloadURL()
      .then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'arraybuffer';
        xhr.onload = () => {
          const f = new Uint8Array(xhr.response);
          const fileJs = fileToJs(f);
          clearHistory();
          applySnapshot(file, fileJs);
          loadActiveProject({ id: project.id, title: project.title });
          setSaveStatus(SaveStatus.saved);
          fitToScreen();
          trackProjectLoaded();
          toast(
            `Editing '${project.title.length > 0 ? project.title : 'Untitled'}'`
          );
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch((error) => {
        // Handle any errors
        console.log('Error: ', error);
        trackProjectLoadFailed();
      });
  };

  const handleProjectDelete = (project: ProjectSnapshotOut) => {
    if (
      confirm(
        `Are you sure you want to delete your project '${
          project.title || 'Untitled'
        }'? This cannot be undone.`
      )
    ) {
      database.projects
        .doc(project.id)
        .delete()
        .then(() => {
          if (currentUser?.uid) {
            const filePath = getFilePath(currentUser.uid, project.id);
            const filePathRef = storage.ref(filePath);
            filePathRef.delete().then(() => {
              removeUserProject(project.id);
              toast(`Deleted project '${project.title || 'Untitled'}'`);
            });
          }
        });
    }
  };

  const handleNewFileClick = () => {
    if (
      saveStatus === SaveStatus.no_file ||
      saveStatus === SaveStatus.saved ||
      (saveStatus === SaveStatus.unsaved && !history.canUndo)
    ) {
      setCreateFileModal(true);
    } else {
      // current project has unsaved changes
      if (
        confirm(
          `There are unsaved changes on the current project '${
            activeProject?.title || 'Untitled'
          }'. Are you sure you want to continue?`
        )
      ) {
        setCreateFileModal(true);
      }
    }
  };

  const handleProjectClick = (project: ProjectSnapshotOut) => {
    if (
      saveStatus === SaveStatus.no_file ||
      saveStatus === SaveStatus.saved ||
      (saveStatus === SaveStatus.unsaved && !history.canUndo)
    ) {
      // load the project immediately
      loadProject(project);
    } else {
      // current project has unsaved changes
      if (
        confirm(
          `There are unsaved changes on the current project '${
            activeProject?.title || 'Untitled'
          }'. Are you sure you want to continue?`
        )
      ) {
        loadProject(project);
      }
    }
  };

  const EmptyFileBin = () => {
    return (
      <EmptyFileBinWrapper>
        <EmptyFolderGraphic width={120} />
        <h4>
          {currentUser?.uid ? (
            'Projects folder is empty'
          ) : (
            <>
              <a
                role="link"
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={() => setLoginModal(true)}
              >
                Sign in
              </a>
              {' to view your projects'}
            </>
          )}
        </h4>
      </EmptyFileBinWrapper>
    );
  };

  return (
    <StyledLeftPanel isOpen={leftPanelOpen}>
      {projects?.length ? (
        <StyledFileMenuWrapper>
          <StyledFileMenuHeader>
            <h1>{`My Projects (${projects.length})`}</h1>
            <button type="button" onClick={() => handleNewFileClick()}>
              New
            </button>
          </StyledFileMenuHeader>
          <ScrollArea
            ref={scrollAreaRef}
            onWheel={(e) => {
              if (
                scrollAreaRef.current &&
                Math.abs(e.deltaY) > Math.abs(e.deltaX)
              )
                scrollAreaRef.current.scrollTop += e.deltaY;
            }}
          >
            <StyledFileList>
              {projects.map((project) => (
                <FileListItemWrapper key={project.id}>
                  <StyledFileListItem
                    onClick={() => handleProjectClick(project)}
                  >
                    <FileIcon width={28} height={28} color="#8e8e8e" />
                    <div>
                      <StyledFileListItemTitle>
                        {project.title.length ? project.title : 'Untitled'}
                      </StyledFileListItemTitle>
                      <StyledFileListItemSubtitle>
                        edited{' '}
                        <TimeAgo date={project.modifiedAt} minPeriod={10} />
                      </StyledFileListItemSubtitle>
                    </div>
                  </StyledFileListItem>
                  {project.id !== activeProject?.id && (
                    <button
                      type="button"
                      onClick={() => handleProjectDelete(project)}
                    >
                      <DeleteIcon />
                    </button>
                  )}
                </FileListItemWrapper>
              ))}
            </StyledFileList>
          </ScrollArea>
          <TierPanel />
          {!projects.length && <EmptyFileBin />}
        </StyledFileMenuWrapper>
      ) : (
        <StyledFileMenuWrapper>
          <StyledFileMenuHeader>
            <h1>My Projects</h1>
            <button type="button" onClick={() => handleNewFileClick()}>
              New
            </button>
          </StyledFileMenuHeader>

          <EmptyFileBin />
        </StyledFileMenuWrapper>
      )}
    </StyledLeftPanel>
  );
};

export default observer(LeftPanel);

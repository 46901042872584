import * as paper from 'paper';
import RootStore from 'state/models/Root';
import { initializeDrawingLayer } from 'features/editor/utils/Paper/Layers';
import { getDimensionLabel } from '../../Unit';
import { getSnappedPoint } from '../../Grid';
import { ObjectSnap } from 'features/editor/types';
import {
  getGridOffset,
  gridContainsPoint,
  paperPointFromGridOffset,
} from '../Grid';
import { distanceOf } from '../../Math';
import { paperToBrowserCoordinates } from '../View';
import { debounce } from 'throttle-debounce';
import { trackTapeMeasureEvent } from 'analytics/editor';

const debouncedDragHandler = debounce(1000, () => {
  trackTapeMeasureEvent();
});

export const handleTapeMeasureDrag = (event: paper.ToolEvent) => {
  debouncedDragHandler();
  const isMetric = !!RootStore.file.sheet?.metric;
  const PPU = RootStore.file.sheet?.PPU || 1;
  const cellSizePx = RootStore.file.sheet?.cellSizePixels || 1;
  initializeDrawingLayer();

  const { point, downPoint } = event;
  const path = new paper.Path([
    getSnappedPoint(getGridOffset(downPoint), ObjectSnap.grid, ObjectSnap.grid),
    getSnappedPoint(getGridOffset(point), ObjectSnap.grid, ObjectSnap.grid),
  ]);

  if (!path) return;
  // const is90Deg = path.bounds.width === 0 || path.bounds.height === 0;
  const line = new paper.Path([
    paperPointFromGridOffset(path.segments[0].point),
    paperPointFromGridOffset(path.segments[1].point),
  ]);
  line.strokeColor = new paper.Color(RootStore.ui.theme.wallColor_uncommited);
  line.strokeWidth = cellSizePx / 4;
  line.strokeCap = 'round';

  let mouseTooltip;
  const labelPosition = paperToBrowserCoordinates(line.bounds.center);
  const distance =
    distanceOf(path.segments[0].point, path.segments[1].point) / PPU;
  const label = getDimensionLabel(distance, isMetric);
  if (!isMetric) {
    mouseTooltip = getDimensionLabel(distance, false, { inches: true });
  }
  RootStore.dimensions.setDimensionLabels([
    { id: 'TapeMeasure', label, position: labelPosition },
  ]);
  if (mouseTooltip) RootStore.mouseTooltip.setData(mouseTooltip);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const handleTapeMeasureMouseUp = (_event: paper.ToolEvent) => {
  const activeDrawingLayer = initializeDrawingLayer();
  RootStore.dimensions.clearDimensionLabels();
  RootStore.mouseTooltip.clearData();
  activeDrawingLayer.remove();
};

export const handleTapeMeasureMouseMove = (event: paper.ToolEvent) => {
  const cellSizePx = RootStore.file.sheet?.cellSizePixels || 1;
  initializeDrawingLayer();
  const { point } = event;
  if (!gridContainsPoint(point)) return;
  const snappedPoint = getSnappedPoint(
    getGridOffset(point),
    ObjectSnap.grid,
    ObjectSnap.grid
  );
  if (!snappedPoint) return;
  const circle = new paper.Shape.Circle(paperPointFromGridOffset(snappedPoint));
  circle.radius = cellSizePx / 4;
  circle.fillColor = new paper.Color(RootStore.ui.theme.wallColor_uncommited);

  const extensionLines = [] as paper.Path[];
  const gridBounds = paper.project.getItem({
    name: 'GRID_BOUNDING_RECT',
  }).bounds;

  // top
  extensionLines.push(
    new paper.Path([
      circle.bounds.center.subtract(new paper.Point(0, cellSizePx)),
      new paper.Point(circle.bounds.center.x, gridBounds.top),
    ])
  );

  // bottom
  extensionLines.push(
    new paper.Path([
      circle.bounds.center.add(new paper.Point(0, cellSizePx)),
      new paper.Point(circle.bounds.center.x, gridBounds.bottom),
    ])
  );

  // left
  extensionLines.push(
    new paper.Path([
      circle.bounds.center.subtract(new paper.Point(cellSizePx, 0)),
      new paper.Point(gridBounds.left, circle.bounds.center.y),
    ])
  );

  // right
  extensionLines.push(
    new paper.Path([
      circle.bounds.center.add(new paper.Point(cellSizePx, 0)),
      new paper.Point(gridBounds.right, circle.bounds.center.y),
    ])
  );

  extensionLines.forEach((path) => {
    path.strokeColor = new paper.Color(RootStore.ui.theme.wallColor_uncommited);
    path.strokeWidth = 6;
    path.opacity = 0.25;
  });
};

const ExpandDownIcon = ({ width = 8, height = 7 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 8 7"
  >
    <path
      fill="currentColor"
      d="M3.646 5.354l-3-3 .708-.708L4 4.293l2.646-2.647.708.708-3 3L4 5.707l-.354-.353z"
    ></path>
  </svg>
);

export default ExpandDownIcon;

import styled from 'styled-components';

export const StyledModalBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
`;

const BORDER_RADIUS = 6;

export const StyledModalContainer = styled.div`
  height: 580px;
  width: 900px;
  margin-right: -100px;
  border-radius: ${`${BORDER_RADIUS}px`};
  background: #fff;
  display: grid;
  grid-template-columns: 60% auto;
  color: #333;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.15);
  user-select: none;
`;

export const StyledRightModalSection = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(5, min-content) auto;
`;

type ModalRowProps = {
  columns: number;
  rows: number;
};

export const StyledModalRow = styled.div<ModalRowProps>`
  position: relative;
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns === 2 ? 'min-content auto' : `repeat(${props.columns})`};
  grid-template-rows: ${(props) => `repeat(${props.rows}, auto)`};
  padding: 0 24px;
`;

export const StyledNewProjectModalHeader = styled.h1`
  margin-top: 24px;
  margin-bottom: 0;
  font-size: 16px;
  display: flex;
  color: #444;
`;

export const StyledNewProjectTitleInput = styled.input`
  margin-top: 28px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  font-size: 22px;
  background: transparent;
  color: #000;
  border: none;
  border-bottom: 1px solid #d5d5d5;
  outline: none;
  &:hover {
    border-color: #c5c5c5;
  }
  &:focus {
    border-color: ${({ theme }) => theme.accentBorderColor2};
  }
`;

export const UsableArea = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  font-size: 13px;
  color: #555;
`;

export const StyledNewProjectModalLabel = styled.div`
  margin-top: 20px;
  margin-bottom: 8px;
  font-size: 14px;
`;

type NewProjectInputProps = {
  width: number;
};

const inputPadding = 6;

export const StyledNewProjectInput = styled.input<NewProjectInputProps>`
  font-size: 14px;
  padding: ${`${inputPadding}px 10px`};
  margin-right: 8px;
  background: #fff;
  color: #000;
  border: 1px solid #d5d5d5;
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  outline: none;
  width: ${({ width }) => `${width - inputPadding * 2}px`};
  height: ${`${38 - inputPadding * 2 - 2}px`};
  &:hover {
    border-color: #888;
  }
  &:focus {
    border-color: ${({ theme }) => theme.accentBorderColor2};
    height: ${`${38 - inputPadding * 2 - 2}px`};
  }
`;

export const StyledNewProjectCreateButton = styled.button`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  width: 96px;
  height: 32px;
  border: none;
  background-color: #389de4;
  color: #fff;
  border-radius: 16px;
`;

export const StyledNewProjectCancelButton = styled.button`
  cursor: pointer;
  font-size: 14px;
  width: 96px;
  height: 32px;
  margin-left: 16px;
  border: 1px solid #a0a0a0;
  background-color: transparent;
  color: #333;
  border-radius: 16px;

  &:hover {
    border-color: #333;
    color: #111;
  }
`;

export const StyledNewProjectButtonContainer = styled.div`
  margin: 24px;
  display: flex;
  align-items: flex-end;
`;

/**
 * Left
 */
type LeftModalSectionProps = {
  rows: number;
};

export const StyledLeftModalSection = styled.div`
  background: #f8f8f8;
  border-top-left-radius: ${`${BORDER_RADIUS}px`};
  border-bottom-left-radius: ${`${BORDER_RADIUS}px`};
  padding: 0 24px 24px 24px;
  display: grid;
  grid-template-rows: min-content min-content auto;
`;

export const StyledPresetGridContainer = styled.div<LeftModalSectionProps>`
  margin-top: 32px;
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: ${(props) =>
    `repeat(${props.rows - 1}, min-content) auto`};
  grid-gap: 18px;
`;

type NewProjectPresetProps = {
  isActive: boolean;
};

export const StyledNewProjectPreset = styled.button<NewProjectPresetProps>`
  position: relative;
  cursor: pointer;
  border: ${({ isActive }) =>
    isActive ? `2px solid #389DE4` : '2px solid #f0f0f0'};
  outline: none;
  height: 154px;
  border-radius: 4px;
  background: #fff;
  &:hover {
    transform: scale(1.05);
    background: #fcfcfc;
  }

  svg {
    margin-bottom: 12px;
  }
`;

export const StyledNewProjectPresetGhost = styled.div`
  border: none;
  border-radius: ${({ theme }) => `${theme.borderRadius}px`};
  background: rgba(210, 210, 210, 1);
`;

type NewProjectPresetAreaProps = {
  selected: boolean;
};

export const NewProjectPresetArea = styled.div<NewProjectPresetAreaProps>`
  height: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0px;

  span {
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    ${({ selected }) => (!selected ? `color: #555;` : null)}
    ${({ selected }) => (!selected ? `font-weight: 500;` : null)}
  }

  background: #389de4;

  ${({ selected }) => (!selected ? `background: #fafafa;` : null)}
  ${({ selected }) => (!selected ? `border-top: 2px solid #f0f0f0;` : null)}
`;

export const StyledNewProjectPresetName = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-top: -24px;
  margin-bottom: 8px;
  color: #111;
`;

export const StyledNewProjectPresetDimensions = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
  color: #555;
`;

export const StyledNewProjectPresetScale = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #555;
`;

export const StyledPresetTabContainer = styled.div`
  margin-top: 16px;
  border-bottom: 1px solid #d0d0d0;
`;

type PresetTabButtonProps = {
  isActive?: boolean;
};

export const StyledPresetTabButton = styled.button<PresetTabButtonProps>`
  cursor: pointer;
  outline: none;
  border: none;
  border-bottom: ${({ isActive }) =>
    isActive ? `2px solid #389DE4` : '2px solid transparent'};
  background: transparent;
  color: ${({ isActive }) => (isActive ? '#389DE4' : '#555')};
  font-size: 14px;
  font-weight: ${({ isActive }) => (isActive ? 600 : 500)};
  padding: 12px 24px;
`;

export const customSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 2,
    backgroundColor: '#fff',
    borderColor: state.isSelected ? '#d5d5d5' : '#d5d5d5',
    borderWidth: '1px !important',
    outline: 'none',
    width: '100%',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: '14px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: () => ({
    marginRight: 6,
    color: '#a0a0a0',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#111',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#fff',
  }),
  option: (provided: any, { isSelected }: any) => ({
    ...provided,
    background: isSelected ? '#f0f0f0' : '#fff',
    color: isSelected ? '#000' : '#333',
    cursor: 'pointer',
  }),
};

export const selectTheme = (providedTheme: any) => ({
  ...providedTheme,
  colors: {
    ...providedTheme.colors,
    primary: '#e0e0e0',
    primary50: '#eeeeee',
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
import { json as serializer } from '@dagrejs/graphlib';

export const writeGraphToString = (graph: any) => {
  return JSON.stringify(serializer.write(graph));
};

export const readGraphFromString = (string: string) => {
  return serializer.read(JSON.parse(string));
};

const TapeMeasueIcon = ({ width = 24, height = 24, color = '#fff' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="12.5" r="4" stroke={color} />
    <path
      d="M9.63636 20C13.8538 20 17.2727 16.6421 17.2727 12.5C17.2727 8.35786 13.8538 5 9.63636 5C5.41892 5 2 8.35786 2 12.5C2 16.6421 5.41892 20 9.63636 20ZM9.63636 20H19.5"
      stroke={color}
      strokeLinecap="square"
    />
    <circle cx="22" cy="20" r="1" fill={color} />
  </svg>
);

export default TapeMeasueIcon;

import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Beds = <ObjectMap>{
  BED_TWIN: {
    key: 'BED_TWIN',
    title: 'Twin Bed',
    type: ObjectType.bed,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: 'Twin', width: 38, height: 75 },
        { label: 'Twin XL', width: 38, height: 80 },
      ],
      metric: [
        { label: 'Twin', width: 965, height: 1905 },
        { label: 'Twin XL', width: 965, height: 2032 },
      ],
    },
  },
  BED_FULL: {
    key: 'BED_FULL',
    title: 'Full Bed',
    type: ObjectType.bed,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: 'Full', width: 54, height: 75 },
        { label: 'Full XL', width: 54, height: 80 },
      ],
      metric: [
        { label: 'Full', width: 1372, height: 1905 },
        { label: 'Full XL', width: 1372, height: 2032 },
      ],
    },
  },
  BED_QUEEN: {
    key: 'BED_QUEEN',
    title: 'Queen Bed',
    type: ObjectType.bed,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: 'Queen', width: 60, height: 80 },
        { label: 'Olympic Queen', width: 66, height: 80 },
      ],
      metric: [
        { label: 'Queen', width: 1524, height: 2032 },
        { label: 'Olympic Queen', width: 1676, height: 2032 },
      ],
    },
  },
  BED_KING: {
    key: 'BED_KING',
    title: 'King Bed',
    type: ObjectType.bed,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: 'King', width: 76, height: 80 },
        { label: 'California King', width: 72, height: 84 },
      ],
      metric: [
        { label: 'King', width: 1930, height: 2032 },
        { label: 'California King', width: 1829, height: 2134 },
      ],
    },
  },
};

import * as paper from 'paper';
import RootStore from 'state/models/Root';
import { Cell, CellPosition, ObjectSnap } from 'features/editor/types';
import rootStore from 'state/models/Root';
import { getClosestNumber } from 'features/editor/utils/Math';

export const cellPositionFromPoint = (point: paper.Point) => {
  const gridBoundingRect = paper.project.getItem({
    name: 'GRID_BOUNDING_RECT',
  });
  if (!gridBoundingRect) return undefined;

  if (!gridBoundingRect.contains(point)) return undefined;

  const CELL_SIZE = RootStore.file.sheet?.cellSizePixels || 1;
  const INITIAL_POINT = gridBoundingRect.bounds.topLeft;

  const delta = point.subtract(INITIAL_POINT);
  const row = Math.floor(delta.y / CELL_SIZE);
  const column = Math.floor(delta.x / CELL_SIZE);

  const cellPosition: CellPosition = {
    row,
    column,
    topLeft: INITIAL_POINT.add({
      x: column * CELL_SIZE,
      y: row * CELL_SIZE,
    } as paper.Point),
    topRight: INITIAL_POINT.add({
      x: (column + 1) * CELL_SIZE,
      y: row * CELL_SIZE,
    } as paper.Point),
    bottomLeft: INITIAL_POINT.add({
      x: column * CELL_SIZE,
      y: (row + 1) * CELL_SIZE,
    } as paper.Point),
    bottomRight: INITIAL_POINT.add({
      x: (column + 1) * CELL_SIZE,
      y: (row + 1) * CELL_SIZE,
    } as paper.Point),
    center: INITIAL_POINT.add({
      x: (column * CELL_SIZE + (column + 1) * CELL_SIZE) / 2,
      y: (row * CELL_SIZE + (row + 1) * CELL_SIZE) / 2,
    } as paper.Point),
  };

  return cellPosition;
};

export const getCellPosition = (cell: Cell) => {
  // get the grid bounding rect
  const gridBoundingRect = paper.project.getItem({
    name: 'GRID_BOUNDING_RECT',
  });

  if (!gridBoundingRect) return undefined;

  const INITIAL_POINT = gridBoundingRect.bounds.topLeft;
  const CELL_SIZE = RootStore.file.sheet?.cellSizePixels || 1;

  const { row, column } = cell;
  const cellPosition: CellPosition = {
    row,
    column,
    topLeft: INITIAL_POINT.add({
      x: column * CELL_SIZE,
      y: row * CELL_SIZE,
    } as paper.Point),
    topRight: INITIAL_POINT.add({
      x: (column + 1) * CELL_SIZE,
      y: row * CELL_SIZE,
    } as paper.Point),
    bottomLeft: INITIAL_POINT.add({
      x: column * CELL_SIZE,
      y: (row + 1) * CELL_SIZE,
    } as paper.Point),
    bottomRight: INITIAL_POINT.add({
      x: (column + 1) * CELL_SIZE,
      y: (row + 1) * CELL_SIZE,
    } as paper.Point),
    center: INITIAL_POINT.add({
      x: (column * CELL_SIZE + (column + 1) * CELL_SIZE) / 2,
      y: (row * CELL_SIZE + (row + 1) * CELL_SIZE) / 2,
    } as paper.Point),
  };

  return cellPosition;
};

export const getSnappedPoint = (
  point: paper.Point,
  snapX: ObjectSnap,
  snapY: ObjectSnap,
  width = 0,
  height = 0,
  rotation = 0
) => {
  let x = point.x;
  let y = point.y;

  const cellSize = rootStore.file.sheet?.cellSizePixels || 1;

  if (
    (snapX === ObjectSnap.grid && (rotation === 0 || rotation === 180)) ||
    (snapY === ObjectSnap.grid && (rotation === 90 || rotation === 270))
  ) {
    x = getClosestNumber(point.x, [
      Math.floor(point.x / cellSize) * cellSize,
      Math.ceil(point.x / cellSize) * cellSize,
    ]);
  } else if (
    (snapX === ObjectSnap.halfGrid && (rotation === 0 || rotation === 180)) ||
    (snapY === ObjectSnap.halfGrid && (rotation === 90 || rotation === 270))
  ) {
    x = getClosestNumber(point.x, [
      Math.floor(point.x / cellSize) * cellSize,
      Math.ceil(point.x / cellSize) * cellSize,
      (Math.floor(point.x / cellSize) * cellSize +
        Math.ceil(point.x / cellSize) * cellSize) /
        2,
    ]);
  }

  if (
    (snapY === ObjectSnap.grid && (rotation === 0 || rotation === 180)) ||
    (snapX === ObjectSnap.grid && (rotation === 90 || rotation === 270))
  ) {
    y = getClosestNumber(point.y, [
      Math.floor(point.y / cellSize) * cellSize,
      Math.ceil(point.y / cellSize) * cellSize,
    ]);
  } else if (
    (snapY === ObjectSnap.halfGrid && (rotation === 0 || rotation === 180)) ||
    (snapX === ObjectSnap.halfGrid && (rotation === 90 || rotation === 270))
  ) {
    y = getClosestNumber(point.y, [
      Math.floor(point.y / cellSize) * cellSize,
      Math.ceil(point.y / cellSize) * cellSize,
      (Math.floor(point.y / cellSize) * cellSize +
        Math.ceil(point.y / cellSize) * cellSize) /
        2,
    ]);
  }

  const gridBoundingRect = paper.project.getItem({
    name: 'GRID_BOUNDING_RECT',
  });

  if (x < 0) {
    x = 0;
  } else if (
    x + (rotation === 0 || rotation === 180 ? width : height) >
    gridBoundingRect.bounds.width
  ) {
    x =
      gridBoundingRect.bounds.width -
      (rotation === 0 || rotation === 180 ? width : height);
  }

  if (y < 0) {
    y = 0;
  } else if (
    y + (rotation === 0 || rotation === 180 ? height : width) >
    gridBoundingRect.bounds.height
  ) {
    y =
      gridBoundingRect.bounds.height -
      (rotation === 0 || rotation === 180 ? height : width);
  }

  return new paper.Point(x, y);
};

import { observer } from 'mobx-react-lite';
import { useLocalStore } from 'state';
import { Wrapper } from './styles';

const ProjectLimitModal = () => {
  const {
    ui: { projectLimitModalOpen: isOpen, setProjectLimitModal },
  } = useLocalStore();

  if (!isOpen) return null;
  return (
    <Wrapper>
      <h4>Saving is disabled for this project</h4>
      <p>{'You have used all 3 projects that come with your Starter plan.'}</p>
      <button type="button" onClick={() => setProjectLimitModal(false)}>
        Ok
      </button>
    </Wrapper>
  );
};

export default observer(ProjectLimitModal);

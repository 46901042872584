import { SnapshotOut, types } from 'mobx-state-tree';

const SelectedObjects = types
  .model({ ids: types.array(types.string) })
  .actions((self) => ({
    addId(id: string) {
      if (!self.ids.some((i) => i === id)) {
        self.ids.push(id);
      }
    },
    clear() {
      self.ids.clear();
    },
  }))
  .views((self) => ({
    get isEmpty() {
      return !(self.ids.length > 0);
    },
    isSelected(id: string) {
      return self.ids.some((i) => i === id);
    },
  }));

export type SelectedObjectsSnapshotOut = SnapshotOut<typeof SelectedObjects>;

export default SelectedObjects;

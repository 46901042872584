const CloseIcon = ({ width = 24, height = 24, color = '#000000' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-6 w-6"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 24 24"
    stroke={color}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

export default CloseIcon;

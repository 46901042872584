export type Preset = {
  id: number;
  name: string;
  dimensions: string;
  scaleText: string;
  width: number;
  height: number;
  scale: number;
  unit: string;
  wallThickness: number;
};

export const selectUnitOptions = [
  { value: 'IN', label: 'Inches' },
  // { value: 'CM', label: 'Centimeters' },
  { value: 'MM', label: 'Millimeters' },
  // { value: 'PX', label: 'Pixels' },
];

export const selectImperialScaleOptions = [
  { value: 0.5, label: '1/2 inch = 1 foot (1:24)' },
  { value: 0.375, label: '3/8 inch = 1 foot (1:32)' },
  { value: 0.25, label: '1/4 inch = 1 foot (1:48)' },
  { value: 0.125, label: '1/8 inch = 1 foot (1:96)' },
];

export const selectImperialWallThicknessOptions = [
  { value: 8, label: '8 inches' },
  { value: 6, label: '6 inches (default)' },
  { value: 4, label: '4 inches' },
];

export const selectMetricScaleOptions = [
  { value: 40, label: '4cm = 1m (1:25)' },
  { value: 25, label: '2.5cm = 1m (1:40)' },
  { value: 20, label: '2cm = 1m (1:50)' },
  { value: 10, label: '1cm = 1m (1:100)' },
];

export const selectMetricWallThicknessOptions = [
  { value: 200, label: '200 mm' },
  { value: 150, label: '150 mm (default)' },
  { value: 100, label: '100 mm' },
];

export const usPresets: Preset[] = [
  {
    id: 0,
    name: 'ARCH A',
    dimensions: '9 x 12 inches',
    scaleText: '1/4" = 1\'',
    width: 12,
    height: 9,
    scale: 0.25,
    unit: 'IN',
    wallThickness: 6,
  },
  {
    id: 1,
    name: 'ARCH B',
    dimensions: '12 x 18 inches',
    scaleText: '1/4" = 1\'',
    width: 18,
    height: 12,
    scale: 0.25,
    unit: 'IN',
    wallThickness: 6,
  },
  {
    id: 2,
    name: 'ARCH C',
    dimensions: '18 x 24 inches',
    scaleText: '1/4" = 1\'',
    width: 24,
    height: 18,
    scale: 0.25,
    unit: 'IN',
    wallThickness: 6,
  },
  {
    id: 3,
    name: 'ARCH D',
    dimensions: '24 x 36 inches',
    scaleText: '1/4" = 1\'',
    width: 36,
    height: 24,
    scale: 0.25,
    unit: 'IN',
    wallThickness: 6,
  },
  {
    id: 4,
    name: 'Letter',
    dimensions: '8.5 x 11 inches',
    scaleText: '1/4" = 1\'',
    width: 11,
    height: 8.5,
    scale: 0.25,
    unit: 'IN',
    wallThickness: 6,
  },
  {
    id: 5,
    name: 'Letter',
    dimensions: '8.5 x 11 inches',
    scaleText: '1/8" = 1\'',
    width: 11,
    height: 8.5,
    scale: 0.125,
    unit: 'IN',
    wallThickness: 6,
  },
];

export const metricPresets: Preset[] = [
  {
    id: 0,
    name: 'A4',
    dimensions: '297 x 210 mm',
    scaleText: '2cm = 1m (1:50)',
    width: 297,
    height: 210,
    scale: 20,
    unit: 'MM',
    wallThickness: 150,
  },

  {
    id: 1,
    name: 'A3',
    dimensions: '420 x 297 mm',
    scaleText: '2cm = 1m (1:50)',
    width: 420,
    height: 297,
    scale: 20,
    unit: 'MM',
    wallThickness: 150,
  },
  {
    id: 2,
    name: 'A2',
    dimensions: '594 x 420 mm',
    scaleText: '2cm = 1m (1:50)',
    width: 594,
    height: 420,
    scale: 20,
    unit: 'MM',
    wallThickness: 150,
  },
  {
    id: 3,
    name: 'A1',
    dimensions: '841 x 594 mm',
    scaleText: '2cm = 1m (1:50)',
    width: 841,
    height: 594,
    scale: 20,
    unit: 'MM',
    wallThickness: 150,
  },
  {
    id: 4,
    name: 'A5',
    dimensions: '210 x 148 mm',
    scaleText: '2cm = 1m (1:50)',
    width: 210,
    height: 148,
    scale: 20,
    unit: 'MM',
    wallThickness: 150,
  },
];

export const getDimensionLabel = (
  number: number,
  isMetric: boolean,
  options?: { inches?: boolean; both?: boolean }
) => {
  const n = Math.round(number);
  if (isMetric) {
    return `${n.toLocaleString()} mm`;
  }

  if (options?.inches) {
    return `${n}"`;
  }

  const feet = Math.floor(n / 12);
  const inches = n % 12;

  if (options?.both) {
    return `${`${feet}' - ${inches}", ${n}"`}`;
  }

  return `${feet}' - ${inches}"`;
};

export const getAreaLabel = (
  number: number,
  isMetric: boolean,
  options?: { isInteger?: boolean }
) => {
  if (isMetric) {
    return `${(number / 1000 ** 2).toLocaleString(undefined, {
      minimumFractionDigits: options?.isInteger ? 0 : 2,
      maximumFractionDigits: options?.isInteger ? 0 : 2,
    })} sqm`;
  }

  return `${(number / 12 ** 2).toLocaleString(undefined, {
    maximumFractionDigits: options?.isInteger ? 0 : 2,
  })} sqft`;
};

import { Point } from 'features/editor/types';
import styled from 'styled-components';

interface WrapperProps {
  position: Point;
}

export const Wrapper = styled.div.attrs((props: WrapperProps) => ({
  style: {
    top: props.position.y,
    left: props.position.x - 24 / 2,
  },
}))<WrapperProps>`
  position: fixed;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

import * as paper from 'paper';
import { getObjectSymbol } from 'objects/symbols';
import {
  ObjectMap,
  ObjectModel,
  ObjectsSnapshotOut,
} from 'state/models/Objects';
import rootStore from 'state/models/Root';
import { createOrActivateLayer, getLayerByName } from '../Layers';
import ObjectMetadata from 'objects/metadata';
import { SelectedObjectsSnapshotOut } from 'state/models/SelectedObjects';
import { browserToPaperCoordinates, paperToBrowserCoordinates } from '../View';
import { Layers, ObjectSnap, ObjectType, Point } from 'features/editor/types';
import { angleOf, getClosestNumber } from 'features/editor/utils/Math';
import { getSnappedPoint } from 'features/editor/utils/Grid';
import { getLineWeight } from 'objects/metadata/lineWeights';

export const getRotatedOffset = (
  width: number,
  height: number,
  rotation: number,
  snapRotation: boolean
) => {
  return snapRotation && (rotation === 90 || rotation === 270)
    ? new paper.Point((width - height) / 2, (height - width) / 2)
    : new paper.Point(0, 0);
};

export const computeRotationHandlePosition = (objectId: string) => {
  const group = paper.project.getItem({
    name: objectId,
  });
  const position = paperToBrowserCoordinates(
    group.bounds.bottomCenter.add(new paper.Point(0, 48))
  );
  return { x: position.x, y: position.y };
};

export const getObjectBrowserBounds = (objectId: string) => {
  const group = paper.project.getItem({
    name: objectId,
  });
  const result = {
    topLeft: paperToBrowserCoordinates(group.bounds.topLeft),
    topRight: paperToBrowserCoordinates(group.bounds.topRight),
    topCenter: paperToBrowserCoordinates(group.bounds.topCenter),
    bottomLeft: paperToBrowserCoordinates(group.bounds.bottomLeft),
    bottomRight: paperToBrowserCoordinates(group.bounds.bottomRight),
    bottomCenter: paperToBrowserCoordinates(group.bounds.bottomCenter),
    center: paperToBrowserCoordinates(group.bounds.center),
    height: group.bounds.height,
    width: group.bounds.width,
  };
  return result;
};

export const handleObjectRotation = (
  objectId: string,
  cursorPosition: Point
) => {
  const committedData = rootStore.file.undoable.objects.getObjectData(objectId);
  const group = paper.project.getItem({
    name: objectId,
  });
  const projectCursorPosition = browserToPaperCoordinates(cursorPosition);
  let rotation =
    committedData.rotation +
    angleOf(group.bounds.center, projectCursorPosition) -
    90;
  if (rotation < 0) rotation += 360;
  rotation %= 360;
  rotation = Math.round(rotation);
  if (rotation === 360) rotation = 0;
  if (
    committedData.snapX !== ObjectSnap.none ||
    committedData.snapY !== ObjectSnap.none ||
    committedData.snapRotation === 90
  ) {
    rotation = getClosestNumber(rotation, [0, 90, 180, 270, 360]) % 360;
  }
  const hasWallOpening = !!ObjectMetadata[committedData.objectKey]?.wallOpening;
  const rotatedOffset = getRotatedOffset(
    committedData.width * (rootStore.file.sheet?.PPU || 1),
    committedData.height * (rootStore.file.sheet?.PPU || 1) +
      (hasWallOpening ? rootStore.file.sheet?.cellSizePixels || 0 : 0),
    rotation,
    committedData.snapX !== ObjectSnap.none ||
      committedData.snapY !== ObjectSnap.none
  );
  const snappedPoint = getSnappedPoint(
    new paper.Point(committedData.x, committedData.y).add(rotatedOffset),
    committedData.snapX,
    committedData.snapY,
    committedData.width * (rootStore.file.sheet?.PPU || 1),
    committedData.height * (rootStore.file.sheet?.PPU || 1) +
      (hasWallOpening ? rootStore.file.sheet?.cellSizePixels || 0 : 0),
    rotation
  ).subtract(rotatedOffset);
  rootStore.overridedObjects.setOverridedObject({
    ...committedData,
    rotation,
    x: snappedPoint.x,
    y: snappedPoint.y,
  });
  rootStore.mouseTooltip.setData(
    rotation <= 180 ? `${rotation}°` : `-${360 - rotation}°`
  );
};

export const handleObjectRotationStop = (objectId: string) => {
  const overrideData = rootStore.overridedObjects.getObjectData(objectId);
  rootStore.overridedObjects.clear();
  rootStore.mouseTooltip.clearData();
  rootStore.file.undoable.objects.setObject(overrideData);
};

const setGroupStrokeStyle = (
  group: paper.Item,
  color: paper.Color,
  lineWeight?: number
) => {
  group.children?.forEach((child) => {
    if (child.hasStroke()) {
      child.strokeColor = color;
      if (lineWeight) child.strokeWidth = lineWeight;
    }
    child.children?.forEach((child) => {
      if (child.hasStroke()) {
        child.strokeColor = color;
        if (lineWeight) child.strokeWidth = lineWeight;
      }
      child.children?.forEach((child) => {
        if (child.hasStroke()) {
          child.strokeColor = color;
          if (lineWeight) child.strokeWidth = lineWeight;
        }
      });
    });
  });
  if (group.hasStroke()) {
    group.strokeColor = color;
    if (lineWeight) group.strokeWidth = lineWeight;
  }
};

const createObjectGroup = (object: ObjectModel) => {
  const objectMeta = ObjectMetadata[object.objectKey];
  // load object symbol (svg)
  const svg = paper.project.importSVG(getObjectSymbol(object.objectKey), {
    expandShapes: true,
  });
  if (svg) {
    // create a group
    const group = new paper.Group();
    group.applyMatrix = false;
    group.name = object.id;
    group.addChild(svg);
    // set object dimensions
    svg.bounds.height = object.height * (rootStore.file.sheet?.PPU || 1);
    svg.bounds.width = object.width * (rootStore.file.sheet?.PPU || 1);
    const isSelected = rootStore.selectedObjects.isSelected(object.id);
    const strokeColor = new paper.Color(
      isSelected
        ? rootStore.ui.theme.selectedObjectColor
        : rootStore.ui.theme.wallColor
    );
    const strokeWidth = isSelected
      ? (rootStore.file.sheet?.cellSizePixels || 10) * (1 / 10)
      : (rootStore.file.sheet?.cellSizePixels || 10) *
        getLineWeight(objectMeta.type);
    // check if object has a wall opening
    const hasWallOpening = !!objectMeta?.wallOpening;
    if (hasWallOpening) {
      const rect = new paper.Path.Rectangle(
        svg.bounds.bottomLeft,
        svg.bounds.bottomRight.add(
          new paper.Point(0, rootStore.file.sheet?.cellSizePixels || 0)
        )
      );
      group.addChild(rect);
      // hack: inside stroke
      const center = rect.bounds.center;
      rect.bounds.height -= strokeWidth;
      rect.bounds.width -= strokeWidth;
      rect.bounds.center = center;
      // style
      rect.fillColor = new paper.Color(1, 1, 1);
      rect.strokeWidth = strokeWidth;
      rect.strokeColor = strokeColor;
    }
    // set object color
    if (rootStore.file.sheet) {
      // @TODO: get stoke width by item type
      // svg.strokeWidth = strokeWidth;
      svg.children.forEach((child) => {
        if (child.hasStroke()) {
          child.strokeColor = strokeColor;
          child.strokeWidth = strokeWidth;
        }
      });
    }
    // set group position
    const gridBoundingRect = paper.project.getItem({
      name: 'GRID_BOUNDING_RECT',
    });
    group.bounds.topLeft = gridBoundingRect.bounds.topLeft.add(
      new paper.Point(object.x, object.y)
    );
    group.rotation = object.rotation;
    group.scaling = new paper.Point(
      object.flippedHorizontal ? -1 : 1,
      object.flippedVertical ? -1 : 1
    );
    // insert at correct z stack position by object type
    const objectLayer = getLayerByName(Layers.objects);
    if (objectLayer) {
      const stackMarker = objectLayer.getItem({
        name: `_${objectMeta.type}_`,
      });
      group.insertBelow(stackMarker);
    }

    return group;
  }
  return null;
};

export const handleObjectStateChanges = (
  objectsSnapshot: ObjectsSnapshotOut
) => {
  if (!rootStore.file.sheet) return;
  createOrActivateLayer(Layers.objects, {
    removeChildren: true,
  });

  Object.values(JSON.parse(objectsSnapshot.json) as ObjectMap).forEach(
    (object) => {
      createObjectGroup(object);
    }
  );
};

export const handleOverridedObjectsStateChange = (
  overridedObjectsSnapshot: ObjectsSnapshotOut
) => {
  if (!rootStore.file.sheet) return;
  createOrActivateLayer(Layers.objects);

  Object.values(JSON.parse(overridedObjectsSnapshot.json) as ObjectMap).forEach(
    (object) => {
      const group =
        paper.project.getItem({
          name: object.id,
        }) || createObjectGroup(object);
      if (group) {
        // set item group's position
        const gridBoundingRect = paper.project.getItem({
          name: 'GRID_BOUNDING_RECT',
        });
        group.rotation = 0;
        group.bounds.topLeft = gridBoundingRect.bounds.topLeft.add(
          new paper.Point(object.x, object.y)
        );
        group.rotation = object.rotation;
        group.scaling = new paper.Point(
          object.flippedHorizontal ? -1 : 1,
          object.flippedVertical ? -1 : 1
        );
      }
    }
  );
};

let prevState: SelectedObjectsSnapshotOut;

export const handleSelectedObjectsStateChange = (
  snapshot: SelectedObjectsSnapshotOut
) => {
  const selectedLineWeight =
    (rootStore.file.sheet?.cellSizePixels || 10) * (1 / 10);
  snapshot.ids.forEach((id) => {
    const group = paper.project.getItem({
      name: id,
    });
    if (group) {
      const strokeColor = new paper.Color(
        rootStore.ui.theme.selectedObjectColor
      );
      setGroupStrokeStyle(group, strokeColor, selectedLineWeight);
    }
  });

  if (prevState) {
    prevState.ids.forEach((id) => {
      if (!snapshot.ids.some((i) => i === id)) {
        const group = paper.project.getItem({
          name: id,
        });
        if (group) {
          const objectData = rootStore.file.undoable.objects.getObjectData(id);
          const lineWeight =
            (rootStore.file.sheet?.cellSizePixels || 10) *
            getLineWeight(
              objectData
                ? ObjectMetadata[objectData.objectKey].type
                : ObjectType.appliance
            );
          const strokeColor = new paper.Color(rootStore.ui.theme.wallColor);
          setGroupStrokeStyle(group, strokeColor, lineWeight);
        }
      }
    });
  }

  prevState = snapshot;
};

import { APP_BAR_HEIGHT_PX, TRANSITION_TIME } from 'features/editor/config';
import styled from 'styled-components';

interface ExportPanelWrapperProps {
  isOpen: boolean;
  width: number;
}

export const ExportPanelWrapper = styled.div.attrs(
  (props: ExportPanelWrapperProps) => ({
    style: {
      transform: props.isOpen
        ? 'translateX(0px)'
        : `translateX(${props.width}px)`,
    },
  })
)<ExportPanelWrapperProps>`
  position: absolute;
  top: ${`${APP_BAR_HEIGHT_PX}px`};
  right: 0px;
  z-index: 999;
  height: ${`calc(100vh - ${APP_BAR_HEIGHT_PX}px)`};
  width: ${({ width }) => `${width}px`};
  background: #fff;
  transition: ${`transform ${TRANSITION_TIME}s`};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

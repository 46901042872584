import { ObjectType } from 'features/editor/types';
/**
 *
 * @param objectType
 * @returns the object type's line weight as a fraction of wall thickness
 */
export const getLineWeight = (objectType: ObjectType) => {
  switch (objectType) {
    case ObjectType.door:
    case ObjectType.window:
    case ObjectType.stairs:
      return 1 / 25;
    case ObjectType.appliance:
    case ObjectType.kitchenSink:
    case ObjectType.cabinet:
    case ObjectType.vanity:
    case ObjectType.bathroomSink:
    case ObjectType.bathtub:
    case ObjectType.shape:
    case ObjectType.shower:
    case ObjectType.utility:
    case ObjectType.toilet:
      return 1 / 25;
    case ObjectType.bed:
    case ObjectType.chair:
    case ObjectType.desk:
    case ObjectType.sofa:
    case ObjectType.electronics:
      return 1 / 35;
    default:
      return 1 / 35;
  }
};

const WallToolIcon = ({ width = 24, height = 24, color = '#fff' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: 'scaleX(-1)' }}
  >
    <circle cx="18" cy="18" r="1" fill={color} />
    <circle cx="18" cy="18" r="1" fill={color} />
    <circle cx="5" cy="5" r="1" fill={color} />
    <circle cx="5" cy="5" r="1" fill={color} />
    <path d="M6.5 6.5L16.5 16.5" stroke={color} />
  </svg>
);

export default WallToolIcon;

import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Bathtubs = <ObjectMap>{
  //
  // Bathtub
  //
  BATHTUB: {
    key: 'BATHTUB',
    title: 'Bathtub',
    variant: 'alcove',
    type: ObjectType.bathtub,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 30", l: 60"', width: 30, height: 60 },
        { label: 'w: 34", l: 60"', width: 34, height: 60 },
      ],
      metric: [
        { label: 'w: 762mm, l: 1524mm', width: 762, height: 1524 },
        { label: 'w: 864mm, l: 1524mm', width: 864, height: 1524 },
      ],
    },
  },
  BATHTUB_FREESTANDING: {
    key: 'BATHTUB_FREESTANDING',
    title: 'Bathtub',
    variant: 'freestanding',
    type: ObjectType.bathtub,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: 'w: 30", l: 60"', width: 30, height: 60 },
        { label: 'w: 34", l: 60"', width: 34, height: 60 },
      ],
      metric: [
        { label: 'w: 762mm, l: 1524mm', width: 762, height: 1524 },
        { label: 'w: 864mm, l: 1524mm', width: 864, height: 1524 },
      ],
    },
  },
};

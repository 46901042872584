import { APP_BAR_HEIGHT_PX, TRANSITION_TIME } from 'features/editor/config';
import styled from 'styled-components';

interface ObjectPanelWrapperProps {
  isOpen: boolean;
  width: number;
}

export const ObjectPanelWrapper = styled.div.attrs(
  (props: ObjectPanelWrapperProps) => ({
    style: {
      transform: props.isOpen
        ? 'translateX(0px)'
        : `translateX(${props.width}px)`,
      width: props.width,
    },
  })
)<ObjectPanelWrapperProps>`
  position: absolute;
  top: ${`${APP_BAR_HEIGHT_PX}px`};
  right: 0px;
  z-index: 999;
  height: ${`calc(100vh - ${APP_BAR_HEIGHT_PX}px)`};
  background: #fff;
  transition: ${`transform ${TRANSITION_TIME}s`};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  margin: 16px 40px 8px;
`;

export const SearchInput = styled.input`
  width: calc(100% - 52px);
  height: 32px;
  padding: 0 0 0 38px;
  font-size: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline-color: #008cea;
`;

export const SearchInputLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 33px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ScrollArea = styled.div`
  height: calc(100% - 68px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 2px;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const Tip = styled.div`
  display: flex;
  margin: 12px 40px 10px 40px;
  color: #444;
  background: #f8f8f8;
  background: linear-gradient(
    20deg,
    rgba(242, 242, 242, 1) 0%,
    rgba(250, 250, 250, 1) 70%
  );

  padding: 16px;
  border-radius: 6px;

  border: 1px solid #222;

  p {
    margin: 0;
    line-height: 20px;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  button {
    cursor: pointer;
    background: none;
    border: none;
  }
`;

import styled from 'styled-components';

interface WrapperProps {
  shiftX?: number;
}

export const Wrapper = styled.div.attrs((props: WrapperProps) => ({
  style: {
    transform: !props.shiftX ? undefined : `translateX(-${props.shiftX}px)`,
  },
}))<WrapperProps>`
  position: fixed;
  bottom: 24px;
  right: 180px;
  border-radius: 6px;
  z-index: 1;
  box-shadow: 1px 1px 3px rgba(50, 50, 50, 0.1);
  border: 1px solid #e0e0e0;
`;

export const ToggleButton = styled.button`
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f8f8; // zoom button bg
  color: #333;

  &:hover {
    background: #f0f0f0; // zoom button bg
  }
`;

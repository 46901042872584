import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Showers = <ObjectMap>{
  //
  // Showers
  //
  SHOWER_SQUARE: {
    key: 'SHOWER_SQUARE',
    title: 'Shower',
    variant: 'square',
    type: ObjectType.shower,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 36", d: 36"', width: 36, height: 36 },
        { label: 'w: 32", d: 32"', width: 32, height: 32 },
        { label: 'w: 48", d: 48"', width: 48, height: 48 },
      ],
      metric: [
        { label: 'w: 914mm, d: 914mm', width: 914, height: 914 },
        { label: 'w: 813mm, d: 813mm', width: 813, height: 813 },
        { label: 'w: 1219mm, d: 1219mm', width: 1219, height: 1219 },
      ],
    },
  },
  SHOWER_RECTANGLE: {
    key: 'SHOWER_RECTANGLE',
    title: 'Shower',
    variant: 'rectangle',
    type: ObjectType.shower,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 36", d: 60"', width: 36, height: 60 },
        { label: 'w: 36", d: 66"', width: 36, height: 66 },
        { label: 'w: 36", d: 72"', width: 36, height: 72 },
      ],
      metric: [
        { label: 'w: 914mm, d: 1524mm', width: 914, height: 1524 },
        { label: 'w: 914mm, d: 1676mm', width: 914, height: 1676 },
        { label: 'w: 914mm, d: 1829mm', width: 914, height: 1829 },
      ],
    },
  },
};

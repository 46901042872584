const FileIcon = ({ width = 24, height = 24, color = '#000000' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M6 18V5C6 4.44772 6.44772 4 7 4H12.5858C12.851 4 13.1054 4.10536 13.2929 4.29289L17.7071 8.70711C17.8946 8.89464 18 9.149 18 9.41421V18C18 18.5523 17.5523 19 17 19H7C6.44772 19 6 18.5523 6 18Z"
        stroke={color}
      />
      <path d="M18 9H13.25C13.1119 9 13 8.88807 13 8.75V4" stroke={color} />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FileIcon;

import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Cabinets = <ObjectMap>{
  //
  // Cabinets
  //
  BASE_CABINET: {
    key: 'BASE_CABINET',
    title: 'Base Cabinet',
    type: ObjectType.cabinet,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 30", d: 24"', width: 30, height: 24 },
        { label: 'w: 9", d: 24"', width: 9, height: 24 },
        { label: 'w: 12", d: 24"', width: 12, height: 24 },
        { label: 'w: 15", d: 24"', width: 15, height: 24 },
        { label: 'w: 18", d: 24"', width: 18, height: 24 },
        { label: 'w: 21", d: 24"', width: 21, height: 24 },
        { label: 'w: 24", d: 24"', width: 24, height: 24 },
        { label: 'w: 27", d: 24"', width: 27, height: 24 },
        { label: 'w: 33", d: 24"', width: 33, height: 24 },
        { label: 'w: 36", d: 24"', width: 36, height: 24 },
        { label: 'w: 42", d: 24"', width: 42, height: 24 },
      ],
      metric: [
        { label: 'w: 762mm, d: 610mm', width: 762, height: 610 },
        { label: 'w: 229mm, d: 610mm', width: 229, height: 610 },
        { label: 'w: 305mm, d: 610mm', width: 305, height: 610 },
        { label: 'w: 381mm, d: 610mm', width: 381, height: 610 },
        { label: 'w: 457mm, d: 610mm', width: 457, height: 610 },
        { label: 'w: 533mm, d: 610mm', width: 533, height: 610 },
        { label: 'w: 610mm, d: 610mm', width: 610, height: 610 },
        { label: 'w: 686mm, d: 610mm', width: 686, height: 610 },
        { label: 'w: 838mm, d: 610mm', width: 838, height: 610 },
        { label: 'w: 914mm, d: 610mm', width: 914, height: 610 },
        { label: 'w: 1067mm, d: 610mm', width: 1067, height: 610 },
      ],
    },
  },
  KITCHEN_COUNTERTOP: {
    key: 'KITCHEN_COUNTERTOP',
    title: 'Countertop',
    type: ObjectType.cabinet,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 30", d: 24"', width: 30, height: 24 },
        { label: 'w: 9", d: 24"', width: 9, height: 24 },
        { label: 'w: 12", d: 24"', width: 12, height: 24 },
        { label: 'w: 15", d: 24"', width: 15, height: 24 },
        { label: 'w: 18", d: 24"', width: 18, height: 24 },
        { label: 'w: 21", d: 24"', width: 21, height: 24 },
        { label: 'w: 24", d: 24"', width: 24, height: 24 },
        { label: 'w: 27", d: 24"', width: 27, height: 24 },
        { label: 'w: 33", d: 24"', width: 33, height: 24 },
        { label: 'w: 36", d: 24"', width: 36, height: 24 },
        { label: 'w: 42", d: 24"', width: 42, height: 24 },
      ],
      metric: [
        { label: 'w: 762mm, d: 610mm', width: 762, height: 610 },
        { label: 'w: 229mm, d: 610mm', width: 229, height: 610 },
        { label: 'w: 305mm, d: 610mm', width: 305, height: 610 },
        { label: 'w: 381mm, d: 610mm', width: 381, height: 610 },
        { label: 'w: 457mm, d: 610mm', width: 457, height: 610 },
        { label: 'w: 533mm, d: 610mm', width: 533, height: 610 },
        { label: 'w: 610mm, d: 610mm', width: 610, height: 610 },
        { label: 'w: 686mm, d: 610mm', width: 686, height: 610 },
        { label: 'w: 838mm, d: 610mm', width: 838, height: 610 },
        { label: 'w: 914mm, d: 610mm', width: 914, height: 610 },
        { label: 'w: 1067mm, d: 610mm', width: 1067, height: 610 },
      ],
    },
  },
  BASE_CABINET_CORNER_90: {
    key: 'BASE_CABINET_CORNER_90',
    title: 'Corner Cabinet',
    variant: '90°',
    type: ObjectType.cabinet,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [{ label: 'd: 24"', width: 36, height: 36 }],
      metric: [{ label: 'w: 610mm', width: 914, height: 914 }],
    },
  },
  BASE_CABINET_CORNER_45: {
    key: 'BASE_CABINET_CORNER_45',
    title: 'Corner Cabinet',
    variant: '45°',
    type: ObjectType.cabinet,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [{ label: 'd: 24"', width: 36, height: 36 }],
      metric: [{ label: 'w: 610mm', width: 914, height: 914 }],
    },
  },
};

import { ToolEnum } from 'features/editor/types';
import rootStore from 'state/models/Root';
import { getLayerByName } from '../../Paper/Layers';

export const resetViewOnToolChange = (tool: ToolEnum) => {
  if (tool === ToolEnum.wall) {
    rootStore.view.setHoveredCellOutlineColor('#0099ff');
    rootStore.view.setShowDistanceToWalls(true);
  } else if (tool === ToolEnum.room) {
    rootStore.view.setHoveredCellOutlineColor('#0099ff');
    rootStore.view.setShowDistanceToWalls(true);
  } else if (tool === ToolEnum.erase) {
    rootStore.view.setHoveredCellOutlineColor('#ff4242');
    rootStore.view.setShowDistanceToWalls(true);
  } else if (tool === ToolEnum.pen) {
    rootStore.view.setHoveredCellOutlineColor('#0099ff');
    rootStore.view.setShowDistanceToWalls(true);
  } else {
    rootStore.view.setHoveredCellOutlineColor(null);
    rootStore.view.setShowDistanceToWalls(false);
    getLayerByName('HOVERED_CELL')?.removeChildren();
  }
};

import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Toilets = <ObjectMap>{
  //
  // Toilets
  //
  TOILET: {
    key: 'TOILET',
    title: 'Toilet',
    variant: 'elongated',
    type: ObjectType.toilet,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'd: 28"', width: 17, height: 28 },
        { label: 'd: 30"', width: 17, height: 30 },
      ],
      metric: [
        { label: 'd: 711mm', width: 432, height: 711 },
        { label: 'd: 762mm', width: 432, height: 762 },
      ],
    },
  },
  TOILET_ROUND: {
    key: 'TOILET_ROUND',
    title: 'Toilet',
    variant: 'round',
    type: ObjectType.toilet,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'd: 22"', width: 16, height: 22 },
        { label: 'd: 26"', width: 16, height: 26 },
      ],
      metric: [
        { label: 'd: 558mm', width: 406, height: 558 },
        { label: 'd: 660mm', width: 406, height: 660 },
      ],
    },
  },
};

const RoomToolIcon = ({ width = 24, height = 24, color = '#fff' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 7V18H16M7 5H18V16" stroke={color} />
    <circle cx="18" cy="18" r="1" fill={color} />
    <circle cx="18" cy="18" r="1" fill={color} />
    <circle cx="5" cy="5" r="1" fill={color} />
    <circle cx="5" cy="5" r="1" fill={color} />
  </svg>
);

export default RoomToolIcon;

import { types, Instance } from 'mobx-state-tree';

const Keyboard = types
  .model({
    metaKey: types.boolean,
    altKey: types.boolean,
    shiftKey: types.boolean,
    spaceKey: types.boolean,
  })
  .actions((self) => ({
    setMetaKey(value: boolean) {
      self.metaKey = value;
    },
    setAltKey(value: boolean) {
      self.altKey = value;
    },
    setShiftKey(value: boolean) {
      self.shiftKey = value;
    },
    setSpaceKey(value: boolean) {
      self.spaceKey = value;
    },
  }));

export type KeyboardInstance = Instance<typeof Keyboard>;

export default Keyboard;

import React from 'react';

const LogoIcon = ({ width = 24, height = 24, color = '#fff' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M5 10.1071V5.6C5 5.26863 5.26863 5 5.6 5H17.4C17.7314 5 18 5.26863 18 5.6V14.6143C18 14.9457 17.7314 15.2143 17.4 15.2143H11.1714C10.8401 15.2143 10.5714 15.4829 10.5714 15.8143V17.75C10.5714 17.8881 10.4595 18 10.3214 18H5.6C5.26863 18 5 17.7314 5 17.4V10.1071ZM5 10.1071H13.3571"
        stroke={color}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill={color} />
      </clipPath>
    </defs>
  </svg>
);

export default LogoIcon;

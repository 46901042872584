import { types } from 'mobx-state-tree';
import { TextItemMap, TextItemModel } from '../TextItems';

const OverridedTextItems = types
  .model({ json: types.string })
  .actions((self) => ({
    setOverridedTextItem(item: TextItemModel) {
      const newJson = JSON.parse(self.json);
      newJson[item.id] = item;
      self.json = JSON.stringify(newJson);
    },
    clear() {
      self.json = '{}';
    },
  }))
  .views((self) => ({
    getTextItemData(id: string) {
      return (JSON.parse(self.json) as TextItemMap)[id] || null;
    },
  }));

export default OverridedTextItems;

import {
  APP_BAR_HEIGHT_PX,
  LEFT_PANEL_WIDTH,
  TRANSITION_TIME,
} from 'features/editor/config';
import styled from 'styled-components';

interface LeftPanelProps {
  isOpen: boolean;
}

export const StyledLeftPanel = styled.div.attrs((props: LeftPanelProps) => ({
  style: {
    transform: props.isOpen
      ? 'translateX(0px)'
      : `translateX(-${LEFT_PANEL_WIDTH}px)`,
  },
}))<LeftPanelProps>`
  position: absolute;
  top: ${`${APP_BAR_HEIGHT_PX}px`};
  left: 0px;
  z-index: 999;
  width: ${`${LEFT_PANEL_WIDTH}px`};
  height: ${`calc(100vh - ${APP_BAR_HEIGHT_PX}px)`};
  background: #fff;
  transition: ${`transform ${TRANSITION_TIME}s`};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
`;

export const StyledFileMenuWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto min-content;
`;

export const StyledFileMenuHeader = styled.div`
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  display: grid;
  grid-template-columns: auto min-content;
  padding: 0 4px;

  h1 {
    font-size: 14px;
    font-weight: 500;
    padding: 0 12px;
    align-self: center;
  }

  button {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    /* border: 1px solid rgba(0, 0, 0, 0.1); */
    border: none;
    outline: 1px transparent;

    align-self: center;
    padding: 0 16px;
    height: 28px;
    margin: 0 12px 0 0;
    border-radius: 14px;
    background: rgb(0, 114, 214);
    color: #fff;
    font-weight: bold;

    &:hover {
      background: rgb(0, 130, 224);
    }
  }
`;

export const StyledFileList = styled.div`
  width: 100%;
  min-height: ${`calc(100vh - ${APP_BAR_HEIGHT_PX + 61 + 60}px)`};
  display: flex;
  flex-direction: column;
`;

export const ScrollArea = styled.div`
  height: ${`calc(100vh - ${APP_BAR_HEIGHT_PX + 61 + 60}px)`};
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const FileListItemWrapper = styled.div`
  position: relative;

  button {
    position: absolute;
    z-index: 1;
    top: calc(50% - 16px);
    right: 16px;
    background: none;
    border: none;
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 4px;
    transition: 0.2s;
    color: #777;
    svg {
      width: 16px;
      height: 16px;
    }

    :hover {
      background: #222;
      color: #fff;
    }
  }
`;

export const StyledFileListItem = styled.li`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: none;
  outline: 1px transparent;

  padding: 0 12px;
  margin: 4px 0 0 0;
  height: 64px;
  background: transparent;

  :hover {
    background: rgba(0, 0, 0, 0.03);
  }

  display: grid;
  grid-template-columns: ${`36px ${LEFT_PANEL_WIDTH - 78 - 36}px 24px`};

  div {
    display: grid;
    grid-template-rows: auto min-content;
  }
`;

export const StyledFileListItemTitle = styled.span`
  text-align: left;
  padding: 0 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  font-size: 14px;
  color: #222;
  font-weight: 500;
`;

export const StyledFileListItemSubtitle = styled.span`
  text-align: left;
  padding: 2px 12px 0 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 2px;

  font-weight: 300;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.7);
`;

export const EmptyFileBinWrapper = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  svg {
    opacity: 50%;
  }

  h4 {
    margin: 16px;
    color: #999;
    font-weight: bold;
    font-size: 16px;
  }

  a {
    color: rgba(0, 120, 214, 0.8);
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Dialog = styled.div`
  position: fixed;
  z-index: 99999999;
  top: calc(50% - 72px);
  left: calc(50% - 190px);
  background: #fff;
  height: 124px;
  width: 400px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 24px;
  display: grid;
  grid-template-rows: auto min-content;

  span {
    font-size: 18px;
  }
`;

export const DialogButtonWrapper = styled.div`
  padding: 1px;
  display: grid;
  grid-template-columns: auto min-content min-content;

  button {
    width: 82px;
    padding: 8px 0;
    background: transparent;
    border-radius: 16px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #000;
  }

  #save-changes-button {
    width: 124px;
    font-weight: bold;
    background: transparent;
    border: 1px solid #0072d6;
    border-left: none;
    cursor: pointer;
    background: #0072d6;
    color: #fff;
    margin-left: 8px;
  }

  #dont-save {
    width: 104px;
    background: transparent;
    cursor: pointer;
    font-weight: 400;
    margin: 0;
    border: 1px solid rgba(180, 3, 0, 0.3);
    color: #660700;
    color: rgba(180, 3, 0, 0.8);
  }
`;

import { observer } from 'mobx-react-lite';
import CopyIcon from 'features/editor/icons/CopyIcon';
import DeleteIcon from 'features/editor/icons/DeleteIcon';
import DimensionsIcon from 'features/editor/icons/DimensionsIcon';
import FlipHorizontalIcon from 'features/editor/icons/FlipHorizontalIcon';
import FlipVerticalIcon from 'features/editor/icons/FlipVerticalIcon';
import { useLocalStore } from 'state';
import { Row, Wrapper, Divider } from './styles';
import {
  handleObjectDelete,
  handleObjectDuplicate,
  handleObjectFlipHorizontal,
  handleObjectFlipVertical,
} from 'features/editor/utils/ObjectActions';
import { getMetaKeyCharacter } from 'helpers/getMetaKeyCharacter';
import ObjectMetadata from 'objects/metadata';

const ObjectContextMenu = () => {
  const {
    objectContextMenu,
    closeObjectContextMenu,
    file,
    setObjectDimensionPopover,
  } = useLocalStore();
  if (!objectContextMenu) return null;
  const objectData = file.undoable.objects.getObjectData(
    objectContextMenu.objectId
  );
  const objectLabel = objectData
    ? ObjectMetadata[objectData.objectKey].title
    : 'Object';

  // const handleSnapToGridClick = () => {
  //   // @TODO:
  // };

  // const handleSnapToHalfGridClick = () => {
  //   // @TODO:
  // };

  const menuHeight = 238;

  return (
    <Wrapper
      top={
        objectContextMenu.y + menuHeight > window.innerHeight - 32
          ? objectContextMenu.y - menuHeight
          : objectContextMenu.y
      }
      left={objectContextMenu.x}
    >
      <Row
        onClick={() =>
          handleObjectDuplicate(objectContextMenu.objectId, objectLabel)
        }
      >
        <CopyIcon />
        <p>Duplicate</p>
        <p className="shortcut">{getMetaKeyCharacter()}D</p>
      </Row>
      {/* <Row>
        <PasteIcon />
        <p>Paste</p>
        <p className="shortcut">⌘V</p>
      </Row> */}
      <Row
        onClick={() =>
          handleObjectDelete(objectContextMenu.objectId, objectLabel)
        }
      >
        <DeleteIcon />
        <p>Delete</p>
        <p className="shortcut">DEL</p>
      </Row>

      <Divider />
      <Row
        onClick={() => {
          setObjectDimensionPopover(objectContextMenu.objectId);
          closeObjectContextMenu();
        }}
      >
        <DimensionsIcon />
        <p>Dimensions</p>
      </Row>

      <Divider />
      <Row
        onClick={() => handleObjectFlipHorizontal(objectContextMenu.objectId)}
      >
        <FlipHorizontalIcon />
        <p>Flip horizontal</p>
      </Row>
      <Row onClick={() => handleObjectFlipVertical(objectContextMenu.objectId)}>
        <FlipVerticalIcon />
        <p>Flip vertical</p>
      </Row>

      {/* <Divider />
      <Row
        active={objectData.snapX === ObjectSnap.grid}
        onClick={handleSnapToGridClick}
      >
        <SnapIcon />
        <p>Snap to grid</p>
      </Row>
      <Row
        active={objectData.snapX === ObjectSnap.halfGrid}
        onClick={handleSnapToHalfGridClick}
      >
        <SnapIcon />
        <p>Snap to half grid</p>
      </Row> */}
    </Wrapper>
  );
};

export default observer(ObjectContextMenu);

const ArrowLeftIcon = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 24 24"
  >
    <path fill="currentColor" d="m14 7-5 5 5 5V7z"></path>
  </svg>
);

export default ArrowLeftIcon;

import styled from 'styled-components';

interface WrapperProps {
  shiftX?: number;
}

export const Wrapper = styled.div.attrs((props: WrapperProps) => ({
  style: {
    transform: !props.shiftX ? undefined : `translateX(-${props.shiftX}px)`,
  },
}))<WrapperProps>`
  position: fixed;
  bottom: 24px;
  right: 300px;
  z-index: 1;
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(50, 50, 50, 0.1);
  border: 1px solid #e0e0e0;

  #inner {
    position: relative;
    display: flex;
    flex-direction: row;

    #zoom-button-first {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-right: 1px solid #e0e0e0;
      padding: 8px 10px;
    }

    #zoom-button-last {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-left: 1px solid #e0e0e0;
      padding: 8px 10px;
    }
  }
`;

export const ZoomButton = styled.button`
  border: none;
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: #f8f8f8; // zoom button bg
  color: #333;

  &:hover {
    background: #f0f0f0; // zoom button bg
  }

  &:disabled {
    svg {
      opacity: 40%;
    }
    cursor: default;
    background: #f8f8f8; // zoom button bg
  }
`;

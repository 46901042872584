import { types, Instance, SnapshotIn, onSnapshot } from 'mobx-state-tree';
import { TimeTraveller } from 'mst-middlewares';
import { toJS } from 'mobx';
import { Graph } from '@dagrejs/graphlib';
import UI from 'state/models/UI';
import View, { handleViewChanges } from 'state/models/View';
import Sheet, { SheetSnapshotIn } from 'state/models/Sheet';
import Keyboard from 'state/models/Keyboard';
import Toolbar from 'state/models/Toolbar';
import { ToolEnum } from 'features/editor/types';
import { writeGraphToString } from 'features/editor/utils/Graphlib/Serialize';
import ActiveDrawing from 'state/models/ActiveDrawing';
import Cursor from 'state/models/Cursor';
import MouseTooltip from 'state/models/MouseTooltip';
import Dimensions from 'state/models/Dimensions';
import UserProjects, { ProjectsSnapshotIn } from 'state/models/UserProjects';
import { SaveStatus } from 'state/types';
import ActiveProject, { ActiveProjectSnapshotIn } from '../ActiveProject';
import File, { onFileSnapshot } from '../File';
import OverridedObjects from '../OverridedObjects';
import SelectedObjects from '../SelectedObjects';
import {
  handleOverridedObjectsStateChange,
  handleSelectedObjectsStateChange,
} from 'features/editor/utils/Paper/Objects';
import Objects, { ObjectMap } from '../Objects';
import ObjectContextMenu, {
  ObjectContextMenuSnapshotIn,
} from '../ObjectContextMenu';
import DraggingObjects from '../DraggingObjects';
import Walls from '../Walls';
import { setDocumentTitle } from 'helpers/setDocumentTitle';
// import { trackObjectOverridesCommitted } from 'analytics/editor';
import SelectedWalls from '../SelectedWalls';
import {
  applySelectedWallTranslations,
  handleSelectedWallsStateChange,
} from 'features/editor/utils/Paper/Walls';
import { cleanupToolSideEffects } from 'features/editor/utils/Tools/CleanupToolSideEffects';
import TextItems, { TextItemMap, TextItemModel } from '../TextItems';
import OverridedTextItems from '../OverridedTextItems';

const initialWalls = new Graph({ directed: false });
const sWalls = writeGraphToString(initialWalls);
const initalUndoableSnapshot = {
  walls: {
    json: sWalls,
    isSelected: false,
  },
  objects: {
    json: '{}',
  },
  textItems: {
    json: '{}',
  },
};

const Root = types
  .model({
    userProjects: types.maybeNull(UserProjects),
    activeProject: types.maybeNull(ActiveProject),
    file: File,
    history: types.optional(TimeTraveller, { targetPath: '../file/undoable' }),
    ui: UI,
    toolbar: Toolbar,
    view: View,
    keyboard: Keyboard,
    cursor: Cursor,
    activeDrawing: ActiveDrawing,
    mouseTooltip: MouseTooltip,
    dimensions: Dimensions,
    overridedObjects: OverridedObjects,
    overridedTextItems: OverridedTextItems,
    selectedObjects: SelectedObjects,
    selectedWalls: SelectedWalls,
    draggingObjects: DraggingObjects,
    objectContextMenu: types.maybeNull(ObjectContextMenu),
    objectDimensionPopover: types.maybeNull(types.string),
    saveStatus: types.enumeration<SaveStatus>(
      'SaveStatus',
      Object.values(SaveStatus)
    ),
  })
  .actions((self) => ({
    loadSheet(sheet: SheetSnapshotIn) {
      self.file.sheet = Sheet.create(sheet);
    },
    loadActiveProject(project: ActiveProjectSnapshotIn) {
      self.activeProject = ActiveProject.create(project);
      setDocumentTitle(project.title);
    },
    resetUndoable() {
      self.file.undoable.walls = Walls.create(initalUndoableSnapshot.walls);
      self.file.undoable.objects = Objects.create(
        initalUndoableSnapshot.objects
      );
      self.file.undoable.textItems = undefined;
    },
    clearHistory() {
      self.history.history.clear();
      self.history.undoIdx = 0;
    },
    setUserProjects(projects: ProjectsSnapshotIn) {
      self.userProjects = UserProjects.create(projects);
    },
    removeUserProject(id: string) {
      if (self.userProjects) {
        self.userProjects = UserProjects.create([
          ...self.userProjects.map((p) => toJS(p)).filter((p) => p.id !== id),
        ]);
      }
    },
    setSaveStatus(value: SaveStatus) {
      self.saveStatus = value;
    },
    setObjectContextMenu(snapshotIn: ObjectContextMenuSnapshotIn) {
      self.objectContextMenu = ObjectContextMenu.create(snapshotIn);
    },
    closeObjectContextMenu() {
      self.objectContextMenu = null;
    },
    setObjectDimensionPopover(objectId: string) {
      self.objectDimensionPopover = objectId;
    },
    closeObjectDimensionPopover() {
      self.objectDimensionPopover = null;
    },
    updateActiveProjectTitle(value: string, initialSaveStatus?: SaveStatus) {
      const activeProjectId = self.activeProject?.id;
      self.activeProject?.updateTitle(value);
      if (activeProjectId) {
        const proj = self.userProjects?.find((p) => p.id === activeProjectId);
        proj?.setTitle(value);
      }
      self.saveStatus = initialSaveStatus || SaveStatus.saved;
    },
    applyOverrides() {
      // Objects
      Object.values(
        JSON.parse(self.overridedObjects.json) as ObjectMap
      ).forEach((object) => {
        self.file.undoable.objects.setObject(object);
      });
      // Text
      Object.values(
        JSON.parse(self.overridedTextItems.json) as TextItemMap
      ).forEach((item) => {
        if (self.file.undoable.textItems) {
          self.file.undoable.textItems.setTextItem(item);
        } else {
          self.file.undoable.textItems = TextItems.create({ json: '{}' });
          self.file.undoable.textItems.setTextItem(item);
        }
      });
      // Walls
      if (
        self.selectedWalls.cells?.length &&
        (self.selectedWalls.rowDelta || self.selectedWalls.columnDelta)
      ) {
        const newWallJson = applySelectedWallTranslations(
          self.file.undoable.walls.json,
          self.selectedWalls
        );
        self.file.undoable.walls.setWallJSON(newWallJson);
      }
      // Cleanup
      self.selectedWalls.clear();
      self.overridedObjects.clear();
      self.overridedTextItems.clear();
      // @TODO: trackObjectOverridesCommitted();
    },
    deleteSelection() {
      const newWallJson = applySelectedWallTranslations(
        self.file.undoable.walls.json,
        self.selectedWalls,
        { forceErase: true }
      );
      self.file.undoable.walls.setWallJSON(newWallJson);
      self.file.undoable.objects.deleteMultiple(self.selectedObjects.ids);
      self.selectedObjects.clear();
      self.selectedWalls.clear();
      cleanupToolSideEffects();
    },
    initializeTextItems(item?: TextItemModel) {
      self.file.undoable.textItems = TextItems.create({ json: '{}' });
      if (item) self.file.undoable.textItems?.setTextItem(item);
    },
  }));

const initialState: SnapshotIn<typeof Root> = {
  userProjects: null,
  activeProject: null,
  saveStatus: SaveStatus.no_file,
  file: {
    sheet: null,
    undoable: initalUndoableSnapshot,
  },
  ui: {
    leftPanelOpen: false,
    objectPanelOpen: false,
    objectPanelWidth: 508,
    newFileModalOpen: false,
    welcomeModalOpen: true,
    resetPasswordModalOpen: false,
    projectLimitModalOpen: false,
    loginModalOpen: false,
    exportPanelOpen: false,
    theme: {
      wallColor: '#606060',
      wallColor_uncommited: '#0099ff',
      roomFillColor: 'rgba(200, 200, 200, 0.10)',
      selectedObjectColor: '#008fed' || '#6c42f5',
      dimensionColor: '#1596ed',
      mouseTooltipColor: '#444',
    },
  },
  toolbar: {
    current: ToolEnum.move,
  },
  view: {
    panMode: false,
    showGrid: true,
    showDistanceToWalls: false,
    isChanging: false,
    zoom: 1,
    cursor: 'default',
  },
  keyboard: {
    metaKey: false,
    altKey: false,
    shiftKey: false,
    spaceKey: false,
  },
  cursor: {
    clientX: 0,
    clientY: 0,
    mouseDown: false,
    rightClick: false,
  },
  activeDrawing: {
    penDown: false,
    eraserDown: false,
    continuousDrag: false,
  },
  mouseTooltip: {
    data: null,
  },
  dimensions: {
    activeDrawing: null,
  },
  overridedObjects: {
    json: '{}',
  },
  overridedTextItems: {
    json: '{}',
  },
  selectedObjects: {
    ids: [],
  },
  selectedWalls: {
    cells: null,
    rowDelta: 0,
    columnDelta: 0,
  },
  draggingObjects: {
    data: null,
  },
};

export type RootInstanceType = Instance<typeof Root>;
const rootStore = Root.create(initialState);

/* Listen for snapshots */
onSnapshot(rootStore.file, onFileSnapshot);
onSnapshot(rootStore.overridedObjects, handleOverridedObjectsStateChange);
onSnapshot(rootStore.selectedObjects, handleSelectedObjectsStateChange);
onSnapshot(rootStore.selectedWalls, handleSelectedWallsStateChange);
onSnapshot(rootStore.view, handleViewChanges);

export default rootStore;

import { types, Instance } from 'mobx-state-tree';

// const DataRow = types.model({
//   key: types.string,
//   value: types.string,
// });

// const TooltipData = types.array(DataRow);
// export type TooltipDataInstance = Instance<typeof TooltipData>;

const MouseTooltip = types
  .model({
    data: types.maybeNull(types.string),
  })
  .actions((self) => ({
    setData(value: string) {
      self.data = value;
    },
    clearData() {
      self.data = null;
    },
  }));

export type MouseTooltipInstance = Instance<typeof MouseTooltip>;

export default MouseTooltip;

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { observer } from 'mobx-react-lite';
import { useLocalStore } from 'state';
import {
  Divider,
  DropdownButton,
  FontSizeDisplay,
  MenuItem,
  PopupMenu,
  ToolbarButton,
  ToolbarWrapper,
} from './styles';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from '@lexical/list';
import {
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
} from 'lexical';
import { mergeRegister } from '@lexical/utils';
import BulletListIcon from 'features/editor/icons/BulletListIcon';
import NumberListIcon from 'features/editor/icons/NumberListIcon';
import DeleteIcon from 'features/editor/icons/DeleteIcon';
import CloseIcon from 'features/editor/icons/CloseIcon';
import { blurAll } from 'helpers/blurAll';
import BoldIcon from 'features/editor/icons/BoldIcon';
import ItalicIcon from 'features/editor/icons/ItalicIcon';
import ColorSwatchIcon from 'features/editor/icons/ColorSwatchIcon';
import { TEXT_COLORS, TEXT_SCALE, TEXT_SIZES } from './constants';
import { SaveStatus } from 'state/types';
import { useCallback, useEffect, useState } from 'react';
import ExpandDownIcon from 'features/editor/icons/ExpandDownIcon';
import CopyIcon from 'features/editor/icons/CopyIcon';
import Tooltip from 'ui/components/Tooltip';

const LowPriority = 1;

type ToolbarPluginProps = {
  itemId: string;
  isFocused: boolean;
};

const ToolbarPlugin = observer(({ itemId, isFocused }: ToolbarPluginProps) => {
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [fontMenuOpen, setFontMenuOpen] = useState(false);
  const [editor] = useLexicalComposerContext();
  const { file, view, overridedTextItems, applyOverrides, setSaveStatus } =
    useLocalStore();
  const textItems = file.undoable.textItems;
  const data =
    overridedTextItems.getTextItemData(itemId) ||
    textItems?.getTextItemData(itemId);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
    }
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    if (!isFocused) {
      setFontMenuOpen(false);
    }
  }, [isFocused]);

  const formatText = (value: 'bold' | 'italic') => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, value);
    setSaveStatus(SaveStatus.unsaved);
  };

  const formatBulletList = () => {
    editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    setSaveStatus(SaveStatus.unsaved);
  };

  const formatNumberedList = () => {
    editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    setSaveStatus(SaveStatus.unsaved);
  };

  const setFontSize = (value: number) => {
    const prev =
      overridedTextItems.getTextItemData(itemId) ||
      textItems?.getTextItemData(itemId);

    if (prev) {
      setSaveStatus(SaveStatus.unsaved);
      overridedTextItems.setOverridedTextItem({
        ...prev,
        fontSize: value,
      });
    }
  };

  const setTextColor = (color: string) => {
    const prev =
      overridedTextItems.getTextItemData(itemId) ||
      textItems?.getTextItemData(itemId);

    if (prev) {
      setSaveStatus(SaveStatus.unsaved);
      overridedTextItems.setOverridedTextItem({
        ...prev,
        color,
      });
    }
  };

  const duplicateItem = () => {
    applyOverrides();
    const dupId = textItems?.duplicateTextItem(itemId, 120, 0);
    setSaveStatus(SaveStatus.unsaved);

    if (dupId) {
      setTimeout(() => {
        document.getElementById(dupId)?.focus();
      }, 100);
    }
  };

  const deleteItem = () => {
    textItems?.deleteTextItem(itemId);
    overridedTextItems.clear();
    setSaveStatus(SaveStatus.unsaved);
  };

  return (
    <ToolbarWrapper
      scale={1 / view.zoom}
      onMouseDown={(e) => e.preventDefault()}
      hide={!isFocused}
    >
      <FontSizeDisplay onClick={() => setFontMenuOpen(true)}>
        <span>{(data?.fontSize || 24) / TEXT_SCALE}</span>
        <DropdownButton type="button">
          <ExpandDownIcon />
        </DropdownButton>
        {fontMenuOpen && (
          <PopupMenu>
            {TEXT_SIZES.map((size) => (
              <MenuItem
                key={size.label}
                isActive={size.value === data?.fontSize}
                onMouseDown={() => {
                  setFontSize(size.value);
                  setFontMenuOpen(false);
                }}
              >
                {size.label}
              </MenuItem>
            ))}
          </PopupMenu>
        )}
      </FontSizeDisplay>

      <Divider />

      <Tooltip content="Bold">
        <ToolbarButton
          id="toolbar-button"
          isActive={isBold}
          onMouseDown={(e) => {
            e.preventDefault();
            formatText('bold');
          }}
        >
          <BoldIcon />
        </ToolbarButton>
      </Tooltip>
      <Tooltip content="Italic">
        <ToolbarButton
          type="button"
          id="toolbar-button"
          isActive={isItalic}
          style={{ marginLeft: 4 }}
          onMouseDown={(e) => {
            e.preventDefault();
            formatText('italic');
          }}
        >
          <ItalicIcon />
        </ToolbarButton>
      </Tooltip>

      <Divider />

      <Tooltip content="Bullet List">
        <ToolbarButton
          id="toolbar-button"
          onMouseDown={(e) => {
            e.preventDefault();
            formatBulletList();
          }}
        >
          <BulletListIcon />
        </ToolbarButton>
      </Tooltip>
      <Tooltip content="Number List">
        <ToolbarButton
          type="button"
          id="toolbar-button"
          style={{ marginLeft: 4 }}
          onMouseDown={(e) => {
            e.preventDefault();
            formatNumberedList();
          }}
        >
          <NumberListIcon />
        </ToolbarButton>
      </Tooltip>

      <Divider />

      {TEXT_COLORS.map((swatch) => (
        <Tooltip key={swatch.value} content={swatch.label}>
          <ToolbarButton
            type="button"
            id="toolbar-button"
            style={{ marginLeft: 0 }}
            isActive={swatch.value === data.color}
            onMouseDown={(e) => {
              e.preventDefault();
              setTextColor(swatch.value);
            }}
          >
            <ColorSwatchIcon color={swatch.value} />
          </ToolbarButton>
        </Tooltip>
      ))}

      <Divider />
      <Tooltip content="Duplicate">
        <ToolbarButton
          type="button"
          id="toolbar-button"
          style={{ marginLeft: 0 }}
          onMouseDown={(e) => {
            e.preventDefault();
            duplicateItem();
          }}
        >
          <CopyIcon />
        </ToolbarButton>
      </Tooltip>
      <Tooltip content="Delete">
        <ToolbarButton
          type="button"
          id="toolbar-button"
          style={{ marginLeft: 4 }}
          onMouseDown={(e) => {
            e.preventDefault();
            deleteItem();
          }}
        >
          <DeleteIcon />
        </ToolbarButton>
      </Tooltip>

      <Divider />

      <Tooltip content="Unselect" keyShortcut="Esc">
        <ToolbarButton
          type="button"
          id="toolbar-button"
          style={{ marginLeft: 0 }}
          onMouseDown={(e) => {
            e.preventDefault();
            blurAll();
          }}
        >
          <CloseIcon />
        </ToolbarButton>
      </Tooltip>
    </ToolbarWrapper>
  );
});

export default ToolbarPlugin;

import { trackProjectTitleChanged } from 'analytics/project';
import { blurAll } from 'helpers/blurAll';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useLocalStore } from 'state';
import { SaveStatus } from 'state/types';
import FileActionButtons from '../FileActionButtons';
import { Input, NewProjectButton, SaveIndicator, Wrapper } from './styles';

const FileActions = () => {
  const {
    activeProject,
    saveStatus,
    ui: { setCreateFileModal },
    updateActiveProjectTitle,
  } = useLocalStore();
  const [titleInput, setTitleInput] = useState('');

  useEffect(() => {
    setTitleInput(activeProject?.title || '');
  }, [activeProject?.title]);

  const handleBlur = () => {
    updateActiveProjectTitle(titleInput, saveStatus);
    trackProjectTitleChanged({
      title: titleInput,
    });
    blurAll();
  };

  return (
    <Wrapper>
      {saveStatus !== SaveStatus.no_file ? (
        <>
          <Input
            value={titleInput}
            placeholder="Untitled"
            onChange={(e) => setTitleInput(e.target.value)}
            onBlur={handleBlur}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleBlur();
            }}
          />
          <SaveIndicator>{saveStatus}</SaveIndicator>
          <FileActionButtons projectTitle={titleInput} />
        </>
      ) : (
        <>
          <NewProjectButton
            style={{ marginLeft: 72 }}
            onClick={() => setCreateFileModal(true)}
          >
            + New Project
          </NewProjectButton>
        </>
      )}
    </Wrapper>
  );
};

export default observer(FileActions);

export enum ToolEnum {
  move = 'Move',
  pen = 'Draw Walls',
  erase = 'Erase Walls',
  wall = 'Draw Straight Walls',
  room = 'Draw Rooms',
  measure = 'Tape Measure',
  text = 'Text',
}

export type Cell = {
  row: number;
  column: number;
};

export type Point = {
  x: number;
  y: number;
};

export type CellPosition = {
  row: number;
  column: number;
  topLeft: paper.Point;
  topRight: paper.Point;
  bottomLeft: paper.Point;
  bottomRight: paper.Point;
  center: paper.Point;
};

export type Edge = {
  v: string;
  w: string;
};

export type NodeValue = Cell;

export type CellInfo = {
  row: number;
  column: number;
  edge?: Edge;
  node?: { id: string; value: NodeValue };
};

export enum ObjectType {
  door = 'Doors',
  window = 'Windows',
  appliance = 'Appliances',
  cabinet = 'Kitchen Cabinets / Countertops',
  kitchenSink = 'Kitchen Sinks',
  vanity = 'Vanities',
  bathroomSink = 'Bathroom Sinks',
  toilet = 'Toilets',
  shower = 'Showers',
  bathtub = 'Bathtubs',
  bed = 'Beds',
  utility = 'Utilities',
  stairs = 'Stairs',
  table = 'Tables',
  sofa = 'Sofas',
  chair = 'Chairs',
  coffeeTable = 'Coffee Tables',
  desk = 'Desks',
  electronics = 'Electronics',
  rug = 'Rugs',
  shape = 'Shapes',
  exercise = 'Exercise',
  plant = 'Plants',
}

export const objectZStack = [
  // --- TOP ---
  //
  ObjectType.door,
  ObjectType.shape,
  ObjectType.window,
  ObjectType.shower,
  ObjectType.stairs,
  // sinks & appliances
  ObjectType.kitchenSink,
  ObjectType.bathroomSink,
  ObjectType.appliance,
  ObjectType.utility,
  // cabinets/vanities
  ObjectType.cabinet,
  ObjectType.vanity,
  //
  ObjectType.toilet,
  ObjectType.bathtub,
  // furniture
  ObjectType.plant,
  ObjectType.electronics,
  ObjectType.table,
  ObjectType.desk,
  ObjectType.coffeeTable,
  ObjectType.chair,
  ObjectType.bed,
  ObjectType.sofa,
  ObjectType.exercise,
  ObjectType.rug,
  //
  // --- BOTTOM ---
];

export enum ObjectSnap {
  none = 'none',
  halfGrid = 'half_grid',
  grid = 'grid',
}

export type ObjectSize = {
  label: string;
  width: number;
  /**height=0 will be replaced by the current wall thickness at time of creation */
  height: number;
};

export type ObjectData = {
  // general
  type: ObjectType;
  key: string;
  title: string;
  variant?: string;
  description?: string;
  wallOpening?: boolean;
  // snapping parameters
  snapX: ObjectSnap;
  snapY: ObjectSnap;
  snapRotation: number;
  // resize options
  preserveAspectRatio?: boolean;
  lockHeight?: boolean;
  // defaults
  sizes: {
    us: ObjectSize[];
    metric: ObjectSize[];
  };
};

export interface ObjectMap {
  [id: string]: ObjectData;
}

// order determines layer stack
export enum Layers {
  // bottom
  root = 'ROOT',
  sheet = 'SHEET',
  grid = 'GRID',
  walls = 'WALLS',
  ghostWalls = 'GHOST_WALLS',
  selectedWalls = 'SELECTED_WALLS',
  objects = 'OBJECTS',
  activeDrawing = 'ACTIVE_DRAWING',
  selectionBox = 'SELECTION_BOX',
  hoveredCell = 'HOVERED_CELL',
  // top
}

import { types, Instance } from 'mobx-state-tree';
import Theme from 'state/models/Theme';

const UI = types
  .model({
    leftPanelOpen: types.boolean,
    objectPanelOpen: types.boolean,
    objectPanelWidth: types.number,
    newFileModalOpen: types.boolean,
    projectLimitModalOpen: types.boolean,
    welcomeModalOpen: types.boolean,
    resetPasswordModalOpen: types.boolean,
    saveOnAuthComplete: types.maybe(types.boolean),
    loginModalOpen: types.boolean,
    exportPanelOpen: types.boolean,
    theme: Theme,
  })
  .actions((self) => ({
    setLeftPanel(value: boolean) {
      self.leftPanelOpen = value;
    },
    setObjectPanelOpen(value: boolean) {
      self.objectPanelOpen = value;
      self.exportPanelOpen = false;
    },
    setObjectPanelWidth(value: number) {
      self.objectPanelWidth = value;
    },
    setCreateFileModal(value: boolean) {
      self.newFileModalOpen = value;
    },
    setWelcomeModal(value: boolean) {
      self.welcomeModalOpen = value;
    },
    setProjectLimitModal(value: boolean) {
      self.projectLimitModalOpen = value;
    },
    setResetPasswordModal(value: boolean) {
      self.resetPasswordModalOpen = value;
    },
    setSaveOnAuthComplete(value: boolean) {
      self.saveOnAuthComplete = value;
    },
    setLoginModal(value: boolean) {
      self.loginModalOpen = value;
    },
    setExportPanel(value: boolean) {
      self.exportPanelOpen = value;
      self.objectPanelOpen = false;
    },
  }));

export type UIInstance = Instance<typeof UI>;

export default UI;

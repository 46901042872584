import styled from 'styled-components';

type ToolbarWrapperProps = {
  scale: number;
  hide?: boolean;
};

export const ToolbarWrapper = styled.div.attrs(
  (props: ToolbarWrapperProps) => ({
    style: {
      transform: `scale(${props.scale})`,
      top: `-${props.scale * 68}px`,
      left: `-${props.scale * 140}px`,
      display: props.hide ? 'none' : undefined,
    },
  })
)<ToolbarWrapperProps>`
  position: absolute;
  cursor: default;
  background: #fff;
  height: 32px;
  font-size: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  transform-origin: 0 0;
`;

export const ToolbarButton = styled.button<{ isActive?: boolean }>`
  border: none;
  display: flex;
  align-items: center;
  background: transparent;
  border-radius: 4px;
  cursor: pointer;
  min-width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;

  &:hover {
    background: #f4f4f4;
  }

  ${({ isActive }) => isActive && 'background: #eaeaea !important'};
`;

export const Divider = styled.div`
  height: 24px;
  margin: auto 10px auto 10px;
  border-left: 1px solid #d5d5d5;
`;

export const FontSizeDisplay = styled.div`
  color: #000;
  font-size: 13px;
  height: 28px;
  width: 54px;
  border: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 6px;
  border-radius: 2px;

  &:hover {
    border: 1px solid #d5d5d5;
  }
`;

export const DropdownButton = styled.button`
  height: 100%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e6e6e6;
  border-radius: 0;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;

  &:hover {
    background: #dadada;
  }
`;

export const PopupMenu = styled.div`
  position: absolute;
  background: #303030;
  color: #fff;
  top: -70px;
  left: 8px;
  width: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 0 6px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  padding: 8px 0;
`;

export const MenuItem = styled.div<{ isActive?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  font-size: 14px;

  &:hover {
    background: #505050;
  }

  ${({ isActive }) => isActive && 'background: #008cea !important'};
`;

import styled from 'styled-components';

type TextItemWrapperProps = {
  top: number;
  left: number;
  fontSize: number;
  color: string;
  scale: number;
  isFocused?: boolean;
  isTextTool?: boolean;
  noHover?: boolean;
};

export const TextItemWrapper = styled.div.attrs(
  (props: TextItemWrapperProps) => ({
    style: {
      top: `${props.top}px`,
      left: `${props.left}px`,
      transform: `scale(${props.scale})`,
    },
  })
)<TextItemWrapperProps>`
  position: absolute;
  transform-origin: 0 0;

  font-size: ${({ fontSize }) => `${fontSize}px`};
  color: ${({ color }) => color};

  border: 2px solid transparent;
  border-radius: 2px;
  min-width: 20px;

  ${({ noHover, isTextTool, isFocused }) =>
    !noHover &&
    `
		&:hover {
			${!isFocused && 'border-color: #24a7ff;'}
			${isTextTool ? 'cursor: text;' : 'cursor: default;'}
		}
	`}

  z-index: 0;

  ${({ isFocused }) =>
    isFocused &&
    `cursor: text; 
			z-index: 1;
		`};

  .editor-input {
    white-space: nowrap !important;
    outline-color: #24a7ff;
    border-radius: 2px;

    ${({ isFocused }) =>
      isFocused &&
      `
			border: 4px solid  #24a7ff;
			outline: none !important;
			transform: translate(-4px, -4px);
			`}
  }

  p {
    margin: 0;
  }

  ul,
  ol {
    margin: 0 0 0 24px;
  }

  li {
    margin: 0;
  }
`;

export const TextItemInnerWrapper = styled.div`
  position: relative;
`;

export const TextItemDragOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const BlinkingCursor = styled.div`
  @keyframes "blink" {
    from,
    to {
      color: transparent;
    }
    50% {
      color: black;
    }
  }

  font-weight: 100;
  font-size: 30px;
  color: #2e3d48;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
`;

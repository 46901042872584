import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  margin: 0 16px;
  display: flex;
  align-items: center;
`;

export const ButtonGhost = styled.button`
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

export const SignInButton = styled.button`
  background: rgb(0, 134, 234);
  background: transparent;
  color: #e0e0e0;
  font-weight: bold;
  width: 72px;
  padding: 8px;
  border-radius: 6px;
  border: none;
  border: 1px solid #c0c0c0;
  cursor: pointer;
`;

export const UserDropdownWrapper = styled.div`
  position: absolute;
  z-index: 9999;
  top: 48px;
  right: 7px;
  background: #fff;
  box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.2);
  width: 220px;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;

  ::before {
    content: '';
    position: absolute;
    top: -8px;
    right: 0;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 8px solid white;
  }
`;

export const UserEmail = styled.h1`
  margin-top: 16px;
  font-size: 14px;
`;

export const Divider = styled.div`
  margin: 16px 0;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;

export const Button = styled.button`
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  padding: 8px 16px;
  margin: 8px 0;
`;

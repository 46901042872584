import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const CoffeeTables = <ObjectMap>{
  COFFEE_TABLE_RECTANGLE: {
    key: 'COFFEE_TABLE_RECTANGLE',
    title: 'Coffee Table',
    variant: 'rectangle',
    type: ObjectType.coffeeTable,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: 'w: 36", d: 24"', width: 36, height: 24 },
        { label: 'w: 42", d: 24"', width: 42, height: 24 },
        { label: 'w: 48", d: 24"', width: 48, height: 24 },
        { label: 'w: 54", d: 24"', width: 54, height: 24 },
      ],
      metric: [
        { label: 'w: 914mm, d: 610mm', width: 914, height: 610 },
        { label: 'w: 1067mm, d: 610mm', width: 1067, height: 610 },
        { label: 'w: 1219mm, d: 610mm', width: 1219, height: 610 },
        { label: 'w: 1372mm, d: 610mm', width: 1372, height: 610 },
      ],
    },
  },
  COFFEE_TABLE_OVAL: {
    key: 'COFFEE_TABLE_OVAL',
    title: 'Coffee Table',
    variant: 'oval',
    type: ObjectType.coffeeTable,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: 'w: 36", d: 24"', width: 36, height: 24 },
        { label: 'w: 42", d: 24"', width: 42, height: 24 },
        { label: 'w: 48", d: 24"', width: 48, height: 24 },
        { label: 'w: 54", d: 24"', width: 54, height: 24 },
      ],
      metric: [
        { label: 'w: 914mm, d: 610mm', width: 914, height: 610 },
        { label: 'w: 1067mm, d: 610mm', width: 1067, height: 610 },
        { label: 'w: 1219mm, d: 610mm', width: 1219, height: 610 },
        { label: 'w: 1372mm, d: 610mm', width: 1372, height: 610 },
      ],
    },
  },
};

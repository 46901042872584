import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

const CHAIR_PADDING = 20 * 2;
const CHAIR_PADDING_METRIC = 508 * 2;

export const Tables = <ObjectMap>{
  TABLE_4_SEAT: {
    key: 'TABLE_4_SEAT',
    title: 'Dining Table',
    variant: '4 seats',
    type: ObjectType.table,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        {
          label: 'w: 42", h: 32"',
          width: 42 + CHAIR_PADDING,
          height: 32 + CHAIR_PADDING,
        },
      ],
      metric: [
        {
          label: 'w: 1066mm, h: 813mm',
          width: 1066 + CHAIR_PADDING_METRIC,
          height: 813 + CHAIR_PADDING_METRIC,
        },
      ],
    },
  },
  TABLE_ROUND_4_SEAT: {
    key: 'TABLE_ROUND_4_SEAT',
    title: 'Round Table',
    variant: '4 seats',
    type: ObjectType.table,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        {
          label: 'd: 36"',
          width: 36 + CHAIR_PADDING,
          height: 36 + CHAIR_PADDING,
        },
        {
          label: 'd: 40"',
          width: 40 + CHAIR_PADDING,
          height: 40 + CHAIR_PADDING,
        },
      ],
      metric: [
        {
          label: 'd: 914mm',
          width: 914 + CHAIR_PADDING_METRIC,
          height: 914 + CHAIR_PADDING_METRIC,
        },
        {
          label: 'd: 1016mm',
          width: 1016 + CHAIR_PADDING_METRIC,
          height: 1016 + CHAIR_PADDING_METRIC,
        },
      ],
    },
  },
  TABLE_6_SEAT: {
    key: 'TABLE_6_SEAT',
    title: 'Dining Table',
    variant: '6 seats',
    type: ObjectType.table,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        {
          label: 'w: 60", h: 32"',
          width: 60 + CHAIR_PADDING,
          height: 32 + CHAIR_PADDING,
        },
        {
          label: 'w: 72", h: 36"',
          width: 72 + CHAIR_PADDING,
          height: 36 + CHAIR_PADDING,
        },
      ],
      metric: [
        {
          label: 'w: 1524mm, h: 813mm',
          width: 1524 + CHAIR_PADDING_METRIC,
          height: 813 + CHAIR_PADDING_METRIC,
        },
        {
          label: 'w: 1829mm", h: 914mm',
          width: 1829 + CHAIR_PADDING_METRIC,
          height: 914 + CHAIR_PADDING_METRIC,
        },
      ],
    },
  },
  TABLE_ROUND_6_SEAT: {
    key: 'TABLE_ROUND_6_SEAT',
    title: 'Round Table',
    variant: '6 seats',
    type: ObjectType.table,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        {
          label: 'd: 54"',
          width: 54 + CHAIR_PADDING,
          height: 54 + CHAIR_PADDING,
        },
      ],
      metric: [
        {
          label: 'd: 1372mm',
          width: 1372 + CHAIR_PADDING_METRIC,
          height: 1372 + CHAIR_PADDING_METRIC,
        },
      ],
    },
  },
  TABLE_8_SEAT: {
    key: 'TABLE_8_SEAT',
    title: 'Dining Table',
    variant: '8 seats',
    type: ObjectType.table,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        {
          label: 'w: 96", h: 42"',
          width: 96 + CHAIR_PADDING,
          height: 42 + CHAIR_PADDING,
        },
      ],
      metric: [
        {
          label: 'w: 2438mm, h: 1067mm',
          width: 2438 + CHAIR_PADDING_METRIC,
          height: 1067 + CHAIR_PADDING_METRIC,
        },
      ],
    },
  },
};

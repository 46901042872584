import { observer } from 'mobx-react-lite';
import { trackGridToggled } from 'analytics/editor';
import GridOffIcon from 'features/editor/icons/GridOffIcon';
import GridOnIcon from 'features/editor/icons/GridOnIcon';
import { useLocalStore } from 'state';
import Tooltip from 'ui/components/Tooltip';
import { Wrapper, ToggleButton } from './styles';

const ZoomControls = () => {
  const { activeProject, view, ui } = useLocalStore();
  return (
    <>
      {activeProject ? (
        <Wrapper shiftX={ui.objectPanelOpen ? ui.objectPanelWidth : 0}>
          <Tooltip
            content={view.showGrid ? 'Grid on' : 'Grid off'}
            direction="top"
          >
            <ToggleButton
              onClick={() => {
                const newValue = !view.showGrid;
                view.setShowGrid(newValue);
                trackGridToggled({
                  showGrid: newValue,
                });
              }}
            >
              {view.showGrid ? (
                <GridOnIcon width={18} height={18} color="#666" />
              ) : (
                <GridOffIcon width={18} height={18} color="#666" />
              )}
            </ToggleButton>
          </Tooltip>
        </Wrapper>
      ) : null}
    </>
  );
};

export default observer(ZoomControls);

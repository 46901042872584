import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const KitchenSinks = <ObjectMap>{
  //
  // Kitchen Sinks
  //
  KITCHEN_SINK_SINGLE: {
    key: 'KITCHEN_SINK_SINGLE',
    title: 'Kitchen Sink',
    variant: 'single bowl',
    type: ObjectType.kitchenSink,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 20", d: 19"', width: 20, height: 19 },
        { label: 'w: 23", d: 19"', width: 23, height: 19 },
        { label: 'w: 20", d: 22"', width: 20, height: 22 },
        { label: 'w: 23", d: 22"', width: 23, height: 22 },
        { label: 'w: 29", d: 22"', width: 29, height: 22 },
        { label: 'w: 35", d: 22"', width: 35, height: 22 },
      ],
      metric: [
        { label: 'w: 508mm, d: 483mm', width: 508, height: 483 },
        { label: 'w: 584mm, d: 483mm', width: 584, height: 483 },
        { label: 'w: 508mm, d: 559mm', width: 508, height: 559 },
        { label: 'w: 584mm, d: 559mm', width: 584, height: 559 },
        { label: 'w: 229mm, d: 559mm', width: 229, height: 559 },
        { label: 'w: 889mm, d: 559mm', width: 889, height: 559 },
      ],
    },
  },
  KITCHEN_SINK_DOUBLE: {
    key: 'KITCHEN_SINK_DOUBLE',
    title: 'Kitchen Sink',
    variant: 'double bowl',
    type: ObjectType.kitchenSink,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 35", d: 20"', width: 35, height: 20 },
        { label: 'w: 35", d: 22"', width: 35, height: 22 },
      ],
      metric: [
        { label: 'w: 889mm, d: 508mm', width: 889, height: 508 },
        { label: 'w: 889mm, d: 559mm', width: 889, height: 559 },
      ],
    },
  },
};

import { createContext, useContext } from 'react';
import RootInstance, { RootInstanceType } from './models/Root';

const RootStoreContext = createContext<RootInstanceType>(RootInstance);

/**
 * Provides context to use workspace store in a react component
 */
export function useLocalStore() {
  const store = useContext(RootStoreContext);

  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}

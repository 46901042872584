import { useEffect, useRef } from 'react';
import Paper from 'paper';
import { observer } from 'mobx-react-lite';
import {
  handleWheelEvent,
  handleKeydownEvent,
  handleKeyupEvent,
  handleMousemoveEvent,
  handleMouseupEvent,
  handleMousedownEvent,
  handleRightClickEvent,
} from 'features/editor/utils/EventListeners';
import { subscribeToMouseEvents } from 'features/editor/utils/Paper/MouseEvent';
import { StyledViewContainer } from './styles';
import Dimensions from '../Dimensions';
import RotationHandles from '../RotationHandles';
import ObjectContextMenu from '../ObjectContextMenu';
import ObjectDimensionPopover from '../ObjectDimensionPopover';
import { useLocalStore } from 'state';
import { Layers } from 'features/editor/types';
import { initializeLayerStack } from 'features/editor/utils/Paper/Layers';
import TextItems from 'features/editor/components/TextItems';

const ViewContainer = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const {
    view: { panMode, cursor },
    cursor: { mouseDown },
  } = useLocalStore();

  const getCursor = () => {
    if (panMode) {
      if (mouseDown) return 'grabbing';
      return 'grab';
    }
    return cursor;
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      Paper.setup(canvas);
      Paper.project.activeLayer.name = Layers.root;
      initializeLayerStack();
      subscribeToMouseEvents();
      canvas.addEventListener('wheel', handleWheelEvent);
      canvas.addEventListener('contextmenu', handleRightClickEvent);
    }
    document.addEventListener('keydown', handleKeydownEvent);
    document.addEventListener('keyup', handleKeyupEvent);
    document.addEventListener('mousemove', handleMousemoveEvent);
    document.addEventListener('mouseup', handleMouseupEvent);
    document.addEventListener('mousedown', handleMousedownEvent);
    document.addEventListener('contextmenu', (e) => e.preventDefault());

    return () => {
      document.removeEventListener('keydown', handleKeydownEvent);
      document.removeEventListener('keyup', handleKeyupEvent);
      document.removeEventListener('mousemove', handleMousemoveEvent);
      document.removeEventListener('mouseup', handleMouseupEvent);
      document.removeEventListener('mousedown', handleMousedownEvent);
      document.removeEventListener('contextmenu', (e) => e.preventDefault());
      if (canvas) {
        canvas.removeEventListener('wheel', handleWheelEvent);
        canvas.removeEventListener('contextmenu', handleRightClickEvent);
      }
    };
  }, []);

  return (
    <StyledViewContainer cursor={getCursor()}>
      <TextItems />
      <Dimensions />
      <RotationHandles />
      <ObjectContextMenu />
      <ObjectDimensionPopover />
      <canvas ref={canvasRef} id="canvas" data-paper-resize="true" />
    </StyledViewContainer>
  );
};

export default observer(ViewContainer);

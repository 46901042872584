/* eslint-disable @typescript-eslint/no-explicit-any */
import { Path, Rectangle, CompoundPath, Color, Shape } from 'paper';
import RootStore from 'state/models/Root';
import { WallsSnapshotOut } from 'state/models/Walls';
import { Cell, Layers } from 'features/editor/types';
import {
  cellPositionFromPoint,
  getCellPosition,
} from 'features/editor/utils/Grid';
import { createOrActivateLayer } from 'features/editor/utils/Paper/Layers';
import {
  readGraphFromString,
  writeGraphToString,
} from 'features/editor/utils/Graphlib/Serialize';
import {
  addCellToGraph,
  getCellInfo,
  removeCellFromGraph,
} from 'features/editor/utils/Graphlib/Mutate';
import { SelectedWallsSnapshotOut } from 'state/models/SelectedWalls';

export const getWallCellsContainedByRectangle = (
  rect: paper.Shape.Rectangle,
  graph: any
) => {
  const result = [] as Cell[];
  const paths = getPathsFromWallGraph(graph);
  const cellSizePx = RootStore.file.sheet?.cellSizePixels || 10;
  const initialCell = cellPositionFromPoint(rect.bounds.topLeft);
  const initialRow = initialCell?.row || 0;
  const initialCol = initialCell?.column || 0;
  for (let row = 0; row < rect.bounds.height / cellSizePx; row++) {
    for (let col = 0; col < rect.bounds.width / cellSizePx; col++) {
      const curCell = {
        row: initialRow + row,
        column: initialCol + col,
      };
      const cellInfo = getCellInfo(curCell, graph, paths);
      if (cellInfo.edge || cellInfo.node) {
        result.push(curCell);
      }
    }
  }
  return result;
};

export const handleSelectedWallsStateChange = (
  snapshot: SelectedWallsSnapshotOut
) => {
  const selectedWallsLayer = createOrActivateLayer(Layers.selectedWalls);

  if (snapshot.columnDelta || snapshot.rowDelta) {
    createOrActivateLayer(Layers.ghostWalls, {
      removeChildren: true,
    });

    snapshot.cells?.forEach((cell) => {
      const cp = getCellPosition(cell);
      if (cp) {
        const r = new Shape.Rectangle(cp?.topLeft, cp?.bottomRight);
        r.fillColor = new Color('#ffffffdd');
      }
    });
  }

  selectedWallsLayer.activate();
  selectedWallsLayer.removeChildren();
  snapshot.cells?.forEach((cell) => {
    const c = {
      row: cell.row + snapshot.rowDelta,
      column: cell.column + snapshot.columnDelta,
    };
    const cp = getCellPosition(c);
    if (cp) {
      const r = new Shape.Rectangle(cp?.topLeft, cp?.bottomRight);
      r.fillColor = new Color(RootStore.ui.theme.wallColor_uncommited);
    }
  });
};

export const applySelectedWallTranslations = (
  wallJson: string,
  selectedWallsSnapshot: SelectedWallsSnapshotOut,
  options?: {
    forceErase?: boolean;
  }
) => {
  if (!selectedWallsSnapshot.cells) return wallJson;
  const removed = removeCellsFromWallGraph(
    selectedWallsSnapshot.cells,
    wallJson
  );
  return options?.forceErase
    ? removed
    : addCellsToWallGraph(
        selectedWallsSnapshot.cells.map((c) => ({
          row: c.row + selectedWallsSnapshot.rowDelta,
          column: c.column + selectedWallsSnapshot.columnDelta,
        })),
        removed
      );
};

/**
 * Constructs a path from two points and draws to the active layer
 *
 * @param options the start and end point from the drag event
 *
 * @returns the path that was drawn or undefined
 */
export const drawPathFromDrag = (options: {
  point: paper.Point;
  downPoint: paper.Point;
}) => {
  const pointCell = cellPositionFromPoint(options.point);
  const downPointCell = cellPositionFromPoint(options.downPoint);

  if (!pointCell || !downPointCell) return undefined;

  if (
    pointCell.row === downPointCell.row &&
    pointCell.column === downPointCell.column
  ) {
    return undefined;
  }

  if (
    pointCell.row !== downPointCell.row &&
    pointCell.column !== downPointCell.column
  ) {
    // Enforce wall must strictly vertical or horizontal
    const boundingRect = new Rectangle(pointCell.center, downPointCell.center);

    if (boundingRect.height < boundingRect.width) {
      pointCell.center.y = downPointCell.center.y;
    } else {
      pointCell.center.x = downPointCell.center.x;
    }
  }

  const path = new Path([pointCell.center, downPointCell.center]);
  path.strokeWidth = RootStore.file.sheet?.cellSizePixels || 0;
  path.strokeCap = 'square';

  return path;
};

/**
 * Constructs a path from two points and draws to the active layer
 *
 * @param options the start and end point from the drag event
 *
 * @returns the path that was drawn or undefined
 */
export const drawRoomFromDrag = (options: {
  point: paper.Point;
  downPoint: paper.Point;
}) => {
  const pointCell = cellPositionFromPoint(options.point);
  const downPointCell = cellPositionFromPoint(options.downPoint);

  if (
    !pointCell ||
    !downPointCell ||
    (pointCell.row === downPointCell.row &&
      pointCell.column === downPointCell.column)
  ) {
    return undefined;
  }

  const rect = new Path.Rectangle(pointCell.center, downPointCell.center);

  // find room dimensions

  const topLeftCell = cellPositionFromPoint(rect.bounds.topLeft);
  const bottomRightCell = cellPositionFromPoint(rect.bounds.bottomRight);

  if (!topLeftCell || !bottomRightCell) return undefined;

  const outerRect = new Path.Rectangle(
    topLeftCell.topLeft,
    bottomRightCell.bottomRight
  );
  const innerRect = new Path.Rectangle(
    topLeftCell.bottomRight,
    bottomRightCell.topLeft
  );

  const roomDimensions = {
    outside: outerRect,
    inside: innerRect,
  };

  // draw the walls
  const paths = [
    new Path([rect.bounds.topLeft, rect.bounds.topRight]),
    new Path([rect.bounds.bottomLeft, rect.bounds.bottomRight]),
    new Path([rect.bounds.topLeft, rect.bounds.bottomLeft]),
    new Path([rect.bounds.topRight, rect.bounds.bottomRight]),
  ];

  paths.forEach((p) => {
    p.strokeWidth = RootStore.file.sheet?.cellSizePixels || 0;
    p.strokeCap = 'square';
    p.strokeColor = new Color(RootStore.ui.theme.wallColor_uncommited);
  });

  return { paths, roomDimensions };
};

export const getPathsFromWallGraph = (graph: any) => {
  const paths = [] as paper.Path[];

  graph.edges().forEach((edge: any) => {
    const n1 = graph.node(edge.v);
    const n2 = graph.node(edge.w);

    if (n1 && n2) {
      const p = new Path([
        getCellPosition(n1)?.center,
        getCellPosition(n2)?.center,
      ]);

      paths.push(p);
    }
  });

  graph.nodes().forEach((nodeId: any) => {
    if (graph.nodeEdges(nodeId).length === 0) {
      const p = new Path([
        getCellPosition(graph.node(nodeId))?.center,
        getCellPosition(graph.node(nodeId))?.center,
      ]);

      paths.push(p);
    }
  });

  return paths;
};

export const addPathToWallGraph = (path: paper.Path, g: any) => {
  if (typeof g === 'string') {
    const graph = readGraphFromString(g);
    const startCell = cellPositionFromPoint(path.segments[1].point);
    const endCell = cellPositionFromPoint(path.segments[0].point);

    if (startCell && endCell) {
      const constantRow = startCell.row === endCell.row ? startCell.row : null;
      const constantColumn =
        startCell.column === endCell.column ? startCell.column : null;
      const min =
        startCell.row !== endCell.row
          ? Math.min(startCell.row, endCell.row)
          : Math.min(startCell.column, endCell.column);
      const max =
        startCell.row !== endCell.row
          ? Math.max(startCell.row, endCell.row)
          : Math.max(startCell.column, endCell.column);
      for (let i = min; i <= max; i++) {
        const paths = getPathsFromWallGraph(graph);
        if (constantRow !== null) {
          const cp = getCellPosition({ row: constantRow, column: i });
          if (cp) {
            addCellToGraph(cp, graph, paths);
          }
        } else if (constantColumn !== null) {
          const cp = getCellPosition({ row: i, column: constantColumn });
          if (cp) {
            addCellToGraph(cp, graph, paths);
          }
        }
      }
    }

    return writeGraphToString(graph);
  }

  return writeGraphToString(g);
};

export const addCellsToWallGraph = (cells: Cell[], g: any) => {
  if (typeof g === 'string') {
    let graph = readGraphFromString(g);

    cells.forEach((cell) => {
      const paths = getPathsFromWallGraph(graph);
      const cp = getCellPosition(cell);
      if (cp) {
        graph = addCellToGraph(cp, graph, paths);
      }
    });

    return writeGraphToString(graph);
  }

  return writeGraphToString(g);
};

export const removeCellsFromWallGraph = (cells: Cell[], g: any) => {
  if (typeof g === 'string') {
    let graph = readGraphFromString(g);

    cells.forEach((cell) => {
      const paths = getPathsFromWallGraph(graph);
      const cp = getCellPosition(cell);
      if (cp) {
        graph = removeCellFromGraph(cp, graph, paths);
      }
    });

    return writeGraphToString(graph);
  }

  return writeGraphToString(g);
};

export const handleWallStateChanges = (snapshot: WallsSnapshotOut) => {
  const wallLayer = createOrActivateLayer(Layers.walls, {
    removeChildren: true,
  });

  const graph = readGraphFromString(snapshot.json);

  const paths = getPathsFromWallGraph(graph);

  // re-draw the walls using a compound path
  wallLayer.activate();
  const walls = new CompoundPath({
    children: paths,
    strokeWidth: RootStore.file.sheet?.cellSizePixels,
    strokeColor: RootStore.ui.theme.wallColor,
    strokeCap: 'square',
  });

  if (snapshot.isSelected) {
    walls.selected = true;
  }

  if (!walls) {
    console.log(
      'Error: unable to construct a compound path using the current wall data'
    );
  }
};

import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Utilities = <ObjectMap>{
  //
  // Utilities
  //
  STACKED_WASH_DRY: {
    key: 'STACKED_WASH_DRY',
    title: 'Washer/Dryer',
    variant: 'stacked',
    type: ObjectType.utility,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 24", d: 27"', width: 24, height: 27 },
        { label: 'w: 24", d: 33"', width: 24, height: 33 },
      ],
      metric: [
        { label: 'w: 610mm, d: 686mm', width: 610, height: 686 },
        { label: 'w: 610mm, d: 838mm', width: 610, height: 838 },
      ],
    },
  },
  WASHER: {
    key: 'WASHER',
    title: 'Washer',
    // variant: 'stacked',
    type: ObjectType.utility,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 24", d: 25"', width: 24, height: 25 },
        { label: 'w: 27", d: 27"', width: 27, height: 27 },
        { label: 'w: 27", d: 32"', width: 27, height: 32 },
        { label: 'w: 28", d: 34"', width: 28, height: 34 },
      ],
      metric: [
        { label: 'w: 610mm, d: 635mm', width: 610, height: 635 },
        { label: 'w: 686mm, d: 686mm', width: 686, height: 686 },
        { label: 'w: 686mm, d: 813mm', width: 686, height: 813 },
        { label: 'w: 711mm, d: 864mm', width: 711, height: 864 },
      ],
    },
  },
  DRYER: {
    key: 'DRYER',
    title: 'Dryer',
    // variant: 'stacked',
    type: ObjectType.utility,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 24", d: 25"', width: 24, height: 25 },
        { label: 'w: 27", d: 27"', width: 27, height: 27 },
        { label: 'w: 27", d: 32"', width: 27, height: 32 },
        { label: 'w: 28", d: 34"', width: 28, height: 34 },
      ],
      metric: [
        { label: 'w: 610mm, d: 635mm', width: 610, height: 635 },
        { label: 'w: 686mm, d: 686mm', width: 686, height: 686 },
        { label: 'w: 686mm, d: 813mm', width: 686, height: 813 },
        { label: 'w: 711mm, d: 864mm', width: 711, height: 864 },
      ],
    },
  },
};

import styled from 'styled-components';

interface WrapperProps {
  top: number;
  left: number;
  color: string;
}

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  z-index: 999999;
  background-color: ${({ color }) => color};
  padding: 4px 6px;
  border-radius: 2px;

  font-size: 16px;
  font-weight: 600;
  color: #fff;
`;

import { ToolEnum } from 'features/editor/types';
import { cleanupToolSideEffects } from 'features/editor/utils/Tools/CleanupToolSideEffects';
import { resetViewOnToolChange } from 'features/editor/utils/Tools/ResetViewOnToolChange';
import { setCursorOnToolChange } from 'helpers/setCursorOnToolChange';
import { types, Instance } from 'mobx-state-tree';

const Toolbar = types
  .model({
    current: types.enumeration<ToolEnum>('ToolEnum', Object.values(ToolEnum)),
  })
  .actions((self) => ({
    setCurrentTool(value: ToolEnum) {
      self.current = value;
      cleanupToolSideEffects();
      resetViewOnToolChange(value);
      setCursorOnToolChange(value);
    },
  }));

export type ToolbarInstance = Instance<typeof Toolbar>;

export default Toolbar;

import { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { FREE_TIER_PROJECT_LIMIT, PPI, PPMM } from 'features/editor/config';
import { useLocalStore } from 'state';
import { SheetSnapshotIn } from 'state/models/Sheet';
import {
  StyledLeftModalSection,
  StyledPresetGridContainer,
  StyledModalBackdrop,
  StyledModalContainer,
  StyledModalRow,
  StyledRightModalSection,
  StyledNewProjectModalHeader,
  StyledNewProjectTitleInput,
  StyledNewProjectModalLabel,
  StyledNewProjectInput,
  StyledNewProjectCreateButton,
  StyledNewProjectButtonContainer,
  StyledNewProjectPreset,
  StyledNewProjectPresetName,
  StyledNewProjectPresetDimensions,
  StyledNewProjectPresetScale,
  StyledPresetTabContainer,
  StyledPresetTabButton,
  StyledNewProjectCancelButton,
  customSelectStyles,
  selectTheme,
  NewProjectPresetArea,
  UsableArea,
} from './styles';
import { SaveStatus } from 'state/types';
import { fitToScreen } from 'features/editor/utils/Paper/View';
import { trackCreateNewLocalProject } from 'analytics/project';
import { useAuth } from 'contexts/AuthContext';
import { ToolEnum } from 'features/editor/types';
import { getAreaLabel } from 'features/editor/utils/Unit';
import {
  metricPresets,
  Preset,
  selectImperialScaleOptions,
  selectImperialWallThicknessOptions,
  selectMetricScaleOptions,
  selectMetricWallThicknessOptions,
  selectUnitOptions,
  usPresets,
} from './config';

const NewFileModal = () => {
  const { userTier } = useAuth();
  const {
    loadSheet,
    toolbar,
    loadActiveProject,
    userProjects,
    setSaveStatus,
    resetUndoable,
    clearHistory,
    overridedObjects,
    overridedTextItems,
    ui: {
      newFileModalOpen: isOpen,
      setCreateFileModal,
      setLeftPanel,
      setProjectLimitModal,
    },
  } = useLocalStore();
  const [currentPreset, setCurrentPreset] = useState(0);
  const [scaleOptions, setScaleOptions] = useState(selectImperialScaleOptions);
  const [wallThicknessOptions, setWallThicknessOptions] = useState(
    selectImperialWallThicknessOptions
  );
  const [titleInput, setTitleInput] = useState('');
  const [widthInput, setWidthInput] = useState(0);
  const [heightInput, setHeightInput] = useState(0);
  const [unitInput, setUnitInput] = useState(selectUnitOptions[0]);
  const [scaleInput, setScaleInput] = useState(selectImperialScaleOptions[2]);
  const [usableArea, setUsableArea] = useState('0 sqft');
  const [wallThicknessInput, setWallThicknessInput] = useState(
    selectImperialWallThicknessOptions[1]
  );

  const [tab, setTab] = useState('US');
  const [presets, setPresets] = useState<Preset[]>([]);

  const setInputsFromPreset = useCallback(
    (p: Preset) => {
      const { width, height, scale, unit, wallThickness, id } = p;
      // set current preset
      setCurrentPreset(id);
      // set width
      setWidthInput(width);
      setHeightInput(height);
      const presetScale =
        tab === 'US'
          ? selectImperialScaleOptions.find((s) => s.value === scale) ||
            selectImperialScaleOptions[0]
          : selectMetricScaleOptions.find((s) => s.value === scale) ||
            selectMetricScaleOptions[0];
      setScaleInput(presetScale);
      const presetWallThickness =
        tab === 'US'
          ? selectImperialWallThicknessOptions.find(
              (s) => s.value === wallThickness
            ) || selectImperialWallThicknessOptions[0]
          : selectMetricWallThicknessOptions.find(
              (s) => s.value === wallThickness
            ) || selectMetricWallThicknessOptions[0];
      setWallThicknessInput(presetWallThickness);
      const presetUnit =
        selectUnitOptions.find((s) => s.value === unit) || selectUnitOptions[0];
      setUnitInput(presetUnit);
    },
    [tab]
  );

  const getAreaFromPreset = (p: Preset) => {
    const heightPixels = p.height * (p.unit === 'IN' ? PPI : PPMM);
    const widthPixels = p.width * (p.unit === 'IN' ? PPI : PPMM);
    const PPU =
      p.unit === 'IN' ? PPI * (p.scale / 12) : PPMM * (p.scale / 1000);
    const cellSizePixels = p.wallThickness * PPU;
    const gridRows = Math.floor(heightPixels / cellSizePixels) - 3;
    const gridColumns = Math.floor(widthPixels / cellSizePixels) - 3;
    return getAreaLabel(
      ((gridRows * cellSizePixels) / PPU) *
        ((gridColumns * cellSizePixels) / PPU),
      p.unit !== 'IN',
      { isInteger: true }
    );
  };

  const userCanSave = () => {
    if (userTier === 1) return true;
    return (userProjects?.length || 0) < FREE_TIER_PROJECT_LIMIT;
  };

  const onCreate = () => {
    // file state
    const activeFile = {
      id: null,
      title: titleInput,
    };
    // sheet state
    const heightPixels = heightInput * (unitInput.value === 'IN' ? PPI : PPMM);
    const widthPixels = widthInput * (unitInput.value === 'IN' ? PPI : PPMM);
    const PPU =
      unitInput.value === 'IN'
        ? PPI * (scaleInput.value / 12)
        : PPMM * (scaleInput.value / 1000);
    const cellSizePixels = wallThicknessInput.value * PPU;
    const gridRows = Math.floor(heightPixels / cellSizePixels) - 3;
    const gridColumns = Math.floor(widthPixels / cellSizePixels) - 3;
    const sheet: SheetSnapshotIn = {
      metric: unitInput.value === 'MM',
      printWidth: widthInput,
      printHeight: heightInput,
      heightPixels,
      widthPixels,
      cellSizePixels,
      gridRows,
      gridColumns,
      gridHeightPixels: gridRows * cellSizePixels,
      gridWidthPixels: gridColumns * cellSizePixels,
      PPU,
    };

    overridedTextItems.clear();
    overridedObjects.clear();
    setLeftPanel(false);
    loadSheet(sheet);
    loadActiveProject(activeFile);
    clearHistory();
    resetUndoable();
    setSaveStatus(SaveStatus.unsaved);
    setCreateFileModal(false);
    fitToScreen();
    trackCreateNewLocalProject({
      title: titleInput,
      metric: sheet.metric,
      printWidth: sheet.printWidth,
      printHeight: sheet.printHeight,
    });
    toast(
      `Created project '${titleInput.length > 0 ? titleInput : 'Untitled'}'`
    );
    if (!userCanSave()) setProjectLimitModal(true);
  };

  useEffect(() => {
    const heightPixels = heightInput * (unitInput.value === 'IN' ? PPI : PPMM);
    const widthPixels = widthInput * (unitInput.value === 'IN' ? PPI : PPMM);
    const PPU =
      unitInput.value === 'IN'
        ? PPI * (scaleInput.value / 12)
        : PPMM * (scaleInput.value / 1000);
    const cellSizePixels = wallThicknessInput.value * PPU;
    const gridRows = Math.floor(heightPixels / cellSizePixels) - 3;
    const gridColumns = Math.floor(widthPixels / cellSizePixels) - 3;
    const label = getAreaLabel(
      ((gridRows * cellSizePixels) / PPU) *
        ((gridColumns * cellSizePixels) / PPU),
      unitInput.value !== 'IN',
      { isInteger: true }
    );
    setUsableArea(label);
  }, [
    heightInput,
    scaleInput.value,
    unitInput.value,
    wallThicknessInput.value,
    widthInput,
  ]);

  useEffect(() => {
    if (unitInput.value === 'IN') {
      setScaleOptions(selectImperialScaleOptions);
      if (
        !selectImperialScaleOptions.some((el) => el.value === scaleInput.value)
      ) {
        setScaleInput(selectImperialScaleOptions[0]);
      }
      setWallThicknessOptions(selectImperialWallThicknessOptions);
      if (
        !selectImperialWallThicknessOptions.some(
          (el) => el.value === wallThicknessInput.value
        )
      ) {
        setWallThicknessInput(selectImperialWallThicknessOptions[1]);
      }
    } else {
      setScaleOptions(selectMetricScaleOptions);
      if (
        !selectMetricScaleOptions.some((el) => el.value === scaleInput.value)
      ) {
        setScaleInput(selectMetricScaleOptions[0]);
      }
      setWallThicknessOptions(selectMetricWallThicknessOptions);
      if (
        !selectMetricWallThicknessOptions.some(
          (el) => el.value === wallThicknessInput.value
        )
      ) {
        setWallThicknessInput(selectMetricWallThicknessOptions[1]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitInput.value]);

  useEffect(() => {
    if (tab === 'US') {
      setPresets(usPresets);
      setInputsFromPreset(usPresets[0]);
    } else {
      setPresets(metricPresets);
      setInputsFromPreset(metricPresets[0]);
    }
  }, [setInputsFromPreset, tab]);

  useEffect(() => {
    if (!isOpen) setTitleInput('');
    if (isOpen) toolbar.setCurrentTool(ToolEnum.move);
  }, [isOpen, toolbar]);

  if (!isOpen) return null;

  return (
    <StyledModalBackdrop>
      <StyledModalContainer>
        <StyledLeftModalSection>
          <StyledNewProjectModalHeader>Templates</StyledNewProjectModalHeader>
          <StyledPresetTabContainer>
            <StyledPresetTabButton
              isActive={tab === 'US'}
              onClick={() => setTab('US')}
            >
              US
            </StyledPresetTabButton>
            <StyledPresetTabButton
              isActive={tab === 'METRIC'}
              onClick={() => setTab('METRIC')}
            >
              METRIC
            </StyledPresetTabButton>
          </StyledPresetTabContainer>
          <StyledPresetGridContainer rows={3}>
            {presets.map((preset) => (
              <StyledNewProjectPreset
                key={preset.id}
                onClick={() => setInputsFromPreset(preset)}
                isActive={currentPreset === preset.id}
              >
                <NewProjectPresetArea selected={currentPreset === preset.id}>
                  <span>{getAreaFromPreset(preset)}</span>
                </NewProjectPresetArea>
                <StyledNewProjectPresetName>
                  {preset.name}
                </StyledNewProjectPresetName>
                <StyledNewProjectPresetDimensions>
                  {`${preset.dimensions}`}
                </StyledNewProjectPresetDimensions>
                <StyledNewProjectPresetScale>
                  {`${preset.scaleText}`}
                </StyledNewProjectPresetScale>
              </StyledNewProjectPreset>
            ))}
          </StyledPresetGridContainer>
        </StyledLeftModalSection>
        <StyledRightModalSection>
          {/* Header */}
          <StyledModalRow rows={2} columns={1}>
            <StyledNewProjectModalHeader>
              New Project
            </StyledNewProjectModalHeader>
            <StyledNewProjectTitleInput
              placeholder="Untitled"
              value={titleInput}
              onChange={(e) => {
                e.preventDefault();
                setTitleInput(e.target.value);
              }}
            />
          </StyledModalRow>

          {/* Sheet Width */}
          <StyledModalRow rows={2} columns={2}>
            <StyledNewProjectModalLabel>Print Width</StyledNewProjectModalLabel>
            <div />
            <StyledNewProjectInput
              width={86}
              type="number"
              value={widthInput}
              onChange={(e) => {
                e.preventDefault();
                setCurrentPreset(-1);
                setWidthInput(parseFloat(e.target.value));
              }}
            />
            <Select
              value={unitInput}
              onChange={(e) => {
                setCurrentPreset(-1);
                setUnitInput(e || { label: '', value: '' });
              }}
              options={selectUnitOptions}
              styles={customSelectStyles}
              theme={selectTheme}
            />
          </StyledModalRow>

          {/* Sheet Height */}
          <StyledModalRow rows={2} columns={2}>
            <StyledNewProjectModalLabel>
              Print Height
            </StyledNewProjectModalLabel>
            <div />
            <StyledNewProjectInput
              type="number"
              width={86}
              value={heightInput}
              onChange={(e) => {
                e.preventDefault();
                setCurrentPreset(-1);
                setHeightInput(parseFloat(e.target.value));
              }}
            />
          </StyledModalRow>

          {/* Sheet Scale */}
          <StyledModalRow rows={2} columns={1}>
            <UsableArea>{`${usableArea}`}</UsableArea>
            <StyledNewProjectModalLabel style={{ marginTop: 26 }}>
              Scale
            </StyledNewProjectModalLabel>
            <Select
              value={scaleInput}
              onChange={(e) => {
                setCurrentPreset(-1);
                setScaleInput(e || { label: '', value: 0 });
              }}
              options={scaleOptions}
              styles={customSelectStyles}
              theme={selectTheme}
            />
          </StyledModalRow>

          {/* Sheet Wall Thickness */}
          <StyledModalRow rows={2} columns={1}>
            <StyledNewProjectModalLabel>
              Wall Thickness
            </StyledNewProjectModalLabel>
            <Select
              value={wallThicknessInput}
              onChange={(e) => {
                setCurrentPreset(-1);
                setWallThicknessInput(e || { label: '', value: 0 });
              }}
              options={wallThicknessOptions}
              styles={customSelectStyles}
              theme={selectTheme}
            />
          </StyledModalRow>

          <StyledNewProjectButtonContainer>
            <StyledNewProjectCreateButton onClick={onCreate}>
              Create
            </StyledNewProjectCreateButton>
            <StyledNewProjectCancelButton
              onClick={() => setCreateFileModal(false)}
            >
              Cancel
            </StyledNewProjectCancelButton>
          </StyledNewProjectButtonContainer>
        </StyledRightModalSection>
      </StyledModalContainer>
    </StyledModalBackdrop>
  );
};

export default observer(NewFileModal);

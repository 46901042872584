import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import MinusIcon from 'features/editor/icons/MinusIcon';
import PlusIcon from 'features/editor/icons/PlusIcon';
import { useLocalStore } from 'state';
import ZoomMenu from '../ZoomMenu';
import { Wrapper, ZoomButton } from './styles';
import { zoomBy } from 'features/editor/utils/Paper/View';

const ZoomControls = () => {
  const { view, activeProject, ui } = useLocalStore();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const zoomIn = (step = 0.1) => {
    zoomBy(step);
  };

  const zoomOut = (step = 0.1) => {
    zoomBy(-step);
  };

  const getZoomLabel = () => {
    return `${
      view.zoom * 100 > 1
        ? Math.round(view.zoom * 100)
        : Math.round(view.zoom * 1000) / 10
    }%`;
  };

  return (
    <>
      {activeProject ? (
        <Wrapper shiftX={ui.objectPanelOpen ? ui.objectPanelWidth : 0}>
          <ZoomMenu isOpen={menuIsOpen} setIsOpen={setMenuIsOpen} />
          <div id="inner">
            <ZoomButton type="button" onClick={() => zoomOut()}>
              <MinusIcon width={18} height={18} color="#333" />
            </ZoomButton>
            <ZoomButton
              type="button"
              onClick={() => setMenuIsOpen(!menuIsOpen)}
            >
              {getZoomLabel()}
            </ZoomButton>
            <ZoomButton type="button" onClick={() => zoomIn()}>
              <PlusIcon width={18} height={18} color="#333" />
            </ZoomButton>
          </div>
        </Wrapper>
      ) : null}
    </>
  );
};

export default observer(ZoomControls);

import { ObjectMap } from 'features/editor/types';
import { Appliances } from './appliances';
import { BathroomSinks } from './bathroomSinks';
import { Bathtubs } from './bathtubs';
import { Beds } from './beds';
import { Cabinets } from './cabinets';
import { Chairs } from './chairs';
import { Doors } from './doors';
import { KitchenSinks } from './kitchenSinks';
import { Shapes } from './shapes';
import { Showers } from './showers';
import { Stairs } from './stairs';
import { Sofas } from './sofas';
import { Tables } from './tables';
import { Toilets } from './toilets';
import { Utilities } from './utilities';
import { Vanities } from './vanities';
import { Windows } from './windows';
import { CoffeeTables } from './coffeeTables';
import { Desks } from './desks';

export default <ObjectMap>{
  ...Doors,
  ...Windows,
  ...Appliances,
  ...KitchenSinks,
  ...Cabinets,
  ...Tables,
  ...Sofas,
  ...Chairs,
  ...Desks,
  ...Beds,
  ...CoffeeTables,
  ...Vanities,
  ...BathroomSinks,
  ...Toilets,
  ...Showers,
  ...Bathtubs,
  ...Utilities,
  ...Stairs,
  ...Shapes,
};

import { observer } from 'mobx-react-lite';
import { useLocalStore } from 'state';
import { ExportPanelWrapper, ContentWrapper } from './styles';

const ExportPanel = () => {
  const { ui, activeProject } = useLocalStore();

  if (!activeProject) return null;
  return (
    <ExportPanelWrapper isOpen={ui.exportPanelOpen} width={350}>
      <ContentWrapper>Content</ContentWrapper>
    </ExportPanelWrapper>
  );
};

export default observer(ExportPanel);

const DotIcon = ({ width = 18, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 24 24"
  >
    <circle cx="12" cy="12" r="4" fill="currentColor" />
    <circle cx="12" cy="12" r="4" fill="currentColor" />
  </svg>
);

export default DotIcon;

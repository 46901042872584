import { Color } from 'paper';
import {
  addPathToWallGraph,
  drawRoomFromDrag,
} from 'features/editor/utils/Paper/Walls';
import RootStore from 'state/models/Root';
import { initializeDrawingLayer } from 'features/editor/utils/Paper/Layers';
import { getAreaLabel, getDimensionLabel } from 'features/editor/utils/Unit';
import { paperToBrowserCoordinates } from 'features/editor/utils/Paper/View';
import { trackRoomToolCommitted } from 'analytics/editor';

/**
 * Drag event handler when the room tool is selected
 *
 * @param event
 */
export const handleRoomToolDrag = (event: paper.ToolEvent) => {
  initializeDrawingLayer();

  // Re-draw the room
  const { point, downPoint } = event;
  const room = drawRoomFromDrag({ point, downPoint });

  if (room) {
    const { roomDimensions } = room;

    roomDimensions.inside.fillColor = new Color(
      RootStore.ui.theme.roomFillColor
    );
    // roomDimensions.inside.blendMode = 'overlay';

    const PPU = RootStore.file.sheet?.PPU || 1;
    const isMetric = !!RootStore.file.sheet?.metric;

    // internal dimensions
    const internalHeight = roomDimensions.inside.bounds.height / PPU;
    const internalWidth = roomDimensions.inside.bounds.width / PPU;
    const internalArea = internalHeight * internalWidth;

    RootStore.mouseTooltip.setData(getAreaLabel(internalArea, isMetric));

    const clientPoints = {
      topLeft: paperToBrowserCoordinates(roomDimensions.inside.bounds.topLeft),
      bottomLeft: paperToBrowserCoordinates(
        roomDimensions.inside.bounds.bottomLeft
      ),
      leftCenter: paperToBrowserCoordinates(
        roomDimensions.inside.bounds.leftCenter
      ),
      topRight: paperToBrowserCoordinates(
        roomDimensions.inside.bounds.topRight
      ),
      topCenter: paperToBrowserCoordinates(
        roomDimensions.inside.bounds.topCenter
      ),
    };

    const clientWallThickness =
      clientPoints.leftCenter.x -
      paperToBrowserCoordinates(roomDimensions.outside.bounds.leftCenter).x;

    const heightLabel = getDimensionLabel(internalHeight, isMetric);
    const widthLabel = getDimensionLabel(internalWidth, isMetric);

    let offset = 24 / clientWallThickness;
    if (offset <= 0.5) offset = clientWallThickness * 0.5;
    else if (offset <= 1.5) offset = clientWallThickness;
    else if (offset <= 2.5) offset = clientWallThickness * 2;
    else offset = clientWallThickness * 3;

    RootStore.dimensions.setActiveDrawingDimensions([
      {
        id: 'HEIGHT',
        start: clientPoints.topLeft,
        center: clientPoints.leftCenter,
        end: clientPoints.bottomLeft,
        label: heightLabel,
        offset: -(clientWallThickness + offset),
      },
      {
        id: 'WIDTH',
        start: clientPoints.topLeft,
        center: clientPoints.topCenter,
        end: clientPoints.topRight,
        label: widthLabel,
        offset: -(clientWallThickness + offset),
      },
    ]);
  } else {
    RootStore.mouseTooltip.clearData();
  }
};

/**
 * Mouseup event handler when the room tool is selected
 *
 * @param event
 */
export const handleRoomToolMouseUp = (event: paper.ToolEvent) => {
  const activeDrawingLayer = initializeDrawingLayer();
  if (!RootStore.mouseTooltip.data) return activeDrawingLayer.remove();
  RootStore.mouseTooltip.clearData();
  RootStore.dimensions.clearActiveDrawingDimensions();

  const { point, downPoint } = event;
  const room = drawRoomFromDrag({ point, downPoint });

  if (!room) return activeDrawingLayer.remove();

  const { paths } = room;

  if (paths) {
    let newJSON = '';
    paths.forEach((p) => {
      if (newJSON === '')
        newJSON = addPathToWallGraph(p, RootStore.file.undoable.walls.json);
      else newJSON = addPathToWallGraph(p, newJSON);
    });

    RootStore.file.undoable.walls.setWallJSON(newJSON);
    trackRoomToolCommitted();
  }

  return activeDrawingLayer.remove();
};

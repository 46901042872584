import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Shapes = <ObjectMap>{
  SQUARE: {
    key: 'SQUARE',
    title: 'Square',
    type: ObjectType.shape,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: '24"', width: 24, height: 24 },
        { label: '12"', width: 12, height: 12 },
        { label: '36"', width: 36, height: 36 },
        { label: '48"', width: 48, height: 48 },
        { label: '60"', width: 60, height: 60 },
        { label: '72"', width: 72, height: 72 },
      ],
      metric: [
        { label: '300mm', width: 300, height: 300 },
        { label: '600mm', width: 600, height: 600 },
        { label: '900mm', width: 900, height: 900 },
        { label: '1200mm', width: 1200, height: 1200 },
        { label: '1500mm', width: 1524, height: 1500 },
        { label: '1800mm', width: 1800, height: 1800 },
      ],
    },
  },
  RECTANGLE: {
    key: 'RECTANGLE',
    title: 'Rectangle',
    type: ObjectType.shape,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: 'w: 48", h: 24"', width: 48, height: 24 },
        { label: 'w: 24", h: 12"', width: 24, height: 12 },
        { label: 'w: 48", h: 36"', width: 48, height: 36 },
        { label: 'w: 60", h: 36"', width: 60, height: 36 },
        { label: 'w: 60", h: 48"', width: 60, height: 48 },
        { label: 'w: 96", h: 72"', width: 96, height: 72 },
      ],
      metric: [
        { label: 'w: 600mm, h: 300mm', width: 600, height: 300 },
        { label: 'w: 1200mm, h: 600mm', width: 1200, height: 600 },
        { label: 'w: 1200mm, h: 900mm', width: 1200, height: 900 },
        { label: 'w: 1500mm, h: 900mm', width: 1500, height: 900 },
        { label: 'w: 1500mm, h: 1200mm', width: 1500, height: 1200 },
        { label: 'w: 2400mm, h: 1800mm', width: 2400, height: 1800 },
      ],
    },
  },
  CIRCLE: {
    key: 'CIRCLE',
    title: 'Circle',
    type: ObjectType.shape,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: 'd: 36"', width: 36, height: 36 },
        { label: 'd: 12"', width: 12, height: 12 },
        { label: 'd: 18"', width: 18, height: 18 },
        { label: 'd: 24"', width: 24, height: 24 },
        { label: 'd: 48"', width: 48, height: 48 },
        { label: 'd: 60"', width: 60, height: 60 },
        { label: 'd: 72"', width: 72, height: 72 },
      ],
      metric: [
        { label: 'd: 300mm', width: 300, height: 300 },
        { label: 'd: 450mm', width: 450, height: 450 },
        { label: 'd: 600mm', width: 600, height: 600 },
        { label: 'd: 900mm', width: 900, height: 900 },
        { label: 'd: 1200mm', width: 1200, height: 1200 },
        { label: 'd: 1500mm', width: 1500, height: 1500 },
        { label: 'd: 1800mm', width: 1800, height: 1800 },
      ],
    },
  },
  TRIANGLE: {
    key: 'TRIANGLE',
    title: 'Triangle',
    type: ObjectType.shape,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: '24"', width: 24, height: 24 },
        { label: '36"', width: 36, height: 36 },
        { label: '48"', width: 48, height: 48 },
        { label: '60"', width: 60, height: 60 },
        { label: '72"', width: 72, height: 72 },
      ],
      metric: [
        { label: '600mm', width: 600, height: 600 },
        { label: '900mm', width: 900, height: 900 },
        { label: '1200mm', width: 1200, height: 1200 },
        { label: '1500mm', width: 1500, height: 1500 },
        { label: '1800mm', width: 1800, height: 1800 },
      ],
    },
  },
};

import styled from 'styled-components';

interface WrapperProps {
  padding?: number;
  width?: number;
  height?: number;
  rotation?: number;
  flippedVertical?: boolean;
  flippedHorizontal?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  padding: ${({ padding }) => `${padding || 0}px`};
  /* width: ${({ width }) => (width ? `${width}px` : undefined)}; */
  /* height: ${({ height }) => (height ? `${height}px` : undefined)}; */

  .svg-wrapper {
    padding: 0;
    margin: 0;
    transform: ${({ rotation, flippedVertical, flippedHorizontal }) =>
      flippedVertical || flippedHorizontal || rotation
        ? `rotate(${rotation || 0}deg) scaleY(${
            flippedVertical ? -1 : 1
          }) scaleX(${flippedHorizontal ? -1 : 1})`
        : undefined};
    margin-top: ${({ rotation, width, height }) =>
      rotation === 90 || rotation === 270
        ? `${((width || 0) - (height || 0)) / 2}px`
        : 0};
    margin-left: ${({ rotation, width, height }) =>
      rotation === 90 || rotation === 270
        ? `${((height || 0) - (width || 0)) / 2}px`
        : 0};
  }
`;

export const WallOpeningWrapper = styled.div`
  display: flex;
  flex: row;
`;

interface WallOpeningProps {
  width: number;
  height: number;
  wallColor: string;
  filled?: boolean;
}

export const WallOpening = styled.div<WallOpeningProps>`
  width: ${({ width }) => `${width - 4}px`};
  height: ${({ height }) => `${height - 4}px`};
  border: 2px solid ${({ wallColor }) => wallColor};
  background: ${({ filled, wallColor }) =>
    filled ? wallColor : 'transparent'};
`;

import { SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

const Cells = types.array(
  types.model({ row: types.number, column: types.number })
);

export type CellsSnapshotIn = SnapshotIn<typeof Cells>;

const SelectedWalls = types
  .model({
    cells: types.maybeNull(Cells),
    rowDelta: types.number,
    columnDelta: types.number,
  })
  .actions((self) => ({
    setCells(data: CellsSnapshotIn) {
      self.cells = Cells.create(data);
    },
    setDelta(rowDelta: number, columnDelta: number) {
      self.rowDelta = rowDelta;
      self.columnDelta = columnDelta;
    },
    clear() {
      self.cells = null;
      self.rowDelta = 0;
      self.columnDelta = 0;
    },
  }));

export type SelectedWallsSnapshotOut = SnapshotOut<typeof SelectedWalls>;

export default SelectedWalls;

import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import LogoIcon from 'features/editor/icons/LogoIcon';
import { useLocalStore } from 'state';
import CloseIcon from 'features/editor/icons/CloseIcon';
import MoveToolIcon from 'features/editor/icons/MoveToolIcon';
import { ToolEnum } from 'features/editor/types';
import WallToolIcon from 'features/editor/icons/WallToolIcon';
import RoomToolIcon from 'features/editor/icons/RoomToolIcon';
import PenToolIcon from 'features/editor/icons/PenToolIcon';
import EraserToolIcon from 'features/editor/icons/EraserToolIcon';
import {
  StyledNavbar,
  StyledNavbarLeft,
  StyledNavbarButton,
  StyledNavbarRight,
  StyledNavbarCenter,
  ObjectPanelLabel,
} from './styles';
import { UserAvatar } from '../UserAvatar';
import FileActions from '../FileActions';
import { getLayerByName } from 'features/editor/utils/Paper/Layers';
import { trackToolbarToolClicked } from 'analytics/editor';
import TapeMeasueIcon from 'features/editor/icons/TapeMeasureIcon';
import Tooltip from 'ui/components/Tooltip';
import TextToolIcon from 'features/editor/icons/TextToolIcon';

const Navbar = () => {
  const { ui, toolbar, activeProject, view } = useLocalStore();
  const ICON_SIZE = 24;
  const ICON_COLOR = '#ffffff';

  const handleToolClick = (tool: ToolEnum) => {
    toolbar.setCurrentTool(tool);

    trackToolbarToolClicked({
      tool: tool,
    });
  };

  useEffect(() => {
    if (view.panMode) {
      getLayerByName('HOVERED_CELL')?.removeChildren();
    }
  }, [view.panMode]);

  return (
    <StyledNavbar>
      <StyledNavbarLeft>
        <Tooltip
          content={ui.leftPanelOpen ? 'Close' : 'My Projects'}
          direction="bottom"
          offsetX={28}
        >
          <StyledNavbarButton
            type="button"
            mainButton
            active={ui.leftPanelOpen}
            onClick={() => ui.setLeftPanel(!ui.leftPanelOpen)}
            style={{ cursor: 'pointer' }}
          >
            {!ui.leftPanelOpen ? (
              <LogoIcon
                width={ICON_SIZE}
                height={ICON_SIZE}
                color={ICON_COLOR}
              />
            ) : (
              <CloseIcon width={22} height={22} color="#393939" />
            )}
          </StyledNavbarButton>
        </Tooltip>

        <Tooltip content={ToolEnum.move} keyShortcut="V" direction="bottom">
          <StyledNavbarButton
            type="button"
            active={toolbar.current === ToolEnum.move}
            onClick={() => handleToolClick(ToolEnum.move)}
          >
            <MoveToolIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              color={ICON_COLOR}
            />
          </StyledNavbarButton>
        </Tooltip>

        <Tooltip content={ToolEnum.pen} keyShortcut="D" direction="bottom">
          <StyledNavbarButton
            type="button"
            active={toolbar.current === ToolEnum.pen}
            onClick={() => handleToolClick(ToolEnum.pen)}
          >
            <PenToolIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              color={ICON_COLOR}
            />
          </StyledNavbarButton>
        </Tooltip>

        <Tooltip content={ToolEnum.erase} keyShortcut="E" direction="bottom">
          <StyledNavbarButton
            type="button"
            active={toolbar.current === ToolEnum.erase}
            onClick={() => handleToolClick(ToolEnum.erase)}
          >
            <EraserToolIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              color={ICON_COLOR}
            />
          </StyledNavbarButton>
        </Tooltip>

        <Tooltip content={ToolEnum.wall} keyShortcut="W" direction="bottom">
          <StyledNavbarButton
            type="button"
            active={toolbar.current === ToolEnum.wall}
            onClick={() => handleToolClick(ToolEnum.wall)}
          >
            <WallToolIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              color={ICON_COLOR}
            />
          </StyledNavbarButton>
        </Tooltip>

        <Tooltip content={ToolEnum.room} keyShortcut="R" direction="bottom">
          <StyledNavbarButton
            type="button"
            active={toolbar.current === ToolEnum.room}
            onClick={() => handleToolClick(ToolEnum.room)}
          >
            <RoomToolIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              color={ICON_COLOR}
            />
          </StyledNavbarButton>
        </Tooltip>

        <Tooltip content={ToolEnum.measure} keyShortcut="M" direction="bottom">
          <StyledNavbarButton
            type="button"
            active={toolbar.current === ToolEnum.measure}
            onClick={() => handleToolClick(ToolEnum.measure)}
          >
            <TapeMeasueIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              color={ICON_COLOR}
            />
          </StyledNavbarButton>
        </Tooltip>

        <Tooltip content={ToolEnum.text} keyShortcut="T" direction="bottom">
          <StyledNavbarButton
            type="button"
            active={toolbar.current === ToolEnum.text}
            onClick={() => handleToolClick(ToolEnum.text)}
          >
            <TextToolIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              color={ICON_COLOR}
            />
          </StyledNavbarButton>
        </Tooltip>
      </StyledNavbarLeft>

      <StyledNavbarCenter>
        <FileActions />
      </StyledNavbarCenter>

      <StyledNavbarRight>
        <UserAvatar />
        <Tooltip
          content={ui.objectPanelOpen ? 'Close' : 'Add Objects'}
          direction="bottom"
          offsetX={-18}
        >
          <StyledNavbarButton
            type="button"
            objectButton
            active={ui.objectPanelOpen && !!activeProject}
            onClick={() =>
              !!activeProject && ui.setObjectPanelOpen(!ui.objectPanelOpen)
            }
            style={{ cursor: 'pointer' }}
          >
            {!ui.objectPanelOpen || !activeProject ? (
              <ObjectPanelLabel>Objects</ObjectPanelLabel>
            ) : (
              <CloseIcon width={22} height={22} color="#393939" />
            )}
          </StyledNavbarButton>
        </Tooltip>
      </StyledNavbarRight>
    </StyledNavbar>
  );
};

export default observer(Navbar);

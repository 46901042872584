import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Appliances = <ObjectMap>{
  //
  // Appliances
  //
  REFRIGERATOR: {
    key: 'REFRIGERATOR',
    title: 'Refrigerator',
    type: ObjectType.appliance,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 24", d: 25"', width: 24, height: 25 },
        { label: 'w: 24", d: 30"', width: 24, height: 30 },
        { label: 'w: 30", d: 25"', width: 30, height: 25 },
        { label: 'w: 30", d: 30"', width: 30, height: 30 },
        { label: 'w: 33", d: 25"', width: 33, height: 25 },
        { label: 'w: 33", d: 30"', width: 33, height: 30 },
        { label: 'w: 36", d: 25"', width: 36, height: 25 },
        { label: 'w: 36", d: 30"', width: 36, height: 30 },
      ],
      metric: [
        { label: 'w: 610mm, d: 635mm', width: 610, height: 635 },
        { label: 'w: 610mm, d: 762mm', width: 610, height: 762 },
        { label: 'w: 762mm, d: 635mm', width: 762, height: 635 },
        { label: 'w: 762mm, d: 762mm', width: 762, height: 762 },
        { label: 'w: 838mm, d: 635mm', width: 838, height: 635 },
        { label: 'w: 838mm, d: 762mm', width: 838, height: 762 },
        { label: 'w: 914mm, d: 635mm', width: 914, height: 635 },
        { label: 'w: 914mm, d: 762mm', width: 914, height: 762 },
      ],
    },
  },
  RANGE_STANDARD: {
    key: 'RANGE_STANDARD',
    title: 'Kitchen Range',
    variant: 'standard',
    type: ObjectType.appliance,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 30", d: 25"', width: 30, height: 25 },
        { label: 'w: 30", d: 30"', width: 30, height: 30 },
      ],
      metric: [
        { label: 'w: 762mm, d: 635mm', width: 762, height: 635 },
        { label: 'w: 762mm, d: 762mm', width: 762, height: 762 },
      ],
    },
  },
  RANGE_SMALL: {
    key: 'RANGE_SMALL',
    title: 'Kitchen Range',
    variant: 'compact',
    type: ObjectType.appliance,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 20", d: 25"', width: 20, height: 25 },
        { label: 'w: 24", d: 28"', width: 24, height: 28 },
        { label: 'w: 27", d: 28"', width: 27, height: 28 },
      ],
      metric: [
        { label: 'w: 508mm, d: 635mm', width: 508, height: 635 },
        { label: 'w: 610mm, d: 711mm', width: 610, height: 711 },
        { label: 'w: 686mm, d: 711mm', width: 686, height: 711 },
      ],
    },
  },

  COOKTOP_STANDARD: {
    key: 'COOKTOP_STANDARD',
    title: 'Cooktop',
    variant: 'standard',
    type: ObjectType.appliance,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 21", d: 18"', width: 21, height: 18 },
        { label: 'w: 24", d: 22"', width: 24, height: 22 },
        { label: 'w: 27", d: 22"', width: 27, height: 22 },
      ],
      metric: [
        { label: 'w: 533mm, d: 457mm', width: 533, height: 457 },
        { label: 'w: 610mm, d: 559mm', width: 610, height: 559 },
        { label: 'w: 686mm, d: 559mm', width: 686, height: 559 },
      ],
    },
  },
  COOKTOP_COMPACT: {
    key: 'COOKTOP_COMPACT',
    title: 'Cooktop',
    variant: 'compact',
    type: ObjectType.appliance,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 12", d: 20"', width: 12, height: 20 },
        { label: 'w: 15", d: 22"', width: 15, height: 22 },
      ],
      metric: [
        { label: 'w: 305mm, d: 508mm', width: 305, height: 508 },
        { label: 'w: 381mm, d: 559mm', width: 381, height: 559 },
      ],
    },
  },
  DISHWASHER: {
    key: 'DISHWASHER',
    title: 'Dishwasher',
    variant: 'standard',
    type: ObjectType.appliance,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [{ label: 'w: 24"', width: 24, height: 24 }],
      metric: [{ label: 'w: 610mm', width: 610, height: 610 }],
    },
  },
  DISHWASHER_COMPACT: {
    key: 'DISHWASHER_COMPACT',
    title: 'Dishwasher',
    variant: 'compact',
    type: ObjectType.appliance,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [{ label: 'w: 18"', width: 18, height: 24 }],
      metric: [{ label: 'w: 457mm', width: 457, height: 610 }],
    },
  },
  RANGE_COMMERCIAL: {
    key: 'RANGE_COMMERCIAL',
    title: 'Kitchen Range',
    variant: 'commercial',
    type: ObjectType.appliance,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 48", d: 28"', width: 48, height: 28 },
        { label: 'w: 36", d: 28"', width: 36, height: 28 },
      ],
      metric: [
        { label: 'w: 1219mm, d: 711mm', width: 1219, height: 711 },
        { label: 'w: 914mm, d: 711mm', width: 914, height: 711 },
      ],
    },
  },
};

import * as paper from 'paper';
import { SheetSnapshotIn } from 'state/models/Sheet';
import { createOrActivateLayer } from 'features/editor/utils/Paper/Layers';
import { fitToScreen } from 'features/editor/utils/Paper/View';
import { Layers } from 'features/editor/types';

// const drawGridDots = (sheetSnapshot: SheetSnapshotIn) => {
//   const RADIUS = sheetSnapshot.cellSizePixels / 20;
//   const COLOR = new paper.Color(0.8, 0.8, 0.8, 0.9);

//   const gridLayer = createOrActivateLayer('GRID_DOTS');
//   gridLayer.removeChildren();

//   const gridBoundingRect = new paper.Path.Rectangle({
//     position: paper.view.center,
//     width: sheetSnapshot.gridWidthPixels,
//     height: sheetSnapshot.gridHeightPixels,
//   });
//   gridBoundingRect.name = 'GRID_BOUNDING_RECT';

//   const dot = new paper.Shape.Circle(gridBoundingRect.bounds.topLeft, RADIUS);
//   dot.fillColor = COLOR;

//   for (let c = 0; c <= sheetSnapshot.gridColumns; c++) {
//     for (let r = 0; r <= sheetSnapshot.gridRows; r++) {
//       const clone = dot.clone();
//       clone.position.x += sheetSnapshot.cellSizePixels * c;
//       clone.position.y += sheetSnapshot.cellSizePixels * r;
//     }
//   }
// };

const drawGridLines = (sheetSnapshot: SheetSnapshotIn) => {
  const LINE_WIDTH = sheetSnapshot.cellSizePixels / 28;
  const LINE_COLOR = new paper.Color(0.8, 0.8, 0.8, 0.7);

  createOrActivateLayer(Layers.grid, {
    removeChildren: true,
  });

  const gridBoundingRect = new paper.Path.Rectangle({
    position: paper.view.center,
    width: sheetSnapshot.gridWidthPixels,
    height: sheetSnapshot.gridHeightPixels,
  });
  gridBoundingRect.name = 'GRID_BOUNDING_RECT';

  const verticleGridline = new paper.Path({
    segments: [
      gridBoundingRect.bounds.topLeft,
      gridBoundingRect.bounds.bottomLeft,
    ],
  });

  verticleGridline.strokeWidth = LINE_WIDTH;
  verticleGridline.strokeColor = LINE_COLOR;

  for (let i = 1; i <= sheetSnapshot.gridColumns; i++) {
    const clone = verticleGridline.clone();
    clone.position.x += sheetSnapshot.cellSizePixels * i;
  }

  const horizontalGridline = new paper.Path({
    segments: [
      gridBoundingRect.bounds.topLeft,
      gridBoundingRect.bounds.topRight,
    ],
  });

  horizontalGridline.strokeWidth = LINE_WIDTH;
  horizontalGridline.strokeColor = LINE_COLOR;

  for (let i = 1; i <= sheetSnapshot.gridRows; i++) {
    const clone = horizontalGridline.clone();
    clone.position.y += sheetSnapshot.cellSizePixels * i;
  }
};

/**
 * Draws a new sheet to the current view
 *
 * @param width pixels
 * @param height pixels
 *
 */
export const drawSheet = (sheetSnapshot: SheetSnapshotIn) => {
  createOrActivateLayer(Layers.sheet, {
    removeChildren: true,
  });

  const sheet = new paper.Path.Rectangle({
    position: paper.view.center,
    width: sheetSnapshot.widthPixels,
    height: sheetSnapshot.heightPixels,
    fillColor: 'white',
  });

  if (!sheet) {
    return;
  }

  drawGridLines(sheetSnapshot);
  fitToScreen();
};

import styled from 'styled-components';

interface WrapperProps {
  isOpen: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  z-index: -1;
  bottom: 48px;
  right: 0;
  width: 160px;
  padding: 8px 0px 8px 0px;
  border-radius: 6px;
  background-color: #303030;
  color: #fff;
  font-weight: 600;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.1s;
  transform-origin: 0 100%;
  transform: ${({ isOpen }) => (!isOpen ? 'scaleY(0)' : 'none')};
`;

interface OptionProps {
  isActive: boolean;
}

export const MenuOption = styled.button<OptionProps>`
  width: 100%;
  background: transparent;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;

  svg {
    padding-right: 8px;
    color: ${({ isActive }) => (isActive ? '#008cea' : '#555')};
  }

  :hover {
    background-color: #008cea;

    svg {
      color: ${({ isActive }) => (isActive ? '#fff' : '#555')};
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #444;
  margin: 8px 0;
`;

import { DimensionInstance } from 'state/models/Dimensions';
// import DimensionAnchorVerticalIcon from 'features/editor/icons/DimensionAnchorVerticalIcon';
// import DimensionAnchorHorizontalIcon from 'features/editor/icons/DimensionAnchorHorizontalIcon';
import { Label, StyledLine } from './styles';

interface DimensionLineProps {
  data: DimensionInstance;
  color: string;
}

// const ANCHOR_SIZE = 24;

const DimensionLine = ({ data, color }: DimensionLineProps) => {
  const { start, center, label, angle, length, offset } = data;

  // const renderAnchorIcon = () => {
  //   return angle === 0 || angle === 180 ? (
  //     <DimensionAnchorHorizontalIcon
  //       width={ANCHOR_SIZE}
  //       height={ANCHOR_SIZE}
  //       color={color}
  //     />
  //   ) : (
  //     <DimensionAnchorVerticalIcon
  //       width={ANCHOR_SIZE}
  //       height={ANCHOR_SIZE}
  //       color={color}
  //     />
  //   );
  // };

  return (
    <>
      <StyledLine
        top={start.y}
        left={start.x}
        length={length}
        angle={angle}
        color={color}
        offset={offset || 0}
      />
      {/* <Anchor
        top={start.y}
        left={start.x}
        size={ANCHOR_SIZE}
        angle={angle}
        color={color}
        offset={offset || 0}
      >
        {renderAnchorIcon()}
      </Anchor>
      <Anchor
        top={end.y}
        left={end.x}
        size={ANCHOR_SIZE}
        angle={angle}
        color={color}
        offset={offset || 0}
      >
        {renderAnchorIcon()}
      </Anchor> */}
      <Label
        top={center.y}
        left={center.x}
        angle={angle}
        color={color}
        offset={offset || 0}
      >
        {label}
      </Label>
    </>
  );
};

export default DimensionLine;

import { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import { Workspace } from './features/editor/modules/Workspace';
import { AuthProvider } from 'contexts/AuthContext';
import LoginModal from 'features/auth/components/LoginModal';
import WelcomeModal from 'features/auth/components/WelcomeModal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ResetPasswordModal from 'features/auth/components/ResetPasswordModal';
import ProjectLimitModal from 'features/editor/components/ProjectLimitModal';
import MobileMarketingPage from 'features/mobile/modules/MobileMarketing';

const App = () => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    setMobile(window?.innerWidth < 480);
  }, []);

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        {isMobile ? (
          <MobileMarketingPage />
        ) : (
          <>
            <Workspace />
            <LoginModal />
            <ResetPasswordModal />
            <WelcomeModal />
            <ProjectLimitModal />
            <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
              limit={3}
            />
          </>
        )}
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;

import styled from 'styled-components';

export const StyledModalBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;

const BORDER_RADIUS = 6;

export const StyledModalContainer = styled.div`
  position: relative;
  width: 424px;
  padding: 36px 0;
  border-radius: ${`${BORDER_RADIUS}px`};
  backdrop-filter: ${(props) => props.theme.glass.backdropFilter};
  color: #161616;
  user-select: none;
  background: #fff;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
`;

export const Subtitle = styled.h4`
  margin: 0 0 8px 40px;
  display: flex;
  font-size: 20px;
  justify-content: start;
  color: #000;
  font-weight: 500;
`;

export const Paragraph = styled.p`
  margin: 8px 40px;
  color: #222;
  font-size: 14px;
  display: flex;
  justify-content: start;
  text-align: center;
`;

export const LoginLink = styled.p`
  margin: 40px 24px 16px 8px;
  display: flex;
  justify-content: center;
  text-align: center;
  a {
    color: rgb(0, 120, 214);
    text-decoration: underline;
    margin-left: 4px;
    cursor: pointer;
    &:hover {
      color: rgb(0, 100, 204);
    }
    &:active {
      color: rgb(0, 80, 164);
    }
  }
`;

export const FormError = styled.p`
  margin: 8px 8px;
  display: flex;
  justify-content: left;
  color: #ff0000;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  font-weight: bold;
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const StyledForm = styled.form`
  display: flex;
  align-content: center;
  align-items: start;
  flex-direction: column;
  margin: 32px;

  input {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }
`;

export const StyledFormInput = styled.input`
  width: 90%;
  align-self: center;
  height: 52px;
  border: none;
  padding: 0 12px;
  font-size: 18px;
`;

export const StyledFormSubmit = styled.button`
  margin: 24px 0 0 8px;
  border-radius: 4px;
  padding: 0 24px;
  align-self: center;
  height: 40px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  background: #333;
  color: #fff;

  &:hover {
    background: #555;
  }
`;

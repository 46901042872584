import styled from 'styled-components';

const BORDER_RADIUS = 6;

export const Wrapper = styled.div`
  position: fixed;
  z-index: 99999;
  top: calc(50% - 150px);
  left: calc(50% - 170px);
  width: 340px;
  padding: 24px;
  background: #fff;
  border-radius: ${`${BORDER_RADIUS}px`};
  backdrop-filter: ${(props) => props.theme.glass.backdropFilter};
  color: #161616;
  user-select: none;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);

  h4 {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 8px 0;
    font-weight: 500;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
  }

  button {
    margin: 24px 0 0 0;
    width: 100%;
    background: #333;
    color: #f0f0f0;
    border-radius: 4px;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    border: none;

    &:hover {
      background: #555;
    }
  }
`;

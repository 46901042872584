import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const BathroomSinks = <ObjectMap>{
  //
  // Bathroom Sinks
  //
  BATHROOM_SINK_SQUARE: {
    key: 'BATHROOM_SINK_SQUARE',
    title: 'Bathroom Sink',
    variant: 'square',
    type: ObjectType.bathroomSink,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 12", d: 12"', width: 12, height: 12 },
        { label: 'w: 16", d: 12"', width: 16, height: 12 },
        { label: 'w: 20", d: 12"', width: 20, height: 12 },
        { label: 'w: 16", d: 16"', width: 16, height: 16 },
        { label: 'w: 20", d: 16"', width: 20, height: 16 },
      ],
      metric: [
        { label: 'w: 305mm, d: 305mm', width: 305, height: 305 },
        { label: 'w: 406mm, d: 305mm', width: 406, height: 305 },
        { label: 'w: 508mm, d: 305mm', width: 508, height: 305 },
        { label: 'w: 406mm, d: 406mm', width: 406, height: 406 },
        { label: 'w: 508mm, d: 406mm', width: 508, height: 406 },
      ],
    },
  },
  BATHROOM_SINK_ROUND: {
    key: 'BATHROOM_SINK_ROUND',
    title: 'Bathroom Sink',
    variant: 'round',
    type: ObjectType.bathroomSink,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 12", d: 12"', width: 12, height: 12 },
        { label: 'w: 16", d: 12"', width: 16, height: 12 },
        { label: 'w: 20", d: 12"', width: 20, height: 12 },
        { label: 'w: 16", d: 16"', width: 16, height: 16 },
        { label: 'w: 20", d: 16"', width: 20, height: 16 },
      ],
      metric: [
        { label: 'w: 305mm, d: 305mm', width: 305, height: 305 },
        { label: 'w: 406mm, d: 305mm', width: 406, height: 305 },
        { label: 'w: 508mm, d: 305mm', width: 508, height: 305 },
        { label: 'w: 406mm, d: 406mm', width: 406, height: 406 },
        { label: 'w: 508mm, d: 406mm', width: 508, height: 406 },
      ],
    },
  },
};

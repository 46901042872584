import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Sofas = <ObjectMap>{
  LOVE_SEAT: {
    key: 'LOVE_SEAT',
    title: 'Love Seat',
    type: ObjectType.sofa,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        {
          label: 'w: 65", d: 36"',
          width: 65,
          height: 36,
        },
      ],
      metric: [
        {
          label: 'w: 1651mm, d: 914mm',
          width: 1651,
          height: 914,
        },
      ],
    },
  },
  SOFA_3_SECTION: {
    key: 'SOFA_3_SECTION',
    title: 'Sofa',
    variant: '3 sections',
    type: ObjectType.sofa,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        {
          label: 'w: 84", d: 38"',
          width: 84,
          height: 38,
        },
        {
          label: 'w: 96", d: 38"',
          width: 96,
          height: 38,
        },
        {
          label: 'w: 106", d: 38"',
          width: 106,
          height: 38,
        },
      ],
      metric: [
        {
          label: 'w: 2134mm, d: 965mm',
          width: 2134,
          height: 965,
        },
        {
          label: 'w: 2438mm, d: 965mm',
          width: 2438,
          height: 965,
        },
        {
          label: 'w: 2692mm, d: 965mm',
          width: 2692,
          height: 965,
        },
      ],
    },
  },
  SOFA_CHAISE: {
    key: 'SOFA_CHAISE',
    title: 'Sofa',
    variant: 'with chaise',
    type: ObjectType.sofa,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        {
          label: 'w: 96", d: 62"',
          width: 96,
          height: 62,
        },
      ],
      metric: [
        {
          label: 'w: 2438mm, d: 1575mm',
          width: 2438,
          height: 1575,
        },
      ],
    },
  },
};

import { observer } from 'mobx-react-lite';
import { useLocalStore } from 'state';
import DimensionLine from 'features/editor/components/DimensionLine';
import { Label } from '../DimensionLine/styles';

const Dimensions = () => {
  const { dimensions, ui, view } = useLocalStore();
  let color = view.hoveredCellOutlineColor || ui.theme.dimensionColor;
  if (color === '#aaa') color = '#008cea';

  return (
    <>
      {dimensions.activeDrawing?.map((dimensionData) => (
        <DimensionLine
          key={dimensionData.id}
          data={dimensionData}
          color={color}
        />
      ))}
      {dimensions.labels?.map((label) => (
        <Label
          key={label.id}
          top={label.position.y}
          left={label.position.x}
          angle={0}
          offset={0}
          color={color}
        >
          {label.label}
        </Label>
      ))}
    </>
  );
};

export default observer(Dimensions);

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  color: #e5e5e5;
`;

export const Input = styled.input`
  color: #f0f0f0;
  background: transparent;
  border: 1px solid #444;
  border-radius: 6px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 4px 68px 4px 8px;
  width: 200px;
  height: 24px;
  outline: none;
  font-size: 16px;

  &:hover {
    border-color: #555;
  }
  &:focus {
    border-color: #008cea;
  }
`;

export const SaveIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 218px;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 12px;
  font-size: 12px;
  color: #b5b5b5;
  font-style: italic;
`;

export const NewProjectButton = styled.button`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: none;
  outline: 1px transparent;

  align-self: center;
  padding: 0 16px;
  height: 28px;
  margin: 0 12px 0 0;
  border-radius: 3px;
  background: transparent;
  color: #f0f0f0;
  font-weight: bold;
`;

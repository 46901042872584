import { types, Instance } from 'mobx-state-tree';

const Cursor = types
  .model({
    clientX: types.number,
    clientY: types.number,
    mouseDown: types.boolean,
    rightClick: types.boolean,
  })
  .actions((self) => ({
    setPosition(value: { clientX: number; clientY: number }) {
      self.clientX = value.clientX;
      self.clientY = value.clientY;
    },
    setMouseDown(value: boolean) {
      self.mouseDown = value;
    },
    setRightClick(value: boolean) {
      self.rightClick = value;
    },
  }));

export type CursorInstance = Instance<typeof Cursor>;

export default Cursor;

import { useCallback, useEffect, useRef, useState } from 'react';
import * as paper from 'paper';
import { observer } from 'mobx-react-lite';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import LexicalComposer from '@lexical/react/LexicalComposer';
import RichTextPlugin from '@lexical/react/LexicalRichTextPlugin';
import ContentEditable from '@lexical/react/LexicalContentEditable';
import AutoFocusPlugin from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalOnChangePlugin from '@lexical/react/LexicalOnChangePlugin';
import ListPlugin from '@lexical/react/LexicalListPlugin';
import { ListItemNode, ListNode } from '@lexical/list';
import {
  browserToGridCoordinates,
  gridToBrowserCoordinates,
} from 'features/editor/utils/Paper/View';
import { useLocalStore } from 'state';
import { TextItemModel } from 'state/models/TextItems';
import {
  BlinkingCursor,
  TextItemDragOverlay,
  TextItemInnerWrapper,
  TextItemWrapper,
} from './styles';
import { APP_BAR_HEIGHT_PX } from 'features/editor/config';
import { Point, ToolEnum } from 'features/editor/types';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { TEXT_COLORS } from './plugins/ToolbarPlugin/constants';
import { SaveStatus } from 'state/types';

const SyncEditorStatePlugin = observer(({ itemId }: { itemId: string }) => {
  const [editor] = useLexicalComposerContext();
  const { file } = useLocalStore();
  const textItems = file.undoable.textItems;
  const incomingData = textItems?.getTextItemData(itemId);

  useEffect(() => {
    if (incomingData?.data) {
      editor.getEditorState().read(() => {
        const existingJson = editor.getEditorState().clone(null).toJSON();
        const existingTextContent =
          existingJson._nodeMap[0][1].getTextContent();
        const parsedIncomingEditorState = editor.parseEditorState(
          incomingData.data as string
        );
        if (
          existingTextContent !== incomingData.textContent ||
          parsedIncomingEditorState._nodeMap.size !==
            existingJson._nodeMap.length
        ) {
          editor.setEditorState(parsedIncomingEditorState.clone(null));
        }
      });
    }
  }, [incomingData, editor]);

  return null;
});

type TextItemProps = {
  item: TextItemModel;
  isFocused: boolean;
  isOverride?: boolean;
};

const TextItem = ({ item, isFocused, isOverride }: TextItemProps) => {
  const [, setTime] = useState(Date.now());
  const [draggingOffset, setDraggingOffset] = useState<Point | null>(null);
  const interval = useRef<NodeJS.Timeout | null>(null);
  const editorStateRef = useRef<any | undefined>();
  const {
    file,
    view,
    overridedTextItems,
    toolbar,
    applyOverrides,
    cursor,
    setSaveStatus,
  } = useLocalStore();
  const textItems = file.undoable.textItems;
  const overridedItem = overridedTextItems.getTextItemData(item.id);

  const getPositionOnScreen = (point: { x: number; y: number }) => {
    const browserPoint = gridToBrowserCoordinates(new paper.Point(point));
    browserPoint.y -= APP_BAR_HEIGHT_PX;
    return browserPoint;
  };

  const position = getPositionOnScreen({
    x: overridedItem?.x || item.x,
    y: overridedItem?.y || item.y,
  });

  const handleChange = (
    itemId: string,
    lexicalData: string,
    textContent: string
  ) => {
    const prev =
      overridedTextItems.getTextItemData(itemId) ||
      textItems?.getTextItemData(itemId);
    if (!prev) return;
    const isEmpty = !!document.getElementById(`placeholder_${itemId}`);
    // set item override
    overridedTextItems.setOverridedTextItem({
      ...prev,
      data: isEmpty ? undefined : lexicalData,
      textContent: textContent,
    });
  };

  const handleDoubleClick = () => {
    toolbar.setCurrentTool(ToolEnum.text);
    document.getElementById(item.id)?.focus();
  };

  const handleRightClick = useCallback(() => {
    toolbar.setCurrentTool(ToolEnum.text);
    document.getElementById(item.id)?.focus();
  }, [item.id, toolbar]);

  const handleMouseDown = (e: any) => {
    let isRightClick;
    if ('which' in e)
      // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
      isRightClick = e.which == 3;
    else if ('button' in e)
      // IE, Opera
      isRightClick = e.button == 2;
    if (isRightClick) {
      handleRightClick();
      return;
    }

    if (
      toolbar.current !== ToolEnum.text &&
      toolbar.current !== ToolEnum.move
    ) {
      toolbar.setCurrentTool(ToolEnum.move);
    }
    const rect = e.currentTarget.getBoundingClientRect();
    const offset = {
      x: e.clientX - rect.x,
      y: e.clientY - rect.y,
    };
    setDraggingOffset(offset);
  };

  useEffect(() => {
    if (!draggingOffset || !cursor.mouseDown) return;
    const prev =
      overridedTextItems.getTextItemData(item.id) ||
      textItems?.getTextItemData(item.id);
    if (!prev) return;

    const paperPoint = browserToGridCoordinates({
      x: cursor.clientX - draggingOffset.x,
      y: cursor.clientY - draggingOffset.y,
    });

    if (
      Math.abs(prev.x - paperPoint.x) < 3 &&
      Math.abs(prev.y - paperPoint.y) < 3
    ) {
      return;
    }

    setSaveStatus(SaveStatus.unsaved);

    overridedTextItems.setOverridedTextItem({
      ...prev,
      x: paperPoint.x,
      y: paperPoint.y,
    });
  }, [
    cursor.clientX,
    cursor.clientY,
    cursor.mouseDown,
    draggingOffset,
    item.id,
    overridedTextItems,
    setSaveStatus,
    textItems,
  ]);

  useEffect(() => {
    if (draggingOffset && !cursor.mouseDown) {
      applyOverrides();
      setDraggingOffset(null);
    }
  }, [applyOverrides, cursor.mouseDown, draggingOffset]);

  useEffect(() => {
    // re-render every 16ms while view is changing
    if (view.isChanging) {
      interval.current = setInterval(() => setTime(Date.now()), 20);
    } else {
      interval.current && clearInterval(interval.current);
    }
  }, [view.isChanging]);

  return (
    <TextItemWrapper
      top={position.y}
      left={position.x}
      fontSize={overridedItem?.fontSize || item.fontSize || 60}
      color={overridedItem?.color || item.color || TEXT_COLORS[0].value}
      scale={view.zoom}
      noHover={isOverride || !!draggingOffset}
      isTextTool={toolbar.current === ToolEnum.text}
      isFocused={isFocused}
      className="text-item-wrapper"
    >
      <TextItemInnerWrapper>
        {(toolbar.current !== ToolEnum.text || view.panMode) && (
          <TextItemDragOverlay
            onDoubleClick={() => !view.panMode && handleDoubleClick()}
            onMouseDown={(e) => !view.panMode && handleMouseDown(e)}
            onClick={() => {}}
          />
        )}
        <LexicalComposer
          initialConfig={{
            onError: () => {},
            nodes: [ListNode, ListItemNode],
          }}
        >
          <RichTextPlugin
            contentEditable={
              <ContentEditable id={item.id} className="editor-input" />
            }
            initialEditorState={item.data}
            placeholder={
              isFocused ? (
                <BlinkingCursor id={`placeholder_${item.id}`} />
              ) : null
            }
          />
          {isOverride ? <AutoFocusPlugin /> : <div />}
          <LexicalOnChangePlugin
            onChange={(e) => {
              editorStateRef.current = e;
              e.read(() => {
                const json = e.clone(null).toJSON();
                const textContent = json._nodeMap[0][1].getTextContent();
                handleChange(item.id, JSON.stringify(json), textContent);
              });
            }}
          />
          <SyncEditorStatePlugin itemId={item.id} />
          <ListPlugin />
          <ToolbarPlugin itemId={item.id} isFocused={isFocused} />
        </LexicalComposer>
      </TextItemInnerWrapper>
    </TextItemWrapper>
  );
};

export default observer(TextItem);

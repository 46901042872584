/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CellPosition,
  Cell,
  CellInfo,
  Edge,
  NodeValue,
} from 'features/editor/types';
import {
  cellPositionFromPoint,
  getCellPosition,
} from 'features/editor/utils/Grid';

const removeEdge = (v: string, w: string, graph: any) => {
  graph.removeEdge(v, w);
  graph.removeEdge(w, v);
};

export const nodeIdFromCell = (cell: Cell) => {
  return `${cell.row}_${cell.column}`;
};

export const getEdgeDirection = (c1: Cell, c2: Cell) => {
  if (c1.row === c2.row) {
    return 'HORIZONTAL';
  }

  return 'VERTICAL';
};

const bisectEdge = (cell: Cell, edge: Edge, graph: any) => {
  removeEdge(edge.v, edge.w, graph);
  const newNodeId = nodeIdFromCell(cell);
  graph.setNode(newNodeId, { row: cell.row, column: cell.column });
  graph.setEdge(newNodeId, edge.v);
  graph.setEdge(newNodeId, edge.w);

  return newNodeId;
};

const addBridge = (c1: CellInfo, c2: CellInfo, graph: any) => {
  let idBridgeNode1: string | null = null;
  let idBridgeNode2: string | null = null;

  // Find the first node of the bridge from c1
  if (c1.node) {
    // c1 is a node
    const edges = graph.nodeEdges(c1.node.id);
    if (edges.length === 1) {
      // c1 is a node with a single edge
      if (
        getEdgeDirection(c1, c2) ===
        getEdgeDirection(graph.node(edges[0].v), graph.node(edges[0].w))
      ) {
        // c1 is a node with a single edge, parallel to the brigde
        // remove node c1
        graph.removeNode(c1.node.id);
        // the bridge node is the edge's other node
        idBridgeNode1 = edges[0].v !== c1.node.id ? edges[0].v : edges[0].w;
      } else {
        // c1 is a node with a single edge, perpendicular to the brigde
        // the bridge node is c1
        idBridgeNode1 = c1.node.id;
      }
    } else {
      // c1 is a node with multiple edges
      // the bridge node is c1
      idBridgeNode1 = c1.node.id;
    }
  } else if (c1.edge) {
    // c1 is an edge
    // bisect the edge and use the new node as the bridge node
    idBridgeNode1 = bisectEdge(c1, c1.edge, graph);
  }

  // Find the second node of the bridge from c2
  if (c2.node) {
    // c2 is a node
    const edges = graph.nodeEdges(c2.node.id);
    if (edges.length === 1) {
      // c2 is a node with a single edge
      if (
        getEdgeDirection(c1, c2) ===
        getEdgeDirection(graph.node(edges[0].v), graph.node(edges[0].w))
      ) {
        // c2 is a node with a single edge, parallel to the brigde
        // remove node c2
        graph.removeNode(c2.node.id);
        // the bridge node is the edge's other node
        idBridgeNode2 = edges[0].v !== c2.node.id ? edges[0].v : edges[0].w;
      } else {
        // c2 is a node with a single edge, perpendicular to the brigde
        // the bridge node is c2
        idBridgeNode2 = c2.node.id;
      }
    } else {
      // c2 is a node with multiple edges
      // the bridge node is c2
      idBridgeNode2 = c2.node.id;
    }
  } else if (c2.edge) {
    // c2 is an edge
    // bisect the edge and use the new node as the bridge node
    idBridgeNode2 = bisectEdge(c2, c2.edge, graph);
  }

  if (idBridgeNode1 && idBridgeNode2) {
    return graph.setEdge(idBridgeNode1, idBridgeNode2);
  }

  return graph;
};

export const getCellInfo = (cell: Cell, graph: any, paths: paper.Path[]) => {
  const result: CellInfo = {
    row: cell.row,
    column: cell.column,
  };

  const nodeId = nodeIdFromCell(cell);
  const node = graph.node(nodeId) as NodeValue;

  if (node) {
    return { ...result, node: { id: nodeId, value: node } };
  }

  const cellCenter = getCellPosition(cell)?.center;

  for (let i = 0; i < paths.length; i++) {
    const path = paths[i];
    if (cellCenter && path.contains(cellCenter)) {
      const c1 = cellPositionFromPoint(path.segments[0].point);
      const c2 = cellPositionFromPoint(path.segments[1].point);

      if (c1 && c2) {
        const edge: Edge = {
          v: nodeIdFromCell(c1),
          w: nodeIdFromCell(c2),
        };
        return { ...result, edge };
      }
    }
  }

  return result;
};

const getNeighbors = (cell: Cell, graph: any, paths: paper.Path[]) => {
  const result: {
    n: CellInfo | null;
    e: CellInfo | null;
    s: CellInfo | null;
    w: CellInfo | null;
  } = {
    n: null,
    e: null,
    s: null,
    w: null,
  };

  const nCell: Cell = {
    row: cell.row - 1,
    column: cell.column,
  };
  const nNeighbor = getCellInfo(nCell, graph, paths);
  if (nNeighbor.edge || nNeighbor.node) {
    result.n = nNeighbor;
  }

  const eCell: Cell = {
    row: cell.row,
    column: cell.column + 1,
  };
  const eNeighbor = getCellInfo(eCell, graph, paths);
  if (eNeighbor.edge || eNeighbor.node) {
    result.e = eNeighbor;
  }

  const sCell: Cell = {
    row: cell.row + 1,
    column: cell.column,
  };
  const sNeighbor = getCellInfo(sCell, graph, paths);
  if (sNeighbor.edge || sNeighbor.node) {
    result.s = sNeighbor;
  }

  const wCell: Cell = {
    row: cell.row,
    column: cell.column - 1,
  };
  const wNeighbor = getCellInfo(wCell, graph, paths);
  if (wNeighbor.edge || wNeighbor.node) {
    result.w = wNeighbor;
  }

  return result;
};

const joinToNeighbor = (nodeId: string, neighbor: CellInfo, graph: any) => {
  if (neighbor.node) {
    const edges = graph.nodeEdges(neighbor.node.id);
    if (edges.length === 1) {
      // neighbor is a node with a single edge
      // if the edge is parallel to node<->neighbor, neighbor should be removed
      const node = graph.node(nodeId) as Cell;
      if (
        getEdgeDirection(node, neighbor.node.value) ===
        getEdgeDirection(graph.node(edges[0].v), graph.node(edges[0].w))
      ) {
        const nid = edges[0].v !== neighbor.node.id ? edges[0].v : edges[0].w;
        graph.removeNode(neighbor.node.id);
        return graph.setEdge(nodeId, nid);
      }
    }

    return graph.setEdge(nodeId, neighbor.node.id);
  }

  if (neighbor.edge) {
    const nid = bisectEdge(neighbor, neighbor.edge, graph);
    graph.setEdge(nodeId, nid);
  }

  return graph;
};

export const addCellToGraph = (
  cell: CellPosition,
  graph: any,
  paths: paper.Path[]
) => {
  // Identify cell as one of the following cases:
  //
  // A - Cell falls on existing edge or vertex (no changes to original graph)
  // B - Cell has no neighbors (n, e, s ,w)
  // C - Cell has 1 neighbor (cell will become a vertex)
  // D - Cell has 2 neighbors
  // 			D1. Cell is on a corner (cell will become a vertex)
  // 			D2. Cell is on a line (pass through, cell will not become a vertex)
  // E - Cell has 3 neighbors (cell will become a vertex, adjacent to all its neighbors)
  // F - Cell has 4 neighbors (cell will become a vertex, adjacent to all its neighbors)
  const nodeId = nodeIdFromCell(cell);

  if (graph.hasNode(nodeId)) {
    // node already exists
    return graph;
  }

  if (paths.some((path) => path.contains(cell.center))) {
    // node falls on an existing edge
    return graph;
  }

  /** Not case A */
  const neighbors = getNeighbors(cell, graph, paths);
  const neighborCount =
    (neighbors.n ? 1 : 0) +
    (neighbors.e ? 1 : 0) +
    (neighbors.s ? 1 : 0) +
    (neighbors.w ? 1 : 0);

  switch (neighborCount) {
    case 0:
      // case B: Cell has no neighbors
      // create a new node at cell with no adjacent vertices
      return graph.setNode(nodeId, { row: cell.row, column: cell.column });

    case 1:
      // case C: Cell has 1 neighbor
      // create a new node at cell
      graph.setNode(nodeId, { row: cell.row, column: cell.column });
      // eslint-disable-next-line no-case-declarations
      const neighbor: CellInfo = neighbors.n ||
        neighbors.e ||
        neighbors.s ||
        neighbors.w || { row: 0, column: 0 };
      return joinToNeighbor(nodeId, neighbor, graph);

    case 2:
      // case D: Cell has 2 neighbors
      // check if horizontal line, vertical line, or corner
      if (neighbors.e && neighbors.w) {
        // HORIZONTAL LINE: create a bridge between neighbor cells
        return addBridge(neighbors.e, neighbors.w, graph);
      }
      if (neighbors.n && neighbors.s) {
        // VERTICAL LINE: create a bridge between neighbor cells
        return addBridge(neighbors.n, neighbors.s, graph);
      }
      // CORNER: create a new node at cell & make adjacent to its neighbors
      graph.setNode(nodeId, { row: cell.row, column: cell.column });
      Object.values(neighbors).forEach((n) => {
        if (n) {
          joinToNeighbor(nodeId, n, graph);
        }
      });
      return graph;

    default:
      // case E or F: Cell has > 2 neighbors
      // create a new node at cell & make adjacent to all of its neighbors
      graph.setNode(nodeId, { row: cell.row, column: cell.column });
      Object.values(neighbors).forEach((n) => {
        if (n) {
          joinToNeighbor(nodeId, n, graph);
        }
      });
      return graph;
  }
};

const cleanDirectNeighborNodes = (nodeId: string, graph: any) => {
  const edges = graph.nodeEdges(nodeId);
  if (edges && edges.length === 2) {
    // check if the edges are parallel
    const vE1 = graph.node(edges[0].v) as NodeValue;
    const wE1 = graph.node(edges[0].w) as NodeValue;
    const vE2 = graph.node(edges[1].v) as NodeValue;
    const wE2 = graph.node(edges[1].w) as NodeValue;
    if (
      (vE1.row === wE1.row && vE2.row === wE2.row) ||
      (vE1.column === wE1.column && vE2.column === wE2.column)
    ) {
      // the two edges are parallel
      graph.setEdge(
        edges[0].v === nodeId ? edges[0].w : edges[0].v,
        edges[1].v === nodeId ? edges[1].w : edges[1].v
      );
      graph.removeNode(nodeId);
    }
  }
};

export const removeCellFromGraph = (
  cell: CellPosition,
  graph: any,
  paths: paper.Path[]
) => {
  /**
   * Identify cell as one of the following cases:
   *
   * A - Cell is on a node
   * B - Cell is on an edge
   * C - Cell is already empty
   */
  const nodeId = nodeIdFromCell(cell);

  if (graph.hasNode(nodeId)) {
    // case A: Cell is on a node
    const directNeighborNodeIds: string[] = [];

    const edges = graph.nodeEdges(nodeId);
    if (edges) {
      edges.forEach((edge: { v: string; w: string }) => {
        const otherNodeId = edge.v === nodeId ? edge.w : edge.v;
        const otherNode: NodeValue | undefined = graph.node(otherNodeId);

        if (otherNode) {
          const isDirectNeighbor =
            Math.abs(otherNode.row - cell.row) === 1 ||
            Math.abs(otherNode.column - cell.column) === 1;

          if (isDirectNeighbor) {
            directNeighborNodeIds.push(otherNodeId);
          } else {
            const rowOffset =
              (otherNode.row - cell.row) /
              (Math.abs(otherNode.row - cell.row) || 1);
            const columnOffset =
              (otherNode.column - cell.column) /
              (Math.abs(otherNode.column - cell.column) || 1);
            const bisectCell = {
              row: cell.row + rowOffset,
              column: cell.column + columnOffset,
            };
            bisectEdge(bisectCell, edge, graph);
          }
        }
      });
    }

    graph.removeNode(nodeId);

    // make sure direct neighbors are actually needed
    directNeighborNodeIds.forEach((id) => {
      cleanDirectNeighborNodes(id, graph);
    });

    return graph;
  }

  // check for case B
  for (let i = 0; i < paths.length; i++) {
    const path = paths[i];

    if (path.contains(cell.center)) {
      // case B: Cell is on an edge
      const directNeighborNodeIds = [] as string[];
      const createdNodeIds = [] as string[];
      const neighbors = getNeighbors(cell, graph, paths);
      Object.values(neighbors).forEach((neighbor) => {
        if (neighbor?.node) {
          directNeighborNodeIds.push(neighbor.node.id);
        } else if (neighbor?.edge) {
          if (createdNodeIds.length === 1) {
            // find the edge to bisect
            let edge = null;
            const createdNode = graph.node(createdNodeIds[0]);
            const vNode = graph.node(neighbor.edge.v);
            const wNode = graph.node(neighbor.edge.w);

            if (
              (vNode &&
                neighbor.row > Math.min(createdNode.row, vNode.row) &&
                neighbor.row < Math.max(createdNode.row, vNode.row)) ||
              (neighbor.column > Math.min(createdNode.column, vNode.column) &&
                neighbor.column < Math.max(createdNode.column, vNode.column))
            ) {
              edge = { v: neighbor.edge.v, w: createdNodeIds[0] };
            } else if (
              (wNode &&
                neighbor.row > Math.min(createdNode.row, wNode.row) &&
                neighbor.row < Math.max(createdNode.row, wNode.row)) ||
              (neighbor.column > Math.min(createdNode.column, wNode.column) &&
                neighbor.column < Math.max(createdNode.column, wNode.column))
            ) {
              edge = { v: createdNodeIds[0], w: neighbor.edge.w };
            }
            if (edge) {
              const createdNodeId = bisectEdge(neighbor, edge, graph);
              createdNodeIds.push(createdNodeId);
            }
          } else {
            const createdNodeId = bisectEdge(neighbor, neighbor.edge, graph);
            createdNodeIds.push(createdNodeId);
          }
        }
      });

      if (createdNodeIds.length === 2) {
        // removed cell was not touching any nodes
        removeEdge(createdNodeIds[0], createdNodeIds[1], graph);
      } else if (
        createdNodeIds.length === 1 &&
        directNeighborNodeIds.length === 1
      ) {
        // removed cell was touching 1 node
        removeEdge(createdNodeIds[0], directNeighborNodeIds[0], graph);
      } else if (directNeighborNodeIds.length === 2) {
        removeEdge(directNeighborNodeIds[0], directNeighborNodeIds[1], graph);
      }

      // make sure direct neighbors are actually needed
      directNeighborNodeIds.forEach((id) => {
        cleanDirectNeighborNodes(id, graph);
      });

      return graph;
    }
  }

  // case C: Cell is already empty
  return graph;
};

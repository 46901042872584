import * as paper from 'paper';

export const getGridOffset = (point: paper.Point) => {
  const gridBoundingRect = paper.project.getItem({
    name: 'GRID_BOUNDING_RECT',
  });
  const result = point.subtract(gridBoundingRect.bounds.topLeft);
  return result;
};

export const paperPointFromGridOffset = (point: paper.Point) => {
  const gridBoundingRect = paper.project.getItem({
    name: 'GRID_BOUNDING_RECT',
  });
  const result = point.add(gridBoundingRect.bounds.topLeft);
  return result;
};

export const gridContainsPoint = (point: paper.Point) => {
  const gridBoundingRect = paper.project.getItem({
    name: 'GRID_BOUNDING_RECT',
  });
  return gridBoundingRect.contains(point);
};

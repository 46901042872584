import React from 'react';

const EraserToolIcon = ({ width = 24, height = 24, color = '#fff' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M11.3514 19H7.89189L4.66223 15.6271C4.29193 15.2404 4.29193 14.6306 4.66223 14.2439L12.764 5.78275C13.1678 5.361 13.8456 5.37351 14.2336 5.80988L18.5042 10.613C18.8578 11.0107 18.838 11.6157 18.4591 11.9894L11.3514 19ZM11.3514 19H20M8.75676 9.96774L14.3784 15.8387"
        stroke={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EraserToolIcon;

import mixpanel, { Dict } from 'mixpanel-browser';

export const trackToolbarToolClicked = (data?: Dict) => {
  try {
    mixpanel.track('Editor_ToolClicked', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackRoomToolCommitted = (data?: Dict) => {
  try {
    mixpanel.track('Editor_RoomToolCommitted', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackWallToolCommitted = (data?: Dict) => {
  try {
    mixpanel.track('Editor_WallToolCommitted', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackPenToolCommitted = (data?: Dict) => {
  try {
    mixpanel.track('Editor_PenToolCommitted', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackEraseToolCommitted = (data?: Dict) => {
  try {
    mixpanel.track('Editor_EraseToolCommitted', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackObjectPlaced = (data?: Dict) => {
  try {
    mixpanel.track('Editor_ObjectPlaced', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackObjectDeleted = (data?: Dict) => {
  try {
    mixpanel.track('Editor_ObjectDeleted', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackObjectOverridesCommitted = (data?: Dict) => {
  try {
    mixpanel.track('Editor_ObjectOverridesCommitted', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackTapeMeasureEvent = (data?: Dict) => {
  try {
    mixpanel.track('Editor_TapeMeasureEvent', data);
  } catch (error) {
    console.error(error);
  }
};

// Text tool
export const trackTextPlaced = (data?: Dict) => {
  try {
    mixpanel.track('Editor_TextPlaced', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackTextUpdated = (data?: Dict) => {
  try {
    mixpanel.track('Editor_TextUpdated', data);
  } catch (error) {
    console.error(error);
  }
};

// Undo / redo
export const trackUndo = (data?: Dict) => {
  try {
    mixpanel.track('Editor_Undo', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackRedo = (data?: Dict) => {
  try {
    mixpanel.track('Editor_Redo', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackGridToggled = (data?: Dict) => {
  try {
    mixpanel.track('Editor_GridToggled', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackPanModeToggled = (data?: Dict) => {
  try {
    mixpanel.track('Editor_PanModeToggled', data);
  } catch (error) {
    console.error(error);
  }
};

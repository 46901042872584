import styled from 'styled-components';

export const StyledModalBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;

const BORDER_RADIUS = 6;

export const StyledModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 424px;
  padding: 36px 0;
  border-radius: ${`${BORDER_RADIUS}px`};
  backdrop-filter: ${(props) => props.theme.glass.backdropFilter};
  color: #161616;
  user-select: none;
  background: #f5f5f5;
  background: linear-gradient(
    0deg,
    rgba(238, 238, 238, 1) 0%,
    rgba(252, 252, 252, 1) 100%
  );
  background: #fff;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);

  img {
    width: 96px;
    height: 96px;
    align-self: center;
    margin-bottom: 24px;
  }
`;

export const Title = styled.h1`
  margin: 0 0 8px 0;
  display: flex;
  font-size: 20px;
  justify-content: center;
  color: #000;
`;

export const Subtitle = styled.h4`
  margin: 8px 24px 0 24px;
  color: #222;
  font-size: 16px;
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const LoginLink = styled.p`
  margin: 16px 24px;
  display: flex;
  justify-content: center;
  text-align: center;
  a {
    color: rgb(0, 120, 214);
    text-decoration: underline;
    margin-left: 4px;
    cursor: pointer;
    &:hover {
      color: rgb(0, 100, 204);
    }
    &:active {
      color: rgb(0, 80, 164);
    }
  }
`;

export const FormError = styled.p`
  margin: 8px 16px;
  display: flex;
  justify-content: left;
  color: #ff0000;
`;

export const CloseButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  font-weight: bold;
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const Divider = styled.div`
  position: relative;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0 0 24px 0;

  span {
    position: absolute;
    top: -9px;
    left: calc(50% - 26px);
    padding: 0 16px;
    color: rgba(0, 0, 0, 0.5);
    background: #fff;
    font-weight: bold;
    font-size: 14px;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  align-content: center;
  flex-direction: column;
  margin: 32px;

  input {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  input:first-child {
    border-radius: 0;
    border-bottom: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
`;

export const StyledFormInput = styled.input`
  width: 90%;
  align-self: center;
  height: 52px;
  border: none;
  padding: 0 12px;
  font-size: 18px;
`;

export const StyledFormSubmit = styled.button`
  margin: 24px 0 0 0;
  border-radius: 24px;
  width: 100%;
  align-self: center;
  height: 48px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  background: rgb(0, 140, 234);
  background: #333;

  color: #fff;

  &:hover {
    background: #444;
  }
`;

export const StyledButton = styled.button`
  margin: 24px 0 0 0;
  width: 360px;
  align-self: center;
  height: 48px;
  border: 1px solid #999;
  background: transparent;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
    margin: 0 16px 0 0;
  }

  &:hover {
    border-color: #111;
    color: #000;
    font-weight: bold;
  }
`;

import * as paper from 'paper';
import {
  addPathToWallGraph,
  drawPathFromDrag,
} from 'features/editor/utils/Paper/Walls';
import RootStore from 'state/models/Root';
import { initializeDrawingLayer } from 'features/editor/utils/Paper/Layers';
import { paperToBrowserCoordinates } from '../View';
import { setDimension } from '../Dimensions';
import { getDimensionLabel } from '../../Unit';
import { trackWallToolCommitted } from 'analytics/editor';

/**
 * Drag event handler when the wall tool is selected
 *
 * @param event
 */
export const handleWallToolDrag = (event: paper.ToolEvent) => {
  initializeDrawingLayer();

  // Re-draw the wall
  const { point, downPoint } = event;
  const path = drawPathFromDrag({ point, downPoint });

  if (!path) return;
  path.strokeColor = new paper.Color(RootStore.ui.theme.wallColor_uncommited);

  // set dimension line
  const isMetric = !!RootStore.file.sheet?.metric;
  const PPU = RootStore.file.sheet?.PPU || 1;
  const cellSizePx = RootStore.file.sheet?.cellSizePixels || 1;
  const isHorizontal = path.bounds.height < path.bounds.width;
  let p1 = path.segments[0].point;
  let p2 = path.segments[1].point;
  if (p1.x + p1.y > p2.x + p2.y) {
    const temp = p1;
    p1 = p2;
    p2 = temp;
  }
  const clientWallThickness =
    paperToBrowserCoordinates(new paper.Point(cellSizePx, 0)).x -
    paperToBrowserCoordinates(new paper.Point(0, 0)).x;
  const label = getDimensionLabel(
    (Math.max(path.bounds.height, path.bounds.width) + cellSizePx) / PPU,
    isMetric
  );
  const clientPoints = {
    start: paperToBrowserCoordinates(
      isHorizontal
        ? p1.subtract(new paper.Point(cellSizePx / 2, 0))
        : p1.subtract(new paper.Point(0, cellSizePx / 2))
    ),
    end: paperToBrowserCoordinates(
      isHorizontal
        ? p2.add(new paper.Point(cellSizePx / 2, 0))
        : p2.add(new paper.Point(0, cellSizePx / 2))
    ),
  };
  setDimension(clientPoints, label, clientWallThickness);
};

/**
 * Mouseup event handler when the wall tool is selected
 *
 * @param event
 */
export const handleWallToolMouseUp = (event: paper.ToolEvent) => {
  const activeDrawingLayer = initializeDrawingLayer();
  if (!RootStore.dimensions.activeDrawing?.length) {
    activeDrawingLayer.remove();
    return;
  }
  const { point, downPoint } = event;
  const path = drawPathFromDrag({ point, downPoint });

  if (path) {
    const newJSON = addPathToWallGraph(
      path,
      RootStore.file.undoable.walls.json
    );
    RootStore.file.undoable.walls.setWallJSON(newJSON);
    trackWallToolCommitted();
  }

  RootStore.dimensions.clearActiveDrawingDimensions();
  activeDrawingLayer.remove();
};

import styled from 'styled-components';

interface WrapperProps {
  top: number;
  left: number;
}

export const Wrapper = styled.div<WrapperProps>`
  z-index: 999999;
  position: absolute;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  min-width: 180px;
  min-height: 100px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 4px rgba(0.3, 0.3, 0.3, 0.4);
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 0;
`;

interface RowProps {
  active?: boolean;
}
const ACTIVE_COLOR = '#008cea';
export const Row = styled.button<RowProps>`
  outline: none;
  border: none;
  display: grid;
  grid-template-columns: min-content auto min-content;
  align-items: center;
  text-align: left;
  grid-gap: 16px;
  background: transparent;
  height: 36px;
  cursor: pointer;

  svg {
    margin: 0 0 0 8px;
    color: ${({ active }) => (active ? ACTIVE_COLOR : '#333')};
  }

  p {
    color: ${({ active }) => (active ? '#0074c2' : '#000')};
    margin: 0;
    font-size: 14px;
  }

  .shortcut {
    color: #555;
    padding-right: 8px;
    font-size: 12px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const Divider = styled.div`
  margin: 4px 0;
  border-bottom: 1px solid rgba(0.4, 0.4, 0.4, 0.1);
`;

import { types, SnapshotIn } from 'mobx-state-tree';
import { database } from '../../../firebase';
import { setDocumentTitle } from 'helpers/setDocumentTitle';
import { toast } from 'react-toastify';

/**
 * id = null if file has not been saved yet
 */
const ActiveProject = types
  .model({
    id: types.maybeNull(types.string),
    title: types.string,
  })
  .actions((self) => ({
    updateTitle(value: string) {
      const hasChanged = self.title !== value;
      self.title = value;
      setDocumentTitle(value);

      if (hasChanged && self.id) {
        const ref = database.projects.doc(self.id);
        ref.update({
          title: value,
        });
        toast(`Project title changed to '${value}'`);
      }
    },
  }));

export type ActiveProjectSnapshotIn = SnapshotIn<typeof ActiveProject>;

export default ActiveProject;

import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { useLocalStore } from 'state';
import {
  CloseButton,
  LoginLink,
  StyledForm,
  StyledFormInput,
  StyledFormSubmit,
  StyledModalBackdrop,
  StyledModalContainer,
  Subtitle,
  Paragraph,
  FormError,
} from './styles';
import { useAuth } from 'contexts/AuthContext';
import CloseIcon from 'features/editor/icons/CloseIcon';

const ResetPasswordModal = () => {
  const { sendPasswordResetEmail } = useAuth();
  const {
    ui: {
      resetPasswordModalOpen: isOpen,
      setLoginModal,
      setResetPasswordModal,
    },
  } = useLocalStore();
  const [emailInput, setEmailInput] = useState('');
  const [error, setError] = useState('');

  const closeModal = (options?: { openSignInModal?: boolean }) => {
    if (options?.openSignInModal) {
      setLoginModal(true);
    }
    setError('');
    setEmailInput('');
    setResetPasswordModal(false);
  };

  const handleResetPassword = async () => {
    if (!emailInput.length) {
      setError('Please enter a valid email');
      return;
    }
    setError('');
    sendPasswordResetEmail(emailInput);
    toast('A password reset email has been sent');
    closeModal({ openSignInModal: true });
  };

  if (!isOpen) return null;

  return (
    <StyledModalBackdrop>
      <StyledModalContainer>
        <CloseButton type="button" onClick={() => closeModal()}>
          <CloseIcon />
        </CloseButton>
        <Subtitle>Reset Password</Subtitle>
        <Paragraph>
          {"We'll email you instructions to reset your password."}
        </Paragraph>
        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
            handleResetPassword();
          }}
        >
          <StyledFormInput
            type="email"
            name="email"
            placeholder="Email address..."
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
          />
          {!!error.length && <FormError>{error}</FormError>}
          <div>
            <StyledFormSubmit type="submit">Reset Password</StyledFormSubmit>
          </div>
          <LoginLink>
            Back to
            <a
              role="link"
              tabIndex={0}
              onClick={() => closeModal({ openSignInModal: true })}
              onKeyDown={() => {}}
            >
              Sign in
            </a>
          </LoginLink>
        </StyledForm>
      </StyledModalContainer>
    </StyledModalBackdrop>
  );
};

export default observer(ResetPasswordModal);

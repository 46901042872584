import { useAuth } from 'contexts/AuthContext';
import { FREE_TIER_PROJECT_LIMIT } from 'features/editor/config';
import { useLocalStore } from 'state';
import { Wrapper, Row } from './styles';

const getTierName = (t: number) => {
  switch (t) {
    case 0:
      return 'Starter';
    case 1:
      return 'Pro';
    default:
      return 'Starter';
  }
};

const TierPanel = () => {
  const { userTier } = useAuth();
  const { userProjects } = useLocalStore();
  return (
    <Wrapper>
      <Row>
        <span className="tier-display">{`${getTierName(userTier)} Plan`}</span>
        <span className="tier-project-count">
          {userTier === 1
            ? 'Unlimited'
            : `${
                userProjects?.length || 0
              }/${FREE_TIER_PROJECT_LIMIT} projects used`}
        </span>
      </Row>
    </Wrapper>
  );
};

export default TierPanel;

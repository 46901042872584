import { Path, Color } from 'paper';
import { addCellsToWallGraph } from 'features/editor/utils/Paper/Walls';
import RootStore from 'state/models/Root';
import { createOrActivateLayer } from 'features/editor/utils/Paper/Layers';
import { nodeIdFromCell } from 'features/editor/utils/Graphlib/Mutate';
import {
  cellPositionFromPoint,
  getCellPosition,
} from 'features/editor/utils/Grid';
import { Cell, CellPosition, Layers } from 'features/editor/types';
import { trackPenToolCommitted } from 'analytics/editor';

const updatePenTrail = (cellPosition: CellPosition) => {
  const nodeId = nodeIdFromCell(cellPosition);
  if (!RootStore.activeDrawing.penTrail.get(nodeId)) {
    RootStore.activeDrawing.addNodeToPenTrail(nodeId, cellPosition);

    const path = new Path([cellPosition.center, cellPosition.center]);

    path.strokeColor = new Color(RootStore.ui.theme.wallColor_uncommited);
    path.strokeCap = 'square';
    path.strokeWidth = (RootStore.file.sheet?.cellSizePixels || 0) + 1;
  }
};

/**
 * Drag event handler when the wall tool is selected
 *
 * @param event
 */
export const handlePenToolDrag = (event: paper.ToolEvent) => {
  createOrActivateLayer(Layers.activeDrawing);

  const { point, downPoint } = event;
  const cellPosition = cellPositionFromPoint(point);
  const downPointCell = cellPositionFromPoint(downPoint);

  if (
    RootStore.activeDrawing.continuousDrag &&
    cellPosition?.row === downPointCell?.row
  ) {
    const min = Math.min(cellPosition?.column || 0, downPointCell?.column || 0);
    const max = Math.max(cellPosition?.column || 0, downPointCell?.column || 0);
    for (let i = min; i <= max; i++) {
      const cp = getCellPosition({ row: cellPosition?.row || 0, column: i });
      if (cp) {
        updatePenTrail(cp);
      }
    }
  } else if (
    RootStore.activeDrawing.continuousDrag &&
    cellPosition?.column === downPointCell?.column
  ) {
    const min = Math.min(cellPosition?.row || 0, downPointCell?.row || 0);
    const max = Math.max(cellPosition?.row || 0, downPointCell?.row || 0);
    for (let i = min; i <= max; i++) {
      const cp = getCellPosition({ row: i, column: cellPosition?.column || 0 });
      if (cp) {
        updatePenTrail(cp);
      }
    }
  } else if (cellPosition) {
    RootStore.activeDrawing.setContinuousDrag(false);
    updatePenTrail(cellPosition);
  }
};

/**
 * Drag event handler when the wall tool is selected
 *
 * @param event
 */
export const handlePenToolMouseDown = (event: paper.ToolEvent) => {
  createOrActivateLayer(Layers.activeDrawing);
  RootStore.activeDrawing.setContinuousDrag(true);

  const { point } = event;
  const cellPosition = cellPositionFromPoint(point);

  if (cellPosition) {
    const nodeId = nodeIdFromCell(cellPosition);
    if (!RootStore.activeDrawing.penTrail.get(nodeId)) {
      RootStore.activeDrawing.addNodeToPenTrail(nodeId, cellPosition);

      const path = new Path([cellPosition.center, cellPosition.center]);

      path.strokeColor = new Color(RootStore.ui.theme.wallColor_uncommited);
      path.strokeCap = 'square';
      path.strokeWidth = RootStore.file.sheet?.cellSizePixels || 0;
    }
  }
};

/**
 * Mouseup event handler when the wall tool is selected
 *
 * @param event
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const handlePenToolMouseUp = (_event: paper.ToolEvent) => {
  const activeDrawingLayer = createOrActivateLayer(Layers.activeDrawing);
  RootStore.activeDrawing.setContinuousDrag(false);

  const cells: Cell[] = [];
  RootStore.activeDrawing.penTrail.forEach((cell: Cell) => {
    cells.push(cell);
  });

  const newJSON = addCellsToWallGraph(
    cells,
    RootStore.file.undoable.walls.json
  );
  RootStore.file.undoable.walls.setWallJSON(newJSON);
  trackPenToolCommitted();

  activeDrawingLayer.remove();
  RootStore.activeDrawing.clearPenTrail();
};

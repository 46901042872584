import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ResizeHandleIcon from 'features/editor/icons/ResizeHandleIcon';
import {
  handleObjectRotation,
  computeRotationHandlePosition,
  handleObjectRotationStop,
} from 'features/editor/utils/Paper/Objects';
import { useLocalStore } from 'state';
import { Wrapper } from './styles';

interface Props {
  objectId: string;
}

const RotationHandle = ({ objectId }: Props) => {
  const [isDragging, setIsDragging] = useState(false);
  const {
    overridedObjects,
    file,
    cursor,
    objectContextMenu,
    objectDimensionPopover,
    selectedObjects,
    selectedWalls,
  } = useLocalStore();
  const objectData = file.undoable.objects.getObjectData(objectId);
  const isOverrided = !!overridedObjects.getObjectData(objectId);
  const multiSelect =
    selectedObjects.ids.length > 1 || selectedWalls.cells?.length;

  useEffect(() => {
    if (isDragging && !cursor.mouseDown) {
      setIsDragging(false);
      handleObjectRotationStop(objectId);
    }
  }, [cursor.mouseDown, isDragging, objectId]);

  useEffect(() => {
    if (isDragging) {
      handleObjectRotation(objectId, {
        x: cursor.clientX,
        y: cursor.clientY,
      });
    }
  }, [cursor.clientX, cursor.clientY, isDragging, objectId]);

  if (
    isOverrided ||
    !objectData ||
    objectContextMenu ||
    objectDimensionPopover ||
    multiSelect
  )
    return null;

  const position = computeRotationHandlePosition(objectId);

  return (
    <Wrapper
      position={position}
      onMouseDown={() => {
        setIsDragging(true);
      }}
    >
      <ResizeHandleIcon />
    </Wrapper>
  );
};

export default observer(RotationHandle);

import styled from 'styled-components';

type TooltipWrapperProps = { offsetX?: number };

const COLOR = '#fff';
const BACKGROUND_COLOR = '#222';
const KEY_SHORCUT_BACKGROUND_COLOR = '#777';

export const TooltipWrapper = styled.div<TooltipWrapperProps>`
  display: inline-block;
  position: relative;

  .Tooltip-Tip {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px;
    color: ${COLOR};
    background: ${BACKGROUND_COLOR};
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  /* CSS border triangles */
  .Tooltip-Tip::before {
    content: ' ';
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 0px;
    margin-left: calc(0px * -1);
  }

  /* Absolute positioning */
  .Tooltip-Tip.top {
    top: calc(40px * -1);
  }
  /* CSS border triangles */
  .Tooltip-Tip.top::before {
    top: 100%;
    border-top-color: ${BACKGROUND_COLOR};
  }

  /* Absolute positioning */
  .Tooltip-Tip.right {
    left: calc(100% + 40px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.right::before {
    left: calc(0px * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: ${BACKGROUND_COLOR};
  }

  /* Absolute positioning */
  .Tooltip-Tip.bottom {
    bottom: calc(40px * -1);
    left: ${({ offsetX }) => (offsetX ? `calc(50% + ${offsetX}px)` : '50%')};
  }
  /* CSS border triangles */
  .Tooltip-Tip.bottom::before {
    bottom: 100%;
    border-bottom-color: ${BACKGROUND_COLOR};
  }

  /* Absolute positioning */
  .Tooltip-Tip.left {
    left: auto;
    right: calc(100% + 40px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }
  /* CSS border triangles */
  .Tooltip-Tip.left::before {
    left: auto;
    right: calc(0px * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: ${BACKGROUND_COLOR};
  }
`;

export const TooltipContent = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;
`;

export const TooltipKeyShortcut = styled.div`
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  padding: 0px 4px;
  margin-left: 6px;
  border-radius: 2px;
  background: ${KEY_SHORCUT_BACKGROUND_COLOR};
`;

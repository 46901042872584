import React from 'react';

const PenToolIcon = ({ width = 24, height = 24, color = '#fff' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M13.5464 7.69231L15.6007 5.68932C15.9926 5.30722 16.6189 5.31118 17.0059 5.69821L18.3018 6.9941C18.6888 7.38113 18.6928 8.0074 18.3107 8.3993L16.3077 10.4536M13.5464 7.69231L5.53846 15.5L5 19L8.5 18.4615L16.3077 10.4536M13.5464 7.69231L16.3077 10.4536"
        stroke={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PenToolIcon;

import { Column, Gradient, GraphicWrapper, Row, Wrapper } from './styles';

const MobileMarketingPage = () => {
  return (
    <div style={{ height: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
      <Wrapper>
        <Column>
          <Row>
            <img width={24} height={24} src="/gradient-logo-round.png" />
            <h3>floorplanlab.com</h3>
          </Row>
          <Row>
            <span>
              An architectural tool that makes it possible for anyone to create
              professional floor plans.
            </span>
          </Row>
        </Column>
        <Gradient>
          <Column>
            <h1>Grab your computer and start designing today.</h1>
            <h4>{"It's Free!"}</h4>
            <span className="copyright">© 2022 floorplanlab.com</span>
          </Column>
        </Gradient>
        <GraphicWrapper>
          <img width={315} height={190} src="/macbook-graphic.png" />
        </GraphicWrapper>
      </Wrapper>
    </div>
  );
};

export default MobileMarketingPage;

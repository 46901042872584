import { getObjectSymbol } from 'objects/symbols';
import ObjectMetadata from 'objects/metadata';
import { WallOpening, Wrapper, WallOpeningWrapper } from './styles';
import { useLocalStore } from 'state';
import { ObjectModel } from 'state/models/Objects';

interface ObjectPreviewProps {
  objectKey: string;
  height: number;
  width: number;
  padding?: number;
  showWall?: boolean;
  objectData?: ObjectModel;
  wallThickness?: number;
}

const ObjectPreview = ({
  objectKey,
  height,
  width,
  padding,
  showWall,
  objectData,
  wallThickness,
}: ObjectPreviewProps) => {
  const { ui } = useLocalStore();
  const meta = ObjectMetadata[objectKey];
  let rotation;
  if (
    objectData &&
    (objectData.rotation === 90 ||
      objectData.rotation === 180 ||
      objectData.rotation === 270)
  ) {
    // rotate the preview
    rotation = objectData.rotation;
  }

  const renderSvg = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: showWall ? 'center' : 'start',
        }}
      >
        <embed
          src={`data:image/svg+xml;utf8,${getObjectSymbol(objectKey)}`}
          height={height}
          width={width}
          draggable="false"
        />
        {meta.wallOpening && (
          <WallOpeningWrapper>
            {showWall && (
              <WallOpening
                width={height / 4}
                height={wallThickness || height / 5}
                wallColor={ui.theme.wallColor}
                filled
              />
            )}
            <WallOpening
              width={width}
              height={wallThickness || height / 5}
              wallColor={ui.theme.wallColor}
            />
            {showWall && (
              <WallOpening
                width={height / 4}
                height={wallThickness || height / 5}
                wallColor={ui.theme.wallColor}
                filled
              />
            )}
          </WallOpeningWrapper>
        )}
      </div>
    );
  };

  return (
    <Wrapper
      padding={padding}
      width={width}
      height={height + (meta.wallOpening ? wallThickness || height / 5 : 0)}
      rotation={rotation}
      flippedVertical={objectData?.flippedVertical}
      flippedHorizontal={objectData?.flippedHorizontal}
    >
      <div className="svg-wrapper">{renderSvg()}</div>
    </Wrapper>
  );
};

export default ObjectPreview;

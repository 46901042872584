import * as paper from 'paper';
import { CellPosition, Layers } from 'features/editor/types';
import { createOrActivateLayer } from '../Layers';

export const drawHoveredCell = (
  cell: CellPosition,
  options?: { color?: string; hideExtensionLines?: boolean }
) => {
  const gridBounds = paper.project.getItem({
    name: 'GRID_BOUNDING_RECT',
  }).bounds;
  createOrActivateLayer(Layers.hoveredCell, { removeChildren: true });
  const rect = new paper.Path.Rectangle(cell.topLeft, cell.bottomRight);
  rect.strokeColor = new paper.Color(options?.color || '#aaa');
  rect.strokeWidth = 4;
  if (options?.hideExtensionLines) return;

  const extensionLines = [] as paper.Path[];

  // top left corner
  extensionLines.push(
    new paper.Path([
      cell.topLeft,
      new paper.Point(cell.topLeft.x, gridBounds.top),
    ])
  );
  extensionLines.push(
    new paper.Path([
      cell.topLeft,
      new paper.Point(gridBounds.left, cell.topLeft.y),
    ])
  );

  // top right corner
  extensionLines.push(
    new paper.Path([
      cell.topRight,
      new paper.Point(cell.topRight.x, gridBounds.top),
    ])
  );
  extensionLines.push(
    new paper.Path([
      cell.topRight,
      new paper.Point(gridBounds.right, cell.topRight.y),
    ])
  );

  // bottom left corner
  extensionLines.push(
    new paper.Path([
      cell.bottomLeft,
      new paper.Point(cell.bottomLeft.x, gridBounds.bottom),
    ])
  );
  extensionLines.push(
    new paper.Path([
      cell.bottomLeft,
      new paper.Point(gridBounds.left, cell.bottomLeft.y),
    ])
  );

  // bottom right corner
  extensionLines.push(
    new paper.Path([
      cell.bottomRight,
      new paper.Point(cell.bottomRight.x, gridBounds.bottom),
    ])
  );
  extensionLines.push(
    new paper.Path([
      cell.bottomRight,
      new paper.Point(gridBounds.right, cell.bottomRight.y),
    ])
  );

  extensionLines.forEach((path) => {
    path.strokeColor = new paper.Color(options?.color || '#5500ff');
    path.strokeWidth = 6;
    path.opacity = 0.25;

    if (options?.color === '#aaa') {
      path.strokeColor = new paper.Color('#888');
    }
  });
};

const MoveToolIcon = ({ width = 24, height = 24, color = '#fff' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.97911 18.3561L12.8151 13.84L18.6442 12.776L7.83253 5.20994L8.97911 18.3561Z"
      stroke={color}
    />
  </svg>
);

export default MoveToolIcon;

import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Rnd } from 'react-rnd';
import SearchIcon from 'features/editor/icons/SearchIcon';
import { useLocalStore } from 'state';
import ObjectMetadata from 'objects/metadata';
import { ObjectData, ObjectType } from 'features/editor/types';
import {
  ObjectPanelWrapper,
  ContentWrapper,
  SearchInput,
  SearchInputWrapper,
  SearchInputLeft,
  ScrollArea,
  Tip,
} from './styles';
import ObjectPanelRow from '../ObjectPanelRow';
import { getItem, setItem } from 'helpers/storage';
import CloseIcon from 'features/editor/icons/CloseIcon';

const sortedObjects = {};
Object.values(ObjectType).forEach((type) => {
  const objects = Object.values(ObjectMetadata).filter(
    (obj) => obj.type === type
  );
  sortedObjects[type] = objects;
});

const ObjectPanel = () => {
  const { ui, activeProject } = useLocalStore();
  const [filteredObjects, setFilteredObjects] = useState<any>(sortedObjects);
  const [showTip, setShowTip] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const hideTip = getItem('hide-object-panel-tip') === 'true';
    if (!hideTip) {
      setShowTip(true);
    }
  }, []);

  useEffect(() => {
    if (!searchInput.length) {
      setFilteredObjects(sortedObjects);
    } else {
      const filtered = {};
      Object.entries(sortedObjects).forEach(([type, objects]) => {
        const f = (objects as ObjectData[]).filter((obj) =>
          obj.title.toLowerCase().includes(searchInput.toLowerCase())
        );
        if (f.length) {
          filtered[type] = f;
        }
      });
      setFilteredObjects(filtered);
    }
  }, [searchInput]);

  const onCloseTip = () => {
    setShowTip(false);
    setItem('hide-object-panel-tip', 'true');
  };

  if (!activeProject) return null;
  return (
    <ObjectPanelWrapper isOpen={ui.objectPanelOpen} width={ui.objectPanelWidth}>
      <Rnd
        size={{ width: ui.objectPanelWidth, height: '100%' }}
        position={{ x: 0, y: 0 }}
        onResize={(_e, _dir, ref) => {
          ui.setObjectPanelWidth(ref.clientWidth);
          ref.style.transform = 'none';
        }}
        minWidth={364}
        maxWidth={650}
        enableResizing={{
          top: false,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        disableDragging
      >
        <ContentWrapper>
          <SearchInputWrapper>
            <SearchInput
              placeholder="Search objects..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <SearchInputLeft>
              <SearchIcon width={22} />
            </SearchInputLeft>
          </SearchInputWrapper>

          <ScrollArea
            ref={scrollAreaRef}
            onWheel={(e) => {
              if (
                scrollAreaRef.current &&
                Math.abs(e.deltaY) > Math.abs(e.deltaX)
              )
                scrollAreaRef.current.scrollTop += e.deltaY;
            }}
          >
            <div style={{ paddingBottom: 400 }}>
              {showTip && (
                <Tip>
                  <p>
                    <b>Tip:</b>
                    {
                      ' Right click any object once added to your project to customize its dimensions.'
                    }
                  </p>
                  <button type="button" onClick={onCloseTip}>
                    <CloseIcon color="#333" />
                  </button>
                </Tip>
              )}
              {Object.entries(filteredObjects).map(([type, meta]) => {
                if (!(meta as ObjectData[])?.length) return null;
                return (
                  <ObjectPanelRow
                    key={type}
                    objectType={type as ObjectType}
                    meta={meta as ObjectData[]}
                  />
                );
              })}
            </div>
          </ScrollArea>
        </ContentWrapper>
      </Rnd>
    </ObjectPanelWrapper>
  );
};

export default observer(ObjectPanel);

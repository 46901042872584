import mixpanel, { Dict } from 'mixpanel-browser';

export const trackSignOut = (data?: Dict) => {
  try {
    mixpanel.track('User_SignOut', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackSignUp = (data?: Dict) => {
  try {
    mixpanel.track('User_SignUp', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackSignUpFailed = (data?: Dict) => {
  try {
    mixpanel.track('User_SignUpFailed', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackSignIn = (data?: Dict) => {
  try {
    mixpanel.track('User_SignIn', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackSignInFailed = (data?: Dict) => {
  try {
    mixpanel.track('User_SignInFailed', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackContinueWithGoogle = (data?: Dict) => {
  try {
    mixpanel.track('User_ContinueWithGoogle', data);
  } catch (error) {
    console.error(error);
  }
};

export const trackContinueAsGuest = (data?: Dict) => {
  try {
    mixpanel.track('User_ContinueAsGuest', data);
  } catch (error) {
    console.error(error);
  }
};

import { types, SnapshotIn } from 'mobx-state-tree';

const ObjectContextMenu = types.model({
  objectId: types.string,
  x: types.number,
  y: types.number,
});

export type ObjectContextMenuSnapshotIn = SnapshotIn<typeof ObjectContextMenu>;

export default ObjectContextMenu;

import * as paper from 'paper';
import { Layers, objectZStack } from 'features/editor/types';

/**
 * Activates the first layer that matches the provided layer name
 *
 * @param layerName
 */
export const activateLayer = (layerName: string) => {
  paper.project.layers.find((layer) => layer.name === layerName)?.activate();
};

export const initializeLayerStack = () => {
  Object.values(Layers).forEach((layer) => {
    const l = new paper.Layer();
    l.name = `_${layer}_`;
  });
};

/**
 * Get the root layer
 *
 */
export const getRootLayer = () => {
  return paper.project.layers.find((layer) => layer.name === Layers.root);
};

/**
 * Gets the first layer that matches the provided layer name
 *
 * @param layerName
 */
export const getLayerByName = (layerName: string) => {
  return paper.project?.layers?.find((layer) => layer.name === layerName);
};

export const initializeDrawingLayer = () => {
  // Clear the active drawing layer
  getLayerByName(Layers.activeDrawing)?.remove();

  const activeDrawingLayer = new paper.Layer();
  activeDrawingLayer.name = Layers.activeDrawing;

  return activeDrawingLayer;
};

const createObjectStackMarkers = () => {
  // initialize stack position markers for each object type
  // (used to insert objects at the correct z index on creation)
  let prevMarker: paper.Item | undefined;
  objectZStack.forEach((objType) => {
    const marker = new paper.Layer();
    marker.parent = getLayerByName(Layers.objects) as paper.Item;
    marker.name = `_${objType}_`;
    if (prevMarker) marker.insertBelow(prevMarker);
    prevMarker = marker;
  });
};

export const createOrActivateLayer = (
  layerName: string,
  options?: { removeChildren?: boolean }
) => {
  paper.project.activate();
  let layer = getLayerByName(layerName);

  if (layer) {
    layer.activate();
    if (options?.removeChildren) {
      layer.removeChildren();
      if (layerName === Layers.objects) {
        createObjectStackMarkers();
      }
    }
    return layer;
  }

  layer = new paper.Layer();
  layer.name = layerName;

  const stackPosition = getLayerByName(`_${layerName}_`);
  stackPosition && layer.insertBelow(stackPosition);
  layer.activate();

  if (layerName === Layers.objects) {
    createObjectStackMarkers();
  }

  return layer;
};

export const hideGrid = () => {
  const gridLayer = getLayerByName(Layers.grid);
  if (gridLayer) gridLayer.visible = false;
};

export const showGrid = () => {
  const gridLayer = getLayerByName(Layers.grid);
  if (gridLayer) gridLayer.visible = true;
};

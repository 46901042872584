import rootStore from 'state/models/Root';
import { getLayerByName, initializeDrawingLayer } from '../../Paper/Layers';
import { clearActiveSelection } from '../../Paper/MoveTool';

export const cleanupToolSideEffects = () => {
  rootStore.dimensions.clearActiveDrawingDimensions();
  rootStore.dimensions.clearDimensionLabels();
  rootStore.mouseTooltip.clearData();
  rootStore.activeDrawing.clearAll();
  const drawingLayer = initializeDrawingLayer();
  drawingLayer.remove();
  getLayerByName('HOVERED_CELL')?.removeChildren();
  clearActiveSelection();
};

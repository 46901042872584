export const TEXT_COLORS = [
  { label: 'Dark Gray', value: '#606060' },
  { label: 'Gray', value: '#909090' },
  { label: 'Blue', value: '#2c88d9' },
  { label: 'Red', value: '#d3455b' },
];

export const TEXT_SCALE = 2;

export const TEXT_SIZES = [
  { label: '12', value: 12 * TEXT_SCALE },
  { label: '16', value: 16 * TEXT_SCALE },
  { label: '20', value: 20 * TEXT_SCALE },
  { label: '24', value: 24 * TEXT_SCALE },
  { label: '32', value: 32 * TEXT_SCALE },
  { label: '36', value: 36 * TEXT_SCALE },
  { label: '40', value: 40 * TEXT_SCALE },
  { label: '48', value: 48 * TEXT_SCALE },
  { label: '64', value: 64 * TEXT_SCALE },
  { label: '96', value: 96 * TEXT_SCALE },
  { label: '128', value: 128 * TEXT_SCALE },
];

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 24px;
  font-size: 14px;

  .tier-display {
    font-weight: 500;
  }

  .tier-project-count {
    font-size: 13px;
    color: #666;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

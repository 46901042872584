import styled from 'styled-components';

interface WrapperProps {
  top: number;
  left: number;
  minWidth: number;
  minHeight: number;
}

export const Wrapper = styled.div<WrapperProps>`
  z-index: 999999;
  position: absolute;
  top: ${({ top }) => `${top - 16}px`};
  left: ${({ left }) => `${left - 16}px`};
  min-width: ${({ minWidth }) => `${minWidth}px`};
  min-height: ${({ minHeight }) => `${minHeight}px`};
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #d0d0d0;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  button {
    margin-top: 16px;
    margin-right: 8px;
    padding: 6px 10px;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    cursor: pointer;

    :hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  #save-object-dimension {
    background: #008cea;
    border: none;
    color: #fff;
    font-weight: 600;

    :hover {
      background: #0074c2;
    }
  }
`;

interface DimensionHorizontalProps {
  height: number;
  offset: number;
}

interface DimensionInputWrapperProps {
  height: number;
}

export const DimensionHorizontal = styled.div<DimensionHorizontalProps>`
  height: ${({ height }) => `${height}px`};
  width: 16px;
  border-top: 1px solid #008cea;
  border-right: 1px solid #008cea;
  border-bottom: 1px solid #008cea;
  margin-top: ${({ offset }) => `${offset}px`};
`;

export const DimensionInputWrapper = styled.div<DimensionInputWrapperProps>`
  height: ${({ height }) => `${height}px`};
  display: flex;
  align-items: center;
  gap: 6px;
  color: rgba(0, 0, 0, 0.5);
`;

export const DimensionInput = styled.input`
  z-index: 99999;
  width: 50px;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  padding: 4px 8px;
  outline-color: #008cea;

  :disabled {
    border-color: rgba(0, 0, 0, 0.1);
  }
`;

interface DimensionVerticalProps {
  width: number;
  offset: number;
}

interface DimensionWidthInputWrapperProps {
  width: number;
}

export const DimensionWidthInputWrapper = styled.div<DimensionWidthInputWrapperProps>`
  min-width: ${({ width }) => `${width}px`};
  margin-top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: rgba(0, 0, 0, 0.5);
`;

export const DimensionVertical = styled.div<DimensionVerticalProps>`
  width: ${({ width }) => `${width}px`};
  height: 16px;
  border-bottom: 1px solid #008cea;
  border-left: 1px solid #008cea;
  border-right: 1px solid #008cea;
  margin-top: 16px;
  margin-left: ${({ offset }) => `${offset}px`};
`;

interface PreviewWrapperProps {
  width?: number;
  height?: number;
}

export const PreviewWrapper = styled.div<PreviewWrapperProps>`
  height: ${({ height }) => (height ? `${height}px` : undefined)};
  width: ${({ width }) => (width ? `${width}px` : undefined)};
  position: relative;

  #preview-inner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const WarningMessage = styled.div`
  margin-top: 16px;
  width: 232px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
`;

import { useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { useAuth } from 'contexts/AuthContext';
import UserAvatarSvg from 'features/editor/icons/UserAvatarSvg';
import { useLocalStore } from 'state';
import {
  Button,
  ButtonGhost,
  Divider,
  SignInButton,
  UserDropdownWrapper,
  UserEmail,
  Wrapper,
} from './styles';
import { trackSignOut } from 'analytics/auth';

const getUserGradientStops = (uid: string) => {
  const digits = uid.match(/\d/);
  if (digits && digits[0]) {
    switch (Number(digits[0])) {
      case 0:
        return [
          { stopColor: '#12c2e9', offset: '0%' },
          { stopColor: '#c471ed', offset: '50%' },
          { stopColor: '#f7797d', offset: '100%' },
        ];
      case 1:
        return [
          { stopColor: '#00b6e8', offset: '0%' },
          { stopColor: '#209616', offset: '100%' },
        ];
      case 2:
        return [
          { stopColor: '#abb02d', offset: '0%' },
          { stopColor: '#3ad59f', offset: '100%' },
        ];
      case 3:
        return [
          { stopColor: '#f12711', offset: '0%' },
          { stopColor: '#f5af19', offset: '100%' },
        ];
      case 4:
        return [
          { stopColor: '#9ebd13', offset: '0%' },
          { stopColor: '#008552', offset: '100%' },
        ];
      case 5:
        return [
          { stopColor: '#FC466B', offset: '0%' },
          { stopColor: '#3F5EFB', offset: '100%' },
        ];
      case 6:
        return [
          { stopColor: '#FDBB2D', offset: '0%' },
          { stopColor: '#22C1C3', offset: '100%' },
        ];
      case 7:
        return [
          { stopColor: '#d6b4eb', offset: '0%' },
          { stopColor: '#515ada', offset: '100%' },
        ];
      case 8:
        return [
          { stopColor: '#9ebd13', offset: '0%' },
          { stopColor: '#008552', offset: '100%' },
        ];
      default:
        return [
          { stopColor: '#d53369', offset: '0%' },
          { stopColor: '#daae51', offset: '100%' },
        ];
    }
  }

  return [
    { stopColor: '#d53369', offset: '0%' },
    { stopColor: '#daae51', offset: '100%' },
  ];
};

export const UserAvatar = () => {
  const { currentUser, signout } = useAuth();
  const {
    ui: { setLoginModal },
  } = useLocalStore();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  return (
    <Wrapper>
      {currentUser?.uid ? (
        <ButtonGhost
          type="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <UserAvatarSvg stops={getUserGradientStops(currentUser.uid)} />
        </ButtonGhost>
      ) : (
        <SignInButton onClick={() => setLoginModal(true)}>Sign In</SignInButton>
      )}

      {dropdownOpen && currentUser && (
        <UserDropdownWrapper>
          <UserAvatarSvg
            stops={getUserGradientStops(currentUser.uid)}
            width={72}
            height={72}
          />
          <UserEmail>{currentUser.email}</UserEmail>
          <Divider />
          <Button
            onClick={() => {
              trackSignOut();
              signout();
              mixpanel.reset();
            }}
          >
            Log out
          </Button>
        </UserDropdownWrapper>
      )}
    </Wrapper>
  );
};

import { Point } from 'features/editor/types';
import { types, Instance } from 'mobx-state-tree';
import Objects, { ObjectModel } from '../Objects';

const DraggingObjects = types
  .model({
    data: types.maybeNull(Objects),
    downPoint: types.maybeNull(
      types.model({ x: types.number, y: types.number })
    ),
    createdAt: types.maybeNull(types.number),
  })
  .actions((self) => ({
    clear() {
      self.data = null;
      self.downPoint = null;
      self.createdAt = null;
    },
    addObject(object: ObjectModel, downPoint?: Point) {
      if (!self.data) {
        self.data = Objects.create({
          json: '{}',
        });
      }
      self.data.setObject(object);
      if (downPoint) {
        self.downPoint = downPoint;
      }
      self.createdAt = Date.now();
    },
  }));

export type DraggingObjects = Instance<typeof DraggingObjects>;

export default DraggingObjects;

import { observer } from 'mobx-react-lite';
import { useLocalStore } from 'state';
import RotationHandle from '../RotationHandle';

const RotationHandles = () => {
  const { selectedObjects, view } = useLocalStore();

  if (selectedObjects.isEmpty || view.isChanging) return null;

  return (
    <>
      {selectedObjects.ids.map((objectId) => (
        <RotationHandle key={objectId} objectId={objectId} />
      ))}
    </>
  );
};

export default observer(RotationHandles);

import { v4 as uuid } from 'uuid';
import { TEXT_COLORS } from 'features/editor/components/TextItem/plugins/ToolbarPlugin/constants';
import rootStore from 'state/models/Root';
import { getGridOffset } from '../Grid';
import { trackTextPlaced } from 'analytics/editor';
/**
 * Mousedown event handler when the move tool is selected
 *
 * @param _event
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const handleTextToolMouseDown = (event: paper.ToolEvent) => {
  const gridPoint = getGridOffset(event.downPoint);
  const newTextItem = {
    id: uuid(),
    x: gridPoint.x,
    y: gridPoint.y,
    fontSize: 48,
    color: TEXT_COLORS[0].value,
    data: undefined,
    textContent: undefined,
  };

  rootStore.overridedTextItems.setOverridedTextItem(newTextItem);
  trackTextPlaced();
};

import { Point } from 'features/editor/types';

/**
 * @param p1
 * @param p2
 * @returns the angle in degrees between the 2 points: 0=E, 90=S, 180=W, 270=N
 */
export const angleOf = (p1: Point, p2: Point) => {
  const deltaY = -(p1.y - p2.y);
  const deltaX = p2.x - p1.x;
  const result = (Math.atan2(deltaY, deltaX) * 180) / Math.PI;
  return Math.abs(result < 0 ? 360 + result : result);
};

/**
 * @param p1
 * @param p2
 * @returns the distance between the 2 points
 */
export const distanceOf = (p1: Point, p2: Point) => {
  const deltaY = p1.y - p2.y;
  const deltaX = p2.x - p1.x;
  return Math.sqrt(deltaX ** 2 + deltaY ** 2);
};

export const getClosestNumber = (target: number, arr: number[]) => {
  return arr.reduce(function (prev, curr) {
    return Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev;
  });
};

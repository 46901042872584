import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Select from 'react-select';
import { v4 as uuid } from 'uuid';
import { ObjectData, ObjectSnap, ToolEnum } from 'features/editor/types';
import { useLocalStore } from 'state';
import ObjectPreview from 'features/editor/components/ObjectPreview';
import {
  InfoWrapper,
  ObjectTitle,
  PreviewWrapper,
  Wrapper,
  customSelectStyles,
  selectTheme,
  ClickArea,
  ObjectVariant,
} from './styles';
import { browserToPaperCoordinates } from 'features/editor/utils/Paper/View';
import { getSnappedPoint } from 'features/editor/utils/Grid';
import { ObjectModel } from 'state/models/Objects';
import { getGridOffset } from 'features/editor/utils/Paper/Grid';
import { getLayerByName } from 'features/editor/utils/Paper/Layers';
import { trackObjectPlaced } from 'analytics/editor';

interface ObjectPanelCardProps {
  objectMeta: ObjectData;
}

const MAX_PREVIEW_HEIGHT = 60;
const MAX_PREVIEW_WIDTH = 80;

const ObjectPanelCard = ({ objectMeta }: ObjectPanelCardProps) => {
  const { file, overridedObjects, draggingObjects, ui, toolbar, view } =
    useLocalStore();
  const [sizeOptions, setSizeOptions] = useState(
    file.sheet?.metric
      ? objectMeta.sizes.metric.map((opt) => ({
          label: opt.label,
          value: { height: opt.height, width: opt.width },
        }))
      : objectMeta.sizes.us.map((opt) => ({
          label: opt.label,
          value: { height: opt.height, width: opt.width },
        }))
  );
  const [addedHeight, setAddedHeight] = useState(0);
  const [sizeInput, setSizeInput] = useState(sizeOptions[0]);

  const defaultSize = file.sheet?.metric
    ? objectMeta.sizes.metric[0]
    : objectMeta.sizes.us[0];
  const maxWidthScale = MAX_PREVIEW_WIDTH / defaultSize.width;
  const maxHeightScale =
    MAX_PREVIEW_HEIGHT /
    ((defaultSize.height === 0
      ? (file.sheet?.cellSizePixels || 1) / (file.sheet?.PPU || 1)
      : defaultSize.height) +
      (objectMeta.wallOpening ? defaultSize.height / 5 : 0));
  const scale = Math.min(maxWidthScale, maxHeightScale);
  const previewHeight =
    (defaultSize.height === 0
      ? (file.sheet?.cellSizePixels || 1) / (file.sheet?.PPU || 1)
      : defaultSize.height) * scale;
  const previewWidth = defaultSize.width * scale;

  const snapDimension = (dimension: number, snapRule: ObjectSnap) => {
    const PPU = file.sheet?.PPU || 1;
    const wallThickness = (file.sheet?.cellSizePixels || 1) / PPU;
    let snappedDimension = dimension;
    if (snapRule === ObjectSnap.grid) {
      snappedDimension =
        Math.round((dimension * PPU) / (file.sheet?.cellSizePixels || 1)) *
        wallThickness;
    } else if (snapRule === ObjectSnap.halfGrid) {
      snappedDimension =
        Math.round(
          (dimension * PPU) / ((file.sheet?.cellSizePixels || 1) / 2)
        ) *
        (wallThickness / 2);
    }
    return snappedDimension;
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    toolbar.setCurrentTool(ToolEnum.move);
    view.setHoveredCellOutlineColor(null);
    view.setShowDistanceToWalls(false);
    getLayerByName('HOVERED_CELL')?.removeChildren();
    const height =
      sizeInput.value.height > 0
        ? snapDimension(sizeInput.value.height, objectMeta.snapY)
        : (file.sheet?.cellSizePixels || 1) / (file.sheet?.PPU || 1);
    const width = snapDimension(sizeInput.value.width, objectMeta.snapX);
    // use mouse event to get location
    const paperMousePosition = browserToPaperCoordinates({
      x: e.clientX,
      y: e.clientY,
    });
    const gridMousePosition = getGridOffset(paperMousePosition);
    const snappedPosition = getSnappedPoint(
      gridMousePosition,
      objectMeta.snapX,
      objectMeta.snapY,
      width * (file.sheet?.PPU || 1),
      height * (file.sheet?.PPU || 1),
      0
    );
    // create an overrided object with the proper data
    const obj: ObjectModel = {
      id: uuid(),
      objectKey: objectMeta.key,
      height,
      width,
      x: snappedPosition.x,
      y:
        snappedPosition.y -
        (objectMeta.wallOpening ? height * (file.sheet?.PPU || 1) : 0),
      rotation: 0,
      snapX: objectMeta.snapX,
      snapY: objectMeta.snapY,
      snapRotation: objectMeta.snapRotation,
    };
    overridedObjects.setOverridedObject(obj);

    const downPoint = browserToPaperCoordinates({
      x: e.clientX,
      y: e.clientY,
    });
    draggingObjects.addObject(obj, { x: downPoint.x, y: downPoint.y });
    // hide object panel
    ui.setObjectPanelOpen(false);
    trackObjectPlaced({
      objectKey: objectMeta.key,
      height,
      width,
    });
  };

  useEffect(() => {
    const opt = file.sheet?.metric
      ? objectMeta.sizes.metric.map((opt) => ({
          label: opt.label,
          value: { height: opt.height, width: opt.width },
        }))
      : objectMeta.sizes.us.map((opt) => ({
          label: opt.label,
          value: { height: opt.height, width: opt.width },
        }));
    setSizeOptions(opt);
    setSizeInput(opt[0]);
  }, [file.sheet?.metric, objectMeta.sizes.metric, objectMeta.sizes.us]);

  return (
    <Wrapper addedHeight={addedHeight}>
      <PreviewWrapper>
        <ClickArea onMouseDown={(e) => handleMouseDown(e)} />
        <ObjectPreview
          objectKey={objectMeta.key}
          height={previewHeight}
          width={previewWidth}
          showWall
        />
      </PreviewWrapper>
      <InfoWrapper>
        <ObjectTitle>{objectMeta.title}</ObjectTitle>
        <ObjectVariant>{objectMeta.variant || ''}</ObjectVariant>
        <Select
          value={sizeInput}
          onChange={(e) => {
            setSizeInput(e || { label: '', value: { height: 0, width: 0 } });
          }}
          onMenuOpen={() => setAddedHeight(380)}
          onMenuClose={() => setAddedHeight(0)}
          options={sizeOptions}
          styles={customSelectStyles}
          theme={selectTheme}
        />
      </InfoWrapper>
    </Wrapper>
  );
};

export default observer(ObjectPanelCard);

// import * as paper from 'paper';
import { CellPosition, NodeValue, Point } from 'features/editor/types';
import { readGraphFromString } from '../../Graphlib/Serialize';
import { getPathsFromWallGraph } from '../Walls';
import rootStore from 'state/models/Root';
import { getCellInfo, getEdgeDirection } from '../../Graphlib/Mutate';
import { getCellPosition } from '../../Grid';
import { getDimensionLabel } from '../../Unit';
import { distanceOf } from '../../Math';
import { paperToBrowserCoordinates } from '../View';

type SingleClientPoints = {
  start: Point;
  end: Point;
};

export const setDimension = (
  clientPoints: SingleClientPoints,
  label: string,
  clientWallThickness: number
) => {
  let offset = 24 / clientWallThickness;
  if (offset <= 0.5) offset = clientWallThickness * 0.5;
  else if (offset <= 1.5) offset = clientWallThickness;
  else if (offset <= 2.5) offset = clientWallThickness * 2;
  else offset = clientWallThickness * 3;

  rootStore.dimensions.setActiveDrawingDimensions(
    [
      {
        id: 'Dimension',
        start: clientPoints.start,
        center: {
          x: (clientPoints.start.x + clientPoints.end.x) / 2,
          y: (clientPoints.start.y + clientPoints.end.y) / 2,
        },
        end: clientPoints.end,
        label: label,
        offset: -offset,
      },
    ].filter((d) => d.end.x !== d.start.x || d.end.y !== d.start.y)
  );
};

type ClientPoints = {
  vStart: Point;
  vEnd: Point;
  wStart: Point;
  wEnd: Point;
};

export const setDimensions = (
  clientPoints: ClientPoints,
  vLabel: string,
  wLabel: string
) => {
  const clientWallThickness = Math.max(
    clientPoints.wStart.x - clientPoints.vEnd.x,
    clientPoints.wStart.y - clientPoints.vEnd.y
  );

  let offset = 24 / clientWallThickness;
  if (offset <= 0.5) offset = clientWallThickness * 0.5;
  else if (offset <= 1.5) offset = clientWallThickness;
  else if (offset <= 2.5) offset = clientWallThickness * 2;
  else offset = clientWallThickness * 3;

  rootStore.dimensions.setActiveDrawingDimensions(
    [
      {
        id: 'V',
        start: clientPoints.vStart,
        center: {
          x: (clientPoints.vStart.x + clientPoints.vEnd.x) / 2,
          y: (clientPoints.vStart.y + clientPoints.vEnd.y) / 2,
        },
        end: clientPoints.vEnd,
        label: vLabel,
        offset: -offset,
      },
      {
        id: 'W',
        start: clientPoints.wStart,
        center: {
          x: (clientPoints.wStart.x + clientPoints.wEnd.x) / 2,
          y: (clientPoints.wStart.y + clientPoints.wEnd.y) / 2,
        },
        end: clientPoints.wEnd,
        label: wLabel,
        offset: -offset,
      },
    ].filter((d) => d.end.x !== d.start.x || d.end.y !== d.start.y)
  );
};

export const showDistanceToWalls = (cell: CellPosition) => {
  const g = rootStore.file.undoable.walls.json;
  if (typeof g === 'string') {
    const graph = readGraphFromString(g);
    const paths = getPathsFromWallGraph(graph);
    const cellInfo = getCellInfo(cell, graph, paths);
    if (cellInfo.edge) {
      const v = graph.node(cellInfo.edge.v) as NodeValue;
      const w = graph.node(cellInfo.edge.w) as NodeValue;
      const vTotalEdges = graph.nodeEdges(cellInfo.edge.v)?.length || 0;
      const wTotalEdges = graph.nodeEdges(cellInfo.edge.w)?.length || 0;
      let vPosition = getCellPosition(v);
      let wPosition = getCellPosition(w);
      if (!vPosition || !wPosition) return;
      if (vPosition.row + vPosition.column > wPosition.row + wPosition.column) {
        const temp = vPosition;
        vPosition = wPosition;
        wPosition = temp;
      }
      const isMetric = !!rootStore.file.sheet?.metric;
      const PPU = rootStore.file.sheet?.PPU || 1;
      if (getEdgeDirection(v, w) === 'HORIZONTAL') {
        const vPoint = vTotalEdges > 1 ? vPosition.topRight : vPosition.topLeft;
        const wPoint = wTotalEdges > 1 ? wPosition.topLeft : wPosition.topRight;
        const vLabel = getDimensionLabel(
          distanceOf(vPoint, cell.topLeft) / PPU,
          isMetric
        );
        const wLabel = getDimensionLabel(
          distanceOf(cell.topRight, wPoint) / PPU,
          isMetric
        );
        const clientPoints = {
          vStart: paperToBrowserCoordinates(vPoint),
          vEnd: paperToBrowserCoordinates(cell.topLeft),
          wStart: paperToBrowserCoordinates(cell.topRight),
          wEnd: paperToBrowserCoordinates(wPoint),
        };
        setDimensions(clientPoints, vLabel, wLabel);
      } else {
        const vPoint =
          vTotalEdges > 1 ? vPosition.bottomLeft : vPosition.topLeft;
        const wPoint =
          wTotalEdges > 1 ? wPosition.topLeft : wPosition.bottomLeft;
        const vLabel = getDimensionLabel(
          distanceOf(vPoint, cell.topLeft) / PPU,
          isMetric
        );
        const wLabel = getDimensionLabel(
          distanceOf(cell.bottomLeft, wPoint) / PPU,
          isMetric
        );
        const clientPoints = {
          vStart: paperToBrowserCoordinates(vPoint),
          vEnd: paperToBrowserCoordinates(cell.topLeft),
          wStart: paperToBrowserCoordinates(cell.bottomLeft),
          wEnd: paperToBrowserCoordinates(wPoint),
        };
        setDimensions(clientPoints, vLabel, wLabel);
      }
    } else if (cellInfo.node) {
      // do nothing
    } else {
      // console.log('empty');
    }
  }
};

import { SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { drawSheet } from 'features/editor/utils/Paper/DrawSheet';
import { handleWallStateChanges } from 'features/editor/utils/Paper/Walls';
import { handleObjectStateChanges } from 'features/editor/utils/Paper/Objects';
import { SaveStatus } from 'state/types';
import Sheet from '../Sheet';
import Undoable from '../Undoable';
import rootStore from '../Root';

const File = types.model({
  sheet: types.maybeNull(Sheet),
  undoable: Undoable,
});

export type FileSnapshotIn = SnapshotIn<typeof File>;
export type FileSnapshotOut = SnapshotOut<typeof File>;

const previousValues = {
  sheet: null,
  walls: null,
  objects: null,
} as {
  sheet: string | null;
  walls: string | null;
  objects: string | null;
};

export const onFileSnapshot = (fileSnapshot: FileSnapshotOut) => {
  // SHEET
  const sheetSnapshot = fileSnapshot.sheet;
  if (previousValues.sheet) {
    if (previousValues.sheet !== JSON.stringify(sheetSnapshot)) {
      sheetSnapshot && drawSheet(sheetSnapshot);
      previousValues.sheet = sheetSnapshot
        ? JSON.stringify(sheetSnapshot)
        : null;
      rootStore.setSaveStatus(SaveStatus.unsaved);
    }
  } else {
    if (sheetSnapshot) {
      drawSheet(sheetSnapshot);
      previousValues.sheet = JSON.stringify(sheetSnapshot);
      rootStore.setSaveStatus(SaveStatus.unsaved);
    }
  }

  // WALLS
  const wallsSnapshot = fileSnapshot.undoable.walls;
  if (previousValues.walls) {
    if (previousValues.walls !== JSON.stringify(wallsSnapshot)) {
      handleWallStateChanges(wallsSnapshot);
      previousValues.walls = wallsSnapshot
        ? JSON.stringify(wallsSnapshot)
        : null;
      rootStore.setSaveStatus(SaveStatus.unsaved);
    }
  } else {
    handleWallStateChanges(wallsSnapshot);
    previousValues.walls = JSON.stringify(wallsSnapshot);
    rootStore.setSaveStatus(SaveStatus.unsaved);
  }

  // OBJECTS
  const objectsSnapshot = fileSnapshot.undoable.objects;
  if (previousValues.objects) {
    if (previousValues.objects !== JSON.stringify(objectsSnapshot)) {
      handleObjectStateChanges(objectsSnapshot);
      previousValues.objects = objectsSnapshot
        ? JSON.stringify(objectsSnapshot)
        : null;
      rootStore.setSaveStatus(SaveStatus.unsaved);
    }
  } else {
    handleObjectStateChanges(objectsSnapshot);
    previousValues.objects = JSON.stringify(objectsSnapshot);
    rootStore.setSaveStatus(SaveStatus.unsaved);
  }
};

export default File;

import { useState, useEffect } from 'react';
import { TooltipContent, TooltipKeyShortcut, TooltipWrapper } from './styles';
import './styles.ts';

type TooltipProps = {
  content: string;
  keyShortcut?: string;
  delay?: number;
  direction?: 'top' | 'bottom' | 'left' | 'right';
  offsetX?: number;
  disabled?: boolean;
};

const Tooltip: React.FC<TooltipProps> = (props) => {
  let timeout: NodeJS.Timeout;
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (props.disabled && active) {
      setActive(false);
    }
  }, [active, props.disabled]);

  const showTip = () => {
    timeout = setTimeout(() => {
      if (!props.disabled) setActive(true);
    }, props.delay || 0);
  };

  const hideTip = () => {
    timeout && clearInterval(timeout);
    setActive(false);
  };

  return (
    <TooltipWrapper
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
      offsetX={props.offsetX}
    >
      {props.children}
      {active && (
        <div className={`Tooltip-Tip ${props.direction || 'top'}`}>
          <TooltipContent>{props.content}</TooltipContent>
          {!!props.keyShortcut && (
            <TooltipKeyShortcut>{props.keyShortcut}</TooltipKeyShortcut>
          )}
        </div>
      )}
    </TooltipWrapper>
  );
};

export default Tooltip;

import DotIcon from 'features/editor/icons/DotIcon';
import { fitToScreen, setZoom } from 'features/editor/utils/Paper/View';
import { useLocalStore } from 'state';
import { Divider, MenuOption, Wrapper } from './styles';

type ZoomMenuProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const options = [
  { label: '25%', value: 0.25 },
  { label: '50%', value: 0.5 },
  { label: '75%', value: 0.75 },
  { label: '100%', value: 1 },
  { label: '125%', value: 1.25 },
];

const ZoomMenu = ({ isOpen, setIsOpen }: ZoomMenuProps) => {
  const {
    view: { zoom },
  } = useLocalStore();

  const handleZoomClick = (value: number) => {
    setZoom(value);
  };

  return (
    <Wrapper isOpen={isOpen}>
      <MenuOption
        type="button"
        onClick={() => {
          fitToScreen();
          setIsOpen(false);
        }}
        isActive={false}
      >
        <DotIcon />
        Fit to screen
      </MenuOption>
      <Divider />
      {options.map((option) => (
        <MenuOption
          type="button"
          key={option.label}
          onClick={() => handleZoomClick(option.value)}
          isActive={zoom === option.value}
        >
          <DotIcon />
          {option.label}
        </MenuOption>
      ))}
    </Wrapper>
  );
};

export default ZoomMenu;

import { types, SnapshotOut } from 'mobx-state-tree';
import { trackObjectDeleted } from 'analytics/editor';
import { v4 as uuid } from 'uuid';

export type TextItemModel = {
  id: string;
  x: number;
  y: number;
  fontSize: number;
  color: string;
  data: string | undefined;
  textContent: string | undefined;
};

export type TextItemMap = Record<string, TextItemModel>;

const TextItems = types
  .model({ json: types.string })
  .actions((self) => ({
    setTextItem(textItem: TextItemModel) {
      if (textItem) {
        const newJson = JSON.parse(self.json);
        newJson[textItem.id] = textItem;
        self.json = JSON.stringify(newJson);
      }
    },
    deleteTextItem(id: string) {
      const newJson = JSON.parse(self.json);
      delete newJson[id];
      self.json = JSON.stringify(newJson);
      trackObjectDeleted();
    },
    duplicateTextItem(id: string, offset?: number, offsetX?: number) {
      const newJson = JSON.parse(self.json);
      const target = newJson[id];
      const duplicateId = uuid();
      newJson[duplicateId] = {
        ...target,
        id: duplicateId,
        x: target.x + (offsetX !== undefined ? offsetX : offset || 0),
        y: target.y + (offset || 0),
      };
      self.json = JSON.stringify(newJson);
      return duplicateId;
    },
    deleteMultiple(ids: string[]) {
      const newJson = JSON.parse(self.json);
      ids.forEach((id) => {
        delete newJson[id];
      });
      self.json = JSON.stringify(newJson);
      // trackObjectDeleted({
      //   multiple: true,
      // });
    },
  }))
  .views((self) => ({
    getTextItemData(id: string) {
      return (JSON.parse(self.json) as TextItemMap)[id] || null;
    },
  }));

export type TextItemsSnapshotOut = SnapshotOut<typeof TextItems>;

export default TextItems;

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocalStore } from 'state';
import {
  CloseButton,
  Divider,
  FormError,
  LoginLink,
  StyledButton,
  StyledForm,
  StyledFormInput,
  StyledFormSubmit,
  StyledModalBackdrop,
  StyledModalContainer,
  Subtitle,
  Title,
} from './styles';
import { useAuth } from 'contexts/AuthContext';
import CloseIcon from 'features/editor/icons/CloseIcon';
import { SaveStatus } from 'state/types';
import {
  trackContinueAsGuest,
  trackContinueWithGoogle,
  trackSignUp,
  trackSignUpFailed,
} from 'analytics/auth';

const WelcomeModal = () => {
  const { signup, continueWithGoogle } = useAuth();
  const {
    saveStatus,
    ui: {
      welcomeModalOpen: isOpen,
      saveOnAuthComplete,
      setSaveOnAuthComplete,
      setWelcomeModal,
      setCreateFileModal,
      setLoginModal,
    },
  } = useLocalStore();
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [error, setError] = useState('');
  const [timeoutComplete, setTimeoutComplete] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTimeoutComplete(true);
    }, 1000);
  }, []);

  const handleSignup = async () => {
    if (!emailInput.length || !passwordInput.length) {
      setError('Please enter an email and password');
      return;
    }

    try {
      await signup(emailInput, passwordInput);
      closeModal({ openNewFileModal: saveStatus === SaveStatus.no_file });
      trackSignUp({
        email: emailInput,
      });
    } catch (error: any) {
      switch (error.code) {
        case 'auth/email-already-exists':
        case 'auth/email-already-in-use':
          setError('The email you provided is already in use');
          break;
        case 'auth/invalid-email':
          setError('Please enter a valid email address');
          break;
        case 'auth/invalid-password':
          setError('Wrong password');
          break;
        case 'auth/user-not-found':
          setError('Email not found');
          break;
        case 'auth/weak-password':
          setError('Password must be at least 6 characters');
          break;
        default:
          setError('Unable to sign up');
          break;
      }

      trackSignUpFailed({ errorCode: error.code });
    }
  };

  const handleContinueWithGoogle = async () => {
    await continueWithGoogle();
    trackContinueWithGoogle();
    closeModal();
  };

  const closeModal = (options?: {
    openNewFileModal?: boolean;
    openLoginModal?: boolean;
  }) => {
    if (options?.openNewFileModal) {
      setCreateFileModal(true);
    }
    if (options?.openLoginModal) {
      setLoginModal(true);
    } else if (saveOnAuthComplete) {
      setSaveOnAuthComplete(false);
    }
    setError('');
    setEmailInput('');
    setPasswordInput('');
    setWelcomeModal(false);
  };

  if (!isOpen || !timeoutComplete) return null;

  return (
    <StyledModalBackdrop>
      <StyledModalContainer>
        <CloseButton
          type="button"
          onClick={() => {
            closeModal({ openNewFileModal: saveStatus === SaveStatus.no_file });
            trackContinueAsGuest();
          }}
        >
          <CloseIcon />
        </CloseButton>
        <img src="/gradient-logo-round.png" />
        <Title>Welcome to Floor Plan Lab</Title>
        <Subtitle>Design and save floor plans in minutes.</Subtitle>
        <Subtitle>Sign up for a FREE account.</Subtitle>
        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
            handleSignup();
          }}
        >
          <StyledFormInput
            name="email"
            placeholder="Email address..."
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
          />
          <StyledFormInput
            name="password"
            type="password"
            placeholder="Password"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
          />
          {!!error.length && <FormError>{error}</FormError>}
          <StyledFormSubmit type="submit">Start Designing</StyledFormSubmit>
          <LoginLink>
            {'Already have an account? '}{' '}
            <a
              role="link"
              tabIndex={0}
              onClick={() => closeModal({ openLoginModal: true })}
              onKeyDown={() => {}}
            >
              Sign in
            </a>
          </LoginLink>
        </StyledForm>

        <Divider>
          <span>OR</span>
        </Divider>

        <StyledButton type="button" onClick={handleContinueWithGoogle}>
          <img src="/google-logo.png" />
          Continue with Google
        </StyledButton>
        <StyledButton
          style={{ fontWeight: 400 }}
          type="button"
          onClick={() => {
            closeModal({ openNewFileModal: true });
            trackContinueAsGuest();
          }}
        >
          Continue without saving
        </StyledButton>
      </StyledModalContainer>
    </StyledModalBackdrop>
  );
};

export default observer(WelcomeModal);

import { observer } from 'mobx-react-lite';
import PanIcon from 'features/editor/icons/PanIcon';
import { useLocalStore } from 'state';
import { Wrapper, ToggleButton } from './styles';
import Tooltip from 'ui/components/Tooltip';

const PanModeToggle = () => {
  const { activeProject, view, ui } = useLocalStore();
  return (
    <>
      {activeProject ? (
        <Wrapper shiftX={ui.objectPanelOpen ? ui.objectPanelWidth : 0}>
          <Tooltip content="Pan" direction="top" keyShortcut="Space">
            <ToggleButton
              type="button"
              onClick={() =>
                view.panMode ? view.disablePan() : view.enablePan()
              }
            >
              <PanIcon
                width={18}
                height={18}
                color={view.panMode ? '#008cea' : '#666'}
              />
            </ToggleButton>
          </Tooltip>
        </Wrapper>
      ) : null}
    </>
  );
};

export default observer(PanModeToggle);

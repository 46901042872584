import styled from 'styled-components';

interface WrapperProps {
  addedHeight: number;
}

export const Wrapper = styled.div<WrapperProps>`
  width: 132px;
  height: 156px;
  margin-bottom: ${({ addedHeight }) => `${addedHeight + 16}px`};
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
`;

export const ObjectCardSkeleton = styled.div`
  width: 132px;
  height: 156px;
  margin-bottom: 16px;
  background: #f8f8f8;
  background: linear-gradient(
    30deg,
    rgba(243, 243, 243, 1) 0%,
    rgba(250, 250, 250, 1) 70%
  );
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-weight: 300;
    font-size: 10px;
    color: #888;
  }
`;

export const PreviewWrapper = styled.div`
  width: 100%;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ClickArea = styled.div`
  position: absolute;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: grab;
  :hover {
    border: 2px solid #008cea;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    opacity: 0.8;
  }
  :active {
    cursor: grabbing;
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  height: calc(100% - 84px);
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  background: rgba(0, 0, 0, 0.05);
  background: linear-gradient(
    20deg,
    rgba(242, 242, 242, 1) 0%,
    rgba(250, 250, 250, 1) 70%
  );
  display: flex;
  flex-direction: column;
`;

export const ObjectTitle = styled.div`
  width: 100%;
  padding: 4px 8px 0px 8px;
  font-size: 13px;
  font-weight: 500;
`;

export const ObjectVariant = styled.div`
  width: 100%;
  height: 16px;
  padding: 1px 0px 1px 10px;
  font-size: 11px;
  color: #555;
`;

export const customSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderRadius: 2,
    backgroundColor: '#fff',
    borderColor: state.isSelected ? '#008cea' : 'rgba(0,0,0,0.1)',
    borderWidth: '1px !important',
    outline: 'none',
    marginLeft: 4,
    width: 'calc(100% - 8px)',
    maxHeight: 28,
    minHeight: 28,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    fontSize: '12px',
    padding: '0 4px',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: () => ({
    marginRight: 2,
    color: '#a0a0a0',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#111',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#fff',
    marginTop: 16,
    boxShadow: 'none',
    border: '1px solid rgba(0,0,0,0.15)',
    maxHeight: 380,
  }),
  option: (provided: any, { isSelected }: any) => ({
    ...provided,
    background: isSelected ? '#008cea' : '#fff',
    color: isSelected ? '#fff' : '#333',
    fontWeight: isSelected ? 600 : 400,
    cursor: 'pointer',
    fontSize: '12px',
    '&:hover': {
      background: isSelected ? '#008cea' : 'rgba(0,0,0,0.05)',
    },
  }),
};

export const selectTheme = (providedTheme: any) => ({
  ...providedTheme,
  colors: {
    ...providedTheme.colors,
    primary: '#e0e0e0',
    primary50: '#eeeeee',
  },
});

import { Cell } from 'features/editor/types';
import { types, Instance } from 'mobx-state-tree';

const ActiveDrawing = types
  .model({
    penDown: types.boolean,
    eraserDown: types.boolean,
    continuousDrag: types.boolean,
    penTrail: types.map(
      types.model({
        row: types.number,
        column: types.number,
      })
    ),
  })
  .actions((self) => ({
    setPenDown(value: boolean) {
      self.penDown = value;
    },
    setEraserDown(value: boolean) {
      self.eraserDown = value;
    },
    addNodeToPenTrail(nodeId: string, cell: Cell) {
      self.penTrail.set(nodeId, cell);
    },
    clearPenTrail() {
      self.penTrail.clear();
    },
    setContinuousDrag(value: boolean) {
      self.continuousDrag = value;
    },
    clearAll() {
      self.penDown = false;
      self.eraserDown = false;
      self.continuousDrag = false;
      self.penTrail.clear();
    },
  }));

export type ActiveDrawingInstance = Instance<typeof ActiveDrawing>;

export default ActiveDrawing;

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  min-height: 742px;
  width: 100%;
  margin-top: 32px;

  h1 {
    width: 258px;
    font-weight: normal;
    font-size: 36px;
    line-height: 132%;
    /* or 48px */
    text-align: center;
    letter-spacing: 1px;
    color: #ffffff;
  }

  h3 {
    margin: 0 0 0 10px;
    font-weight: 300;
    font-size: 24px;
    line-height: 90%;
    /* or 16px */
    text-align: center;
    letter-spacing: 1px;
    color: #222;
  }

  h4 {
    margin: 8px 0;
    font-weight: normal;
    font-size: 18px;
    line-height: 132%;
    /* or 24px */
    text-align: center;
    letter-spacing: 1px;
    color: #ffffff;
  }

  span {
    max-width: calc(100% - 64px);

    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 132%;
    /* or 21px */
    text-align: center;
    letter-spacing: 0px;
    color: #777;
  }

  .copyright {
    position: absolute;
    bottom: 24px;
    z-index: 2;

    font-weight: normal;
    font-size: 14px;
    line-height: 132%;
    /* or 18px */
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
  }
`;

export const Gradient = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 380px;
  width: 100%;
  padding: 60px 0 0 0;
  background: linear-gradient(
    219.86deg,
    rgba(0, 194, 207, 0.7) 6.41%,
    rgba(0, 123, 234, 0.7) 49.12%,
    rgba(98, 44, 191, 0.7) 90.96%
  );
`;

export const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Row = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
`;

export const GraphicWrapper = styled.div`
  position: absolute;
  bottom: 380px;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
`;

import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Vanities = <ObjectMap>{
  //
  // Vanities
  //
  BATHROOM_VANITY: {
    key: 'BATHROOM_VANITY',
    title: 'Bathroom Vanity',
    variant: 'small',
    type: ObjectType.vanity,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 16", d: 16"', width: 16, height: 16 },
        { label: 'w: 24", d: 16"', width: 24, height: 16 },
        { label: 'w: 32", d: 16"', width: 32, height: 16 },
      ],
      metric: [
        { label: 'w: 406mm, d: 406mm', width: 406, height: 406 },
        { label: 'w: 610mm, d: 406mm', width: 610, height: 406 },
        { label: 'w: 813mm, d: 406mm', width: 813, height: 406 },
      ],
    },
  },
  BATHROOM_VANITY_MEDIUM: {
    key: 'BATHROOM_VANITY_MEDIUM',
    title: 'Bathroom Vanity',
    variant: 'medium',
    type: ObjectType.vanity,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 24", d: 19"', width: 24, height: 19 },
        { label: 'w: 32", d: 19"', width: 32, height: 19 },
        { label: 'w: 36", d: 19"', width: 36, height: 19 },
        { label: 'w: 40", d: 19"', width: 40, height: 19 },
      ],
      metric: [
        { label: 'w: 610mm, d: 483mm', width: 610, height: 483 },
        { label: 'w: 813mm, d: 483mm', width: 813, height: 483 },
        { label: 'w: 914mm, d: 483mm', width: 914, height: 483 },
        { label: 'w: 1016mm, d: 483mm', width: 1016, height: 483 },
      ],
    },
  },
  BATHROOM_VANITY_LARGE: {
    key: 'BATHROOM_VANITY_LARGE',
    title: 'Bathroom Vanity',
    variant: 'large',
    type: ObjectType.vanity,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.halfGrid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 48", d: 19"', width: 48, height: 19 },
        { label: 'w: 56", d: 19"', width: 56, height: 19 },
        { label: 'w: 72", d: 19"', width: 72, height: 19 },
      ],
      metric: [
        { label: 'w: 1219mm, d: 483mm', width: 1219, height: 483 },
        { label: 'w: 1422mm, d: 483mm', width: 1422, height: 483 },
        { label: 'w: 1829mm, d: 483mm', width: 1829, height: 483 },
      ],
    },
  },
};

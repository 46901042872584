import styled from 'styled-components';
import { APP_BAR_HEIGHT_PX } from 'features/editor/config';

interface ViewContainerProps {
  cursor?: string;
}

export const StyledViewContainer = styled.div<ViewContainerProps>`
  position: fixed;
  width: 100%;
  height: ${`calc(100vh - ${APP_BAR_HEIGHT_PX}px)`};
  background: #f0f0f0;
  z-index: 1;

  ${({ cursor }) => cursor && `cursor: ${cursor}`};
`;

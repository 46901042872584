import { types } from 'mobx-state-tree';
import { ObjectMap, ObjectModel } from '../Objects';

const OverridedObjects = types
  .model({ json: types.string })
  .actions((self) => ({
    setOverridedObject(object: ObjectModel) {
      const newJson = JSON.parse(self.json);
      newJson[object.id] = object;
      self.json = JSON.stringify(newJson);
    },
    clear() {
      self.json = '{}';
    },
  }))
  .views((self) => ({
    getObjectData(id: string) {
      return (JSON.parse(self.json) as ObjectMap)[id] || null;
    },
  }));

export default OverridedObjects;

import { types, Instance } from 'mobx-state-tree';

const Theme = types.model({
  wallColor: types.string,
  wallColor_uncommited: types.string,
  roomFillColor: types.string,
  selectedObjectColor: types.string,
  dimensionColor: types.string,
  mouseTooltipColor: types.string,
});
// .actions((self) => ({
//   toggleLeftPanel() {
//     self.leftPanelOpen = !self.leftPanelOpen;
//   },
// }));

export type ThemeInstance = Instance<typeof Theme>;

export default Theme;

function isMacintosh() {
  return (
    navigator?.platform?.indexOf('Mac') > -1 ||
    navigator?.appVersion?.indexOf('Mac') > -1
  );
}

export const getMetaKeyCharacter = (options?: { shift?: boolean }) => {
  if (isMacintosh()) return options?.shift ? '⌘⇧' : '⌘';

  return options?.shift ? 'Ctrl+Shift+' : 'Ctrl+';
};

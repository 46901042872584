import { ToolEnum } from 'features/editor/types';
import rootStore from 'state/models/Root';

export const setCursorOnToolChange = (currentTool: ToolEnum) => {
  switch (currentTool) {
    case ToolEnum.pen:
    case ToolEnum.erase:
    case ToolEnum.room:
    case ToolEnum.wall:
    case ToolEnum.measure:
    case ToolEnum.text:
      rootStore.view.setCursor('crosshair');
      break;

    default:
      rootStore.view.setCursor('default');
      break;
  }
};

import rootStore from 'state/models/Root';
import ObjectMetadata from 'objects/metadata';

export const handleObjectDuplicate = (
  objectId: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _objectType?: string
) => {
  const offset = rootStore.file.sheet?.cellSizePixels || 0;
  const duplicateId = rootStore.file.undoable.objects.duplicateObject(
    objectId,
    offset
  );
  rootStore.closeObjectContextMenu();
  rootStore.selectedObjects.clear();
  rootStore.selectedObjects.addId(duplicateId);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const handleObjectDelete = (objectId: string, _objectType: string) => {
  rootStore.file.undoable.objects.deleteObject(objectId);
  rootStore.closeObjectContextMenu();
};

export const handleObjectFlipHorizontal = (
  objectId: string,
  skipCheck?: boolean
) => {
  const data = { ...rootStore.file.undoable.objects.getObjectData(objectId) };
  if (!skipCheck && (data.rotation === 90 || data.rotation === 270)) {
    handleObjectFlipVertical(objectId, true);
    return;
  }
  data.flippedHorizontal = data.flippedHorizontal
    ? !data.flippedHorizontal
    : true;
  rootStore.file.undoable.objects.setObject(data);
  rootStore.closeObjectContextMenu();
};

export const handleObjectFlipVertical = (
  objectId: string,
  skipCheck?: boolean
) => {
  const data = { ...rootStore.file.undoable.objects.getObjectData(objectId) };
  if (!skipCheck && (data.rotation === 90 || data.rotation === 270)) {
    handleObjectFlipHorizontal(objectId, true);
    return;
  }
  if (ObjectMetadata[data.objectKey].wallOpening) {
    // shift position to keep wall openings from moving
    const multiplier =
      (!data.flippedVertical &&
        (data.rotation === 0 || data.rotation === 270)) ||
      (data.flippedVertical && (data.rotation === 90 || data.rotation === 180))
        ? 1
        : -1;
    const shiftMagnitude = data.height * (rootStore.file.sheet?.PPU || 1);
    if (data.rotation === 0 || data.rotation === 180) {
      data.y += shiftMagnitude * multiplier;
    } else {
      data.x += shiftMagnitude * multiplier;
    }
  }

  data.flippedVertical = data.flippedVertical ? !data.flippedVertical : true;
  rootStore.file.undoable.objects.setObject(data);
  rootStore.closeObjectContextMenu();
};

// export const handleObjectChangeSnap = (objectId: string, snap: ObjectSnap) => {
//   const data = { ...rootStore.file.undoable.objects.getObjectData(objectId) };
//   const position = getSnappedPoint(
//     new paper.Point(data.x, data.y),
//     snap,
//     snap,
//     data.width,
//     data.height
//   );
//   data.x = position.x;
//   data.y = position.y;
// 	data.snapX

//   rootStore.file.undoable.objects.setObject(data);
//   rootStore.closeObjectContextMenu();
// };

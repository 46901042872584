import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLocalStore } from 'state';
import {
  CloseButton,
  Divider,
  FormError,
  LoginLink,
  StyledButton,
  StyledForm,
  StyledFormInput,
  StyledFormSubmit,
  StyledModalBackdrop,
  StyledModalContainer,
  Title,
  ForgotPassword,
} from './styles';
import { useAuth } from 'contexts/AuthContext';
import CloseIcon from 'features/editor/icons/CloseIcon';
import { SaveStatus } from 'state/types';
import {
  trackContinueWithGoogle,
  trackSignIn,
  trackSignInFailed,
} from 'analytics/auth';

const LoginModal = () => {
  const { login, continueWithGoogle } = useAuth();
  const {
    saveStatus,
    ui: {
      loginModalOpen: isOpen,
      saveOnAuthComplete,
      setSaveOnAuthComplete,
      setLoginModal,
      setCreateFileModal,
      setResetPasswordModal,
      setWelcomeModal,
    },
  } = useLocalStore();
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async () => {
    if (!emailInput.length || !passwordInput.length) {
      setError('Please enter a valid email and password');
      return;
    }

    try {
      await login(emailInput, passwordInput);
      trackSignIn({
        email: emailInput,
      });
      closeModal();
    } catch (error: any) {
      switch (error.code) {
        case 'auth/invalid-email':
          setError('Please enter a valid email address');
          break;
        case 'auth/wrong-password':
          setError('The password you entered is incorrect');
          break;
        case 'auth/user-not-found':
          setError('Email not found');
          break;
        default:
          setError('Unable to sign in');
          break;
      }
      trackSignInFailed({ errorCode: error.code });
    }
  };

  const handleContinueWithGoogle = async () => {
    await continueWithGoogle();
    trackContinueWithGoogle();
    closeModal();
  };

  const closeModal = (options?: {
    openNewFileModal?: boolean;
    openWelcomeModal?: boolean;
    openResetPasswordModal?: boolean;
  }) => {
    if (options?.openNewFileModal) {
      setCreateFileModal(true);
    }
    if (options?.openResetPasswordModal) {
      setResetPasswordModal(true);
    }
    if (options?.openWelcomeModal) {
      setWelcomeModal(true);
    } else if (saveOnAuthComplete) {
      setSaveOnAuthComplete(false);
    }
    setError('');
    setEmailInput('');
    setPasswordInput('');
    setLoginModal(false);
  };

  if (!isOpen) return null;

  return (
    <StyledModalBackdrop>
      <StyledModalContainer>
        <CloseButton
          type="button"
          onClick={() =>
            closeModal({ openNewFileModal: saveStatus === SaveStatus.no_file })
          }
        >
          <CloseIcon />
        </CloseButton>
        {!saveOnAuthComplete ? (
          <Title>Welcome Back</Title>
        ) : (
          <Title>Sign in to save your project</Title>
        )}

        <StyledForm
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <StyledFormInput
            name="email"
            placeholder="Email address..."
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
          />
          <StyledFormInput
            name="password"
            type="password"
            placeholder="Password"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
          />
          <ForgotPassword
            onClick={() => closeModal({ openResetPasswordModal: true })}
          >
            Forgot password?
          </ForgotPassword>
          {!!error.length && <FormError>{error}</FormError>}
          <StyledFormSubmit type="submit">Sign In</StyledFormSubmit>
          <LoginLink>
            {"Don't have an account? "}
            <a
              role="link"
              tabIndex={0}
              onClick={() => closeModal({ openWelcomeModal: true })}
              onKeyDown={() => {}}
            >
              Sign up
            </a>
          </LoginLink>
        </StyledForm>

        <Divider>
          <span>OR</span>
        </Divider>

        <StyledButton type="button" onClick={handleContinueWithGoogle}>
          <img src="/google-logo.png" />
          Continue with Google
        </StyledButton>
        <StyledButton
          type="button"
          onClick={() => closeModal({ openNewFileModal: true })}
          style={{ fontWeight: 400 }}
        >
          Continue without saving
        </StyledButton>
      </StyledModalContainer>
    </StyledModalBackdrop>
  );
};

export default observer(LoginModal);

import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Chairs = <ObjectMap>{
  ARMCHAIR: {
    key: 'ARMCHAIR',
    title: 'Armchair',
    type: ObjectType.chair,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: 'w: 32", d: 32"', width: 32, height: 32 },
        { label: 'w: 35", d: 36"', width: 35, height: 36 },
      ],
      metric: [
        { label: 'w: 813mm, d: 813mm', width: 813, height: 813 },
        { label: 'w: 889mm, d: 914mm', width: 889, height: 914 },
      ],
    },
  },
  LOUNGE_CHAIR: {
    key: 'LOUNGE_CHAIR',
    title: 'Lounge Chair',
    type: ObjectType.chair,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [{ label: 'w: 33", d: 33"', width: 33, height: 33 }],
      metric: [{ label: 'w: 838mm, d: 838mm', width: 838, height: 838 }],
    },
  },
  OFFICE_CHAIR: {
    key: 'OFFICE_CHAIR',
    title: 'Office Chair',
    type: ObjectType.chair,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [{ label: 'w: 26", d: 26"', width: 26, height: 26 }],
      metric: [{ label: 'w: 660mm, d: 660mm', width: 660, height: 660 }],
    },
  },
};

import { types, SnapshotOut } from 'mobx-state-tree';

const Walls = types
  .model({
    json: types.string,
    isSelected: types.boolean,
  })
  .actions((self) => ({
    setWallJSON(s: string) {
      self.json = s;
    },
    setIsSelected(value: boolean) {
      self.isSelected = value;
    },
  }));

export type WallsSnapshotOut = SnapshotOut<typeof Walls>;

export default Walls;

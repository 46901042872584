const EmptyFolderGraphic = ({ width = 222, height = 199 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 222 199"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M220.945 79.9735L202.404 195.973C202.171 197.429 200.915 198.5 199.441 198.5H24L45.0631 78.9793C45.3158 77.5454 46.5616 76.5 48.0175 76.5H217.982C219.828 76.5 221.236 78.1509 220.945 79.9735Z"
      fill="#8CA7B6"
    />
    <path
      d="M20.4467 197.531L24 198.5L45.0631 78.9793C45.3158 77.5454 46.5616 76.5 48.0175 76.5H176.5L174.368 61.5757C174.157 60.0978 172.891 59 171.398 59H76.6056C75.6025 59 74.6658 58.4987 74.1094 57.6641L65.8906 45.3359C65.3342 44.5013 64.3975 44 63.3944 44H3.3846C1.58494 44 0.190259 45.5735 0.40629 47.3601L18.2578 194.997C18.4042 196.208 19.2697 197.21 20.4467 197.531Z"
      fill="#B8C1C2"
    />
    <circle cx="72.5" cy="11.5" r="3.5" fill="#C4C4C4" />
    <circle cx="132.5" cy="6.5" r="5" stroke="#C4C4C4" strokeWidth="3" />
    <circle cx="125.5" cy="40.5" r="3.5" fill="#C4C4C4" />
    <path
      d="M95.6536 22.1179L95.9448 19.4969C95.9762 19.214 96.2153 19 96.5 19C96.7847 19 97.0238 19.214 97.0552 19.4969L97.3213 21.8918C97.4255 22.8297 98.1711 23.5671 99.1101 23.661L101.966 23.9466C102.269 23.9769 102.5 24.2321 102.5 24.5368C102.5 24.809 102.315 25.0463 102.051 25.1123L99.6992 25.7002C98.9642 25.8839 98.3962 26.4671 98.2319 27.2066L97.6085 30.0119C97.5451 30.2971 97.2921 30.5 97 30.5C96.7079 30.5 96.4549 30.2971 96.3915 30.0119L95.8058 27.3759C95.6224 26.5508 94.9401 25.93 94.1014 25.8252L92.0266 25.5658C91.7257 25.5282 91.5 25.2725 91.5 24.9693C91.5 24.6935 91.6877 24.4531 91.9553 24.3862L94.1509 23.8373C94.9619 23.6345 95.5613 22.9487 95.6536 22.1179Z"
      fill="#C4C4C4"
    />
    <path
      d="M163.154 30.1179L163.445 27.4969C163.476 27.214 163.715 27 164 27C164.285 27 164.524 27.214 164.555 27.4969L164.821 29.8918C164.926 30.8297 165.671 31.5671 166.61 31.661L169.466 31.9466C169.769 31.9769 170 32.2321 170 32.5368C170 32.809 169.815 33.0463 169.551 33.1123L167.199 33.7002C166.464 33.8839 165.896 34.4671 165.732 35.2066L165.108 38.0119C165.045 38.2971 164.792 38.5 164.5 38.5C164.208 38.5 163.955 38.2971 163.892 38.0119L163.306 35.3759C163.122 34.5508 162.44 33.93 161.601 33.8252L159.527 33.5658C159.226 33.5282 159 33.2725 159 32.9693C159 32.6935 159.188 32.4531 159.455 32.3862L161.651 31.8373C162.462 31.6345 163.061 30.9487 163.154 30.1179Z"
      fill="#C4C4C4"
    />
  </svg>
);

export default EmptyFolderGraphic;

import { APP_BAR_HEIGHT_PX } from 'features/editor/config';
import styled from 'styled-components';

export const StyledNavbar = styled.div`
  width: 100%;
  height: ${`${APP_BAR_HEIGHT_PX}px`};
  background: ${({ theme }) => theme.appbar.bgColor};
  z-index: 999999;

  position: relative;
  display: grid;
  grid-template-columns: auto min-content;
`;

export const StyledNavbarLeft = styled.div`
  height: 100%;

  display: flex;
  flex-direction: row;
`;

export const StyledNavbarCenter = styled.div`
  position: absolute;
  top: 0;
  left: calc(50% - 110px);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledNavbarRight = styled.div`
  display: flex;
  flex-direction: row;
`;

interface NavbarButtonProps {
  active?: boolean;
  mainButton?: boolean;
  objectButton?: boolean;
}

export const StyledNavbarButton = styled.button<NavbarButtonProps>`
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  outline: 1px transparent;
  transition: background 0.1s;
  height: 100%;
  padding: 0 2px 0 0;
  width: ${({ objectButton }) =>
    objectButton ? `${86}px` : `${APP_BAR_HEIGHT_PX}px`};

  background: ${({ active, mainButton, objectButton }) => {
    if (mainButton || objectButton)
      return active ? 'rgba(255, 255, 255, 1)' : 'transparent';
    // Not main button
    return active ? 'rgb(0, 140, 234)' : 'transparent';
  }};

  :hover {
    background: ${({ active, mainButton, objectButton }) => {
      if (mainButton || objectButton)
        return active ? 'rgba(255, 255, 255, 1)' : 'rgb(0, 0, 0)';
      // Not main button
      return active ? 'rgb(0, 140, 234)' : 'rgb(0, 0, 0)';
    }};
  }
`;

export const NavButton = styled.button`
  cursor: pointer;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;

  outline: 1px transparent;
  padding: 0 12px;
  height: 34px;
  color: #e0e0e0;
  background: transparent;
  background: rgba(255, 255, 255, 0.08);
  margin-left: 16px;

  :hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const ObjectPanelLabel = styled.div`
  color: #fff;
  font-weight: 600;
  font-size: 14px;
`;

import { types, SnapshotOut } from 'mobx-state-tree';
import { ObjectSnap } from 'features/editor/types';
import { trackObjectDeleted } from 'analytics/editor';
import { v4 as uuid } from 'uuid';

export type ObjectModel = {
  id: string;
  objectKey: string;
  x: number;
  y: number;
  width: number;
  height: number;
  rotation: number;
  snapX: ObjectSnap;
  snapY: ObjectSnap;
  snapRotation: number;
  flippedHorizontal?: boolean;
  flippedVertical?: boolean;
};

export type ObjectMap = Record<string, ObjectModel>;

const Objects = types
  .model({ json: types.string })
  .actions((self) => ({
    setObject(object: ObjectModel) {
      if (object) {
        const newJson = JSON.parse(self.json);
        newJson[object.id] = object;
        self.json = JSON.stringify(newJson);
      }
    },
    deleteObject(id: string) {
      const newJson = JSON.parse(self.json);
      delete newJson[id];
      self.json = JSON.stringify(newJson);
      trackObjectDeleted();
    },
    duplicateObject(id: string, offset?: number) {
      const newJson = JSON.parse(self.json);
      const target = newJson[id];
      const duplicateId = uuid();
      newJson[duplicateId] = {
        ...target,
        id: duplicateId,
        x: target.x + (offset || 0),
        y: target.y + (offset || 0),
      };
      self.json = JSON.stringify(newJson);
      return duplicateId;
    },
    deleteMultiple(ids: string[]) {
      const newJson = JSON.parse(self.json);
      ids.forEach((id) => {
        delete newJson[id];
      });
      self.json = JSON.stringify(newJson);
      trackObjectDeleted({
        multiple: true,
      });
    },
  }))
  .views((self) => ({
    getObjectData(id: string) {
      return (JSON.parse(self.json) as ObjectMap)[id] || null;
    },
  }));

export type ObjectsSnapshotOut = SnapshotOut<typeof Objects>;

export default Objects;

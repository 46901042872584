import { types } from 'mobx-state-tree';
import Objects from '../Objects';
import TextItems from '../TextItems';
import Walls from '../Walls';

const Undoable = types.model({
  walls: Walls,
  objects: Objects,
  textItems: types.maybe(TextItems),
});

export default Undoable;

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RowHeader = styled.div`
  width: 100%;
  height: 38px;
  margin: 0 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
  span {
    margin-left: 8px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const ScrollLeftArrow = styled.div`
  z-index: 100;
  position: absolute;
  left: 0;
  top: 38px;
  width: 42px;
  height: 158px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 0) 100%
  );

  button {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #fff;
  }

  svg {
    margin-left: -7px;
    margin-top: -2px;
    color: #555;
  }
`;

export const ScrollRightArrow = styled.div`
  z-index: 100;
  position: absolute;
  right: 0;
  top: 38px;
  width: 42px;
  height: 158px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 70%,
    rgba(255, 255, 255, 0) 100%
  );

  button {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #fff;
  }

  svg {
    margin-left: -7px;
    margin-top: -2px;
    color: #555;
  }
`;

export const HorizontalScroll = styled.div`
  width: calc(100% - 48px);
  padding: 0 40px;
  overflow-x: scroll;

  div.inner {
    display: inline-flex;
    gap: 8px;
    padding-right: 40px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: none;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: none;
  }
`;

import * as paper from 'paper';
import { debounce } from 'throttle-debounce';
import RootStore from 'state/models/Root';
import { Layers, ToolEnum } from 'features/editor/types';
import {
  handleEraseToolDrag,
  handleEraseToolMouseDown,
  handleEraseToolMouseUp,
} from 'features/editor/utils/Paper/EraseTool';
import {
  handleWallToolDrag,
  handleWallToolMouseUp,
} from 'features/editor/utils/Paper/WallTool';
import {
  handlePenToolDrag,
  handlePenToolMouseDown,
  handlePenToolMouseUp,
} from 'features/editor/utils/Paper/PenTool';
import {
  handleRoomToolDrag,
  handleRoomToolMouseUp,
} from 'features/editor/utils/Paper/RoomTool';
import {
  handleMoveToolDrag,
  handleMoveToolMouseDown,
  handleMoveToolMouseUp,
} from '../MoveTool';
import { cellPositionFromPoint } from '../../Grid';
import { createOrActivateLayer, getLayerByName } from '../Layers';
import { showDistanceToWalls } from '../Dimensions';
import { drawHoveredCell } from '../HoveredCell';
import {
  handleTapeMeasureDrag,
  handleTapeMeasureMouseMove,
  handleTapeMeasureMouseUp,
} from '../TapeMeasureTool';
import { handleTextToolMouseDown } from '../TextTool';
import { blurAll } from 'helpers/blurAll';

const debouncedDragHandler = debounce(200, () => {
  RootStore.view.setIsChanging(false);
});

const handleDrag = (event: paper.ToolEvent) => {
  if (!RootStore.file.sheet) return;
  if (RootStore.view.panMode) {
    RootStore.view.setIsChanging(true);
    const panOffset = event.point.subtract(event.downPoint);
    paper.view.center = paper.view.center.subtract(panOffset);
    debouncedDragHandler();
  } else {
    switch (RootStore.toolbar.current) {
      case ToolEnum.wall:
        handleWallToolDrag(event);
        break;
      case ToolEnum.room:
        handleRoomToolDrag(event);
        break;
      case ToolEnum.pen:
        handlePenToolDrag(event);
        break;
      case ToolEnum.erase:
        handleEraseToolDrag(event);
        break;
      case ToolEnum.move:
        handleMoveToolDrag(event);
        break;
      case ToolEnum.measure:
        handleTapeMeasureDrag(event);
        break;

      default:
        break;
    }
  }
};

const handleMouseUp = (event: paper.ToolEvent) => {
  if (!RootStore.file.sheet) return;
  if (!RootStore.view.panMode) {
    switch (RootStore.toolbar.current) {
      case ToolEnum.wall:
        handleWallToolMouseUp(event);
        break;
      case ToolEnum.room:
        handleRoomToolMouseUp(event);
        break;
      case ToolEnum.pen:
        handlePenToolMouseUp(event);
        RootStore.activeDrawing.setPenDown(false);
        break;
      case ToolEnum.erase:
        handleEraseToolMouseUp(event);
        RootStore.activeDrawing.setEraserDown(false);
        break;
      case ToolEnum.move:
        handleMoveToolMouseUp(event);
        break;
      case ToolEnum.measure:
        handleTapeMeasureMouseUp(event);
        break;

      default:
        break;
    }
  }
};

const handleMouseDown = (event: paper.ToolEvent) => {
  if (!RootStore.file.sheet) return;
  blurAll();
  RootStore.closeObjectDimensionPopover();
  RootStore.dimensions.clearActiveDrawingDimensions();
  getLayerByName(Layers.hoveredCell)?.removeChildren();
  if (!RootStore.view.panMode) {
    if (
      event.item?.layer.name === 'OBJECTS' &&
      RootStore.toolbar.current !== ToolEnum.measure
    ) {
      // clicked on an object
      RootStore.toolbar.setCurrentTool(ToolEnum.move);
      RootStore.view.setHoveredCellOutlineColor(null);
      RootStore.view.setShowDistanceToWalls(false);
      handleMoveToolMouseDown(event);
      return;
    } else {
      RootStore.toolbar.current !== ToolEnum.move &&
        RootStore.selectedObjects.clear();
      RootStore.closeObjectContextMenu();
    }

    switch (RootStore.toolbar.current) {
      case ToolEnum.pen:
        handlePenToolMouseDown(event);
        RootStore.activeDrawing.setPenDown(true);
        break;
      case ToolEnum.erase:
        handleEraseToolMouseDown(event);
        RootStore.activeDrawing.setEraserDown(true);
        break;
      case ToolEnum.move:
        handleMoveToolMouseDown(event);
        break;
      case ToolEnum.text:
        handleTextToolMouseDown(event);
        break;

      default:
        break;
    }
  }
};

const handleMouseMove = (event: paper.ToolEvent) => {
  if (!RootStore.file.sheet) return;
  if (!RootStore.view.panMode) {
    if (RootStore.view.hoveredCellOutlineColor) {
      createOrActivateLayer(Layers.hoveredCell, {
        removeChildren: true,
      });
      const cell = cellPositionFromPoint(event.point);
      if (cell) {
        drawHoveredCell(cell, {
          color: RootStore.view.hoveredCellOutlineColor,
          hideExtensionLines:
            RootStore.toolbar.current === ToolEnum.pen ||
            RootStore.toolbar.current === ToolEnum.erase,
        });
      }
    }

    if (RootStore.view.showDistanceToWalls) {
      RootStore.dimensions.clearActiveDrawingDimensions();
      const cell = cellPositionFromPoint(event.point);
      if (cell) {
        showDistanceToWalls(cell);
      }
    }

    if (RootStore.toolbar.current === ToolEnum.measure) {
      handleTapeMeasureMouseMove(event);
    }
  }
};

export const subscribeToMouseEvents = () => {
  const tool = new paper.Tool();
  tool.onMouseDrag = handleDrag;
  tool.onMouseUp = handleMouseUp;
  tool.onMouseDown = handleMouseDown;
  tool.onMouseMove = handleMouseMove;
};

const TextToolIcon = ({ width = 24, height = 24, color = '#fff' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: 'scaleX(-1)' }}
  >
    <path
      d="M6 8V5H12M18 8V5H12M12 5V19M12 19H14.5714M12 19H9.42857"
      stroke={color}
    />
  </svg>
);

export default TextToolIcon;

import { view, Point } from 'paper';
import { debounce } from 'throttle-debounce';
import { checkValidZoom } from 'helpers/checkValidZoom';
import { ObjectMap, ObjectModel } from 'state/models/Objects';
import RootStore from 'state/models/Root';
import ObjectMetadata from 'objects/metadata';
import { getNewPosition } from '../Paper/MoveTool';
import { browserToPaperCoordinates } from '../Paper/View';
import { getGridOffset, gridContainsPoint } from '../Paper/Grid';
import { getSnappedPoint } from '../Grid';
import { trackRedo, trackUndo } from 'analytics/editor';
import { ToolEnum } from 'features/editor/types';
import { cleanupToolSideEffects } from '../Tools/CleanupToolSideEffects';
import { handleObjectDuplicate } from '../ObjectActions';

const debouncedWheelHandler = debounce(200, () => {
  RootStore.view.setIsChanging(false);
});

export const handleWheelEvent = (event: WheelEvent) => {
  if (event.defaultPrevented) return;
  event.preventDefault();
  if (RootStore.objectDimensionPopover) return;
  if (event.metaKey || event.ctrlKey) {
    let newZoom = view.zoom;
    const oldZoom = view.zoom;

    if (event.deltaY <= 0) {
      newZoom = view.zoom * 1.05;
    } else {
      newZoom = view.zoom * 0.95;
    }

    const beta = oldZoom / newZoom;

    const mousePosition = new Point(event.offsetX, event.offsetY);

    // viewToProject: gives the coordinates in the project space from the screen coordinates
    const viewPosition = view.viewToProject(mousePosition);

    const mpos = viewPosition;
    const ctr = view.center;

    const pc = mpos.subtract(ctr);
    const offset = mpos.subtract(pc.multiply(beta)).subtract(ctr);

    if (checkValidZoom(newZoom)) {
      view.zoom = newZoom;
      RootStore.view.setZoom(newZoom);
      view.center = view.center.add(offset);
    }
  } else {
    view.center = view.center.add(
      new Point(event.deltaX / view.zoom, event.deltaY / view.zoom)
    );
  }

  RootStore.dimensions.clearActiveDrawingDimensions();
  RootStore.view.setIsChanging(true);
  debouncedWheelHandler();
};

export const handleKeydownEvent = debounce(100, (e: KeyboardEvent) => {
  if (
    e.defaultPrevented ||
    document.activeElement?.nodeName === 'INPUT' ||
    document.activeElement?.classList.contains('editor-input')
  ) {
    return;
  }
  const key = e.key || e.keyCode;

  RootStore.keyboard.setMetaKey(!!e.metaKey);
  RootStore.keyboard.setAltKey(!!e.altKey);
  RootStore.keyboard.setShiftKey(!!e.shiftKey);

  if (key === ' ') {
    e.preventDefault();
    RootStore.keyboard.setSpaceKey(true);
    RootStore.view.enablePan();
  }

  if (key === 'Escape') {
    e.preventDefault();
    cleanupToolSideEffects();
  }

  // Move Tool Shorcut
  if (key === 'v' || key === 'V') {
    e.preventDefault();
    if (RootStore.toolbar.current !== ToolEnum.move) {
      RootStore.toolbar.setCurrentTool(ToolEnum.move);
    }
  }

  // Wall Tool Shorcut
  if (key === 'w' || key === 'W') {
    e.preventDefault();
    if (RootStore.toolbar.current !== ToolEnum.wall) {
      RootStore.toolbar.setCurrentTool(ToolEnum.wall);
    }
  }

  // Room Tool Shorcut
  if ((key === 'r' || key === 'R') && !e.metaKey) {
    e.preventDefault();
    if (RootStore.toolbar.current !== ToolEnum.room) {
      RootStore.toolbar.setCurrentTool(ToolEnum.room);
    }
  }

  // Draw Walls Tool Shorcut
  if ((key === 'd' || key === 'D') && !e.metaKey) {
    e.preventDefault();
    if (RootStore.toolbar.current !== ToolEnum.pen) {
      RootStore.toolbar.setCurrentTool(ToolEnum.pen);
    }
  }

  // Erase Walls Tool Shorcut
  if (key === 'e' || key === 'E') {
    e.preventDefault();
    if (RootStore.toolbar.current !== ToolEnum.erase) {
      RootStore.toolbar.setCurrentTool(ToolEnum.erase);
    }
  }

  // Tape Measure Shorcut
  if (key === 'm' || key === 'M') {
    e.preventDefault();
    if (RootStore.toolbar.current !== ToolEnum.measure) {
      RootStore.toolbar.setCurrentTool(ToolEnum.measure);
    }
  }

  // Text Tool Shorcut
  if (key === 't' || key === 'T') {
    e.preventDefault();
    if (RootStore.toolbar.current !== ToolEnum.text) {
      RootStore.toolbar.setCurrentTool(ToolEnum.text);
    }
  }

  if (key === 'Backspace' || key === 'Delete') {
    if (document.activeElement?.nodeName !== 'INPUT') {
      RootStore.deleteSelection();
    }
  }

  if ((key === 'z' || key === 'Z') && e.metaKey) {
    e.preventDefault();
    if (!e.shiftKey && RootStore.history.canUndo) {
      RootStore.history.undo();
      trackUndo({
        keyShortcut: true,
      });
    } else if (e.shiftKey && RootStore.history.canRedo) {
      RootStore.history.redo();
      trackRedo({
        keyShortcut: true,
      });
    }
  }

  if ((key === 'd' || key === 'D') && e.metaKey) {
    e.preventDefault();
    if (RootStore.selectedObjects.ids.length === 1) {
      const label =
        ObjectMetadata[
          RootStore.file.undoable.objects.getObjectData(
            RootStore.selectedObjects.ids[0]
          ).objectKey
        ].title;
      handleObjectDuplicate(RootStore.selectedObjects.ids[0], label);
    }
  }
});

export const handleKeyupEvent = (e: KeyboardEvent) => {
  if (e.defaultPrevented) {
    return;
  }
  const key = e.key || e.keyCode;

  RootStore.keyboard.setMetaKey(!!e.metaKey);
  RootStore.keyboard.setAltKey(!!e.altKey);
  RootStore.keyboard.setShiftKey(!!e.shiftKey);

  if (key === ' ') {
    RootStore.keyboard.setSpaceKey(false);
    setTimeout(() => {
      RootStore.view.disablePan();
    }, 200);
  }
};

export const handleMousemoveEvent = (e: MouseEvent) => {
  RootStore.cursor.setPosition(e);

  const draggingObjects = RootStore.draggingObjects;
  if (draggingObjects.data && draggingObjects.downPoint) {
    if (gridContainsPoint(new Point(draggingObjects.downPoint))) {
      // down point is on the grid
      const totalDragDelta = browserToPaperCoordinates({
        x: e.clientX,
        y: e.clientY,
      }).subtract(new Point(draggingObjects.downPoint));
      Object.values(JSON.parse(draggingObjects.data.json) as ObjectMap).forEach(
        (committedData) => {
          if (committedData) {
            const hasWallOpening =
              !!ObjectMetadata[committedData.objectKey]?.wallOpening;
            const overridedData: ObjectModel = {
              ...committedData,
              ...getNewPosition(
                { x: committedData.x, y: committedData.y },
                totalDragDelta,
                committedData.snapX,
                committedData.snapY,
                committedData.width * (RootStore.file.sheet?.PPU || 1),
                committedData.height * (RootStore.file.sheet?.PPU || 1) +
                  (hasWallOpening
                    ? RootStore.file.sheet?.cellSizePixels || 0
                    : 0),
                committedData.rotation
              ),
            };
            RootStore.overridedObjects.setOverridedObject(overridedData);
          }
        }
      );
    } else {
      // down point not on the grid
      // find position from current mouse position
      const paperMousePosition = browserToPaperCoordinates({
        x: e.clientX,
        y: e.clientY,
      });
      const gridMousePosition = getGridOffset(paperMousePosition);
      Object.values(JSON.parse(draggingObjects.data.json) as ObjectMap).forEach(
        (committedData) => {
          if (committedData) {
            const hasWallOpening =
              !!ObjectMetadata[committedData.objectKey]?.wallOpening;
            const p = gridMousePosition.subtract(
              hasWallOpening
                ? new Point({
                    x: 0,
                    y: committedData.height * (RootStore.file.sheet?.PPU || 1),
                  })
                : new Point({ x: 0, y: 0 })
            );
            const overridedData: ObjectModel = {
              ...committedData,
              ...getSnappedPoint(
                p,
                committedData.snapX,
                committedData.snapY,
                committedData.width * (RootStore.file.sheet?.PPU || 1),
                committedData.height * (RootStore.file.sheet?.PPU || 1) +
                  (hasWallOpening
                    ? RootStore.file.sheet?.cellSizePixels || 0
                    : 0),
                committedData.rotation
              ),
            };
            RootStore.overridedObjects.setOverridedObject(overridedData);
          }
        }
      );
    }
  }
};

export const handleMouseupEvent = () => {
  RootStore.cursor.setMouseDown(false);

  if (RootStore.draggingObjects.data) {
    const shouldOpenObjectPanel =
      Date.now() - (RootStore.draggingObjects.createdAt || 0) > 200;
    RootStore.draggingObjects.clear();
    RootStore.applyOverrides();
    if (shouldOpenObjectPanel) {
      setTimeout(() => {
        RootStore.ui.setObjectPanelOpen(true);
      }, 300);
    }
  }
};

export const handleMousedownEvent = () => {
  RootStore.cursor.setMouseDown(true);
};

const debouncedRightClickHandler = debounce(200, () => {
  RootStore.cursor.setRightClick(false);
});

export const handleRightClickEvent = (e: MouseEvent) => {
  e.preventDefault();
  RootStore.cursor.setRightClick(true);
  debouncedRightClickHandler();
};

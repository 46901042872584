import { types, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

const Project = types
  .model({
    id: types.string,
    title: types.string,
    modifiedAt: types.number,
  })
  .actions((self) => ({
    setTitle(value: string) {
      self.title = value;
    },
  }));

const UserProjects = types.array(Project);

export type ProjectSnapshotOut = SnapshotOut<typeof Project>;
export type ProjectsSnapshotIn = SnapshotIn<typeof UserProjects>;

export default UserProjects;

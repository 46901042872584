import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import firebase from 'firebase/app';
import { toJS } from 'mobx';
import LZUTF8 from 'lzutf8';
import { toast } from 'react-toastify';
import SaveIcon from 'features/editor/icons/SaveIcon';
import {
  Menu,
  MenuButton,
  MenuToggleButton,
  SaveButton as StyledSaveButton,
} from './styles';
import { useLocalStore } from 'state';
import { SaveStatus } from 'state/types';
import { database, storage } from '../../../../firebase';
import { useAuth } from 'contexts/AuthContext';
import { getFilePath } from 'helpers/getFilePath';
import { FILE_ENCODING, FREE_TIER_PROJECT_LIMIT } from 'features/editor/config';
import { fitToScreen } from 'features/editor/utils/Paper/View';
import ArrowRightIcon from 'features/editor/icons/ArrowRightIcon';
import {
  trackProjectSaved,
  trackProjectSavedAsNewProject,
} from 'analytics/project';

interface FileActionButtonsProps {
  projectTitle: string;
}

const FileActionButtons = ({
  projectTitle: projectTile,
}: FileActionButtonsProps) => {
  const { currentUser, getUserProjects, userTier } = useAuth();
  const [menuButtonHovered, setMenuButtonHovered] = useState(false);
  const [menuHovered, setMenuHovered] = useState(false);
  const [delayComplete, setDelayComplete] = useState(true);
  const {
    saveStatus,
    setSaveStatus,
    activeProject,
    userProjects,
    loadActiveProject,
    file,
    ui,
  } = useLocalStore();

  const createFile = () => {
    const fileJs = toJS(file);
    const fileStr = JSON.stringify(fileJs);
    const compressed = LZUTF8.compress(fileStr, {
      outputEncoding: FILE_ENCODING,
    });
    return compressed;
  };

  const uploadFileToStorage = async (projectId: string, file: any) => {
    if (!currentUser?.uid) return;

    const filePath = getFilePath(currentUser.uid, projectId);
    await storage.ref(filePath).put(file);
  };

  const createProject = async (title?: string) => {
    if (!currentUser) return;

    // add project to firestore db
    const project = await database.projects.add({
      userId: currentUser.uid,
      title: title || projectTile,
      createdAt: firebase.firestore.Timestamp.now(),
      modifiedAt: firebase.firestore.Timestamp.now(),
    });

    // create the file as a byte array and upload to storage
    const file = createFile();
    if (!file) return; // @TODO: handle errors

    await uploadFileToStorage(project.id, file);

    getUserProjects();
    loadActiveProject({
      id: project.id,
      title: title || projectTile,
    });
    fitToScreen();
    setSaveStatus(SaveStatus.saved);
  };

  const updateProject = async (id: string) => {
    const file = createFile();

    // overwrite existing project file
    await uploadFileToStorage(id, file);

    const projectDocument = database.projects.doc(id);
    projectDocument.update({
      modifiedAt: firebase.firestore.Timestamp.now(),
    });

    getUserProjects();
    setSaveStatus(SaveStatus.saved);
  };

  const userCanSave = () => {
    if (userTier === 1) return true;
    if (activeProject?.id) return true;
    return (userProjects?.length || 0) < FREE_TIER_PROJECT_LIMIT;
  };

  const userCanSaveNew = () => {
    if (userTier === 1) return true;
    return (userProjects?.length || 0) < FREE_TIER_PROJECT_LIMIT;
  };

  const canSave = userCanSave();
  const canSaveNew = userCanSaveNew();

  const handleSave = () => {
    if (!currentUser?.uid) {
      ui.setLoginModal(true);
      ui.setSaveOnAuthComplete(true);
      return;
    }

    if (!canSave) {
      return;
    }

    if (saveStatus !== SaveStatus.unsaved) return;

    setSaveStatus(SaveStatus.saving);

    if (activeProject?.id?.length) {
      // file already exists
      updateProject(activeProject.id);
      trackProjectSaved({
        isNew: false,
      });
    } else {
      createProject();
      trackProjectSaved({
        isNew: true,
      });
    }

    toast(
      `Saved project '${projectTile.length > 0 ? projectTile : 'Untitled'}'`
    );
  };

  const handleSaveAs = () => {
    if (saveStatus === SaveStatus.saving || saveStatus === SaveStatus.no_file)
      return;

    if (!currentUser?.uid) {
      ui.setLoginModal(true);
      ui.setSaveOnAuthComplete(true);
      return;
    }

    if (!canSaveNew) {
      return;
    }

    setSaveStatus(SaveStatus.saving);
    createProject(`${projectTile} copy`);
    trackProjectSavedAsNewProject();

    toast(`Created project '${projectTile} copy'`);
  };

  return (
    <>
      <MenuToggleButton
        type="button"
        onMouseEnter={() => {
          setMenuButtonHovered(true);
        }}
        onMouseLeave={() => {
          setDelayComplete(false);
          setMenuButtonHovered(false);
          setTimeout(() => {
            setDelayComplete(true);
          }, 400);
        }}
      >
        <ArrowRightIcon width={22} />
      </MenuToggleButton>
      <Menu isOpen={menuButtonHovered || menuHovered || !delayComplete}>
        <MenuButton
          type="button"
          disabled={!canSaveNew}
          onMouseEnter={() => {
            setMenuHovered(true);
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              setMenuHovered(false);
            }, 400);
          }}
          onClick={() => {
            setMenuHovered(false);
            canSaveNew && handleSaveAs();
          }}
        >
          Save as a New Project
        </MenuButton>
        <MenuButton
          disabled
          type="button"
          onMouseEnter={() => {
            setMenuHovered(true);
          }}
          onMouseLeave={() => {
            setTimeout(() => {
              setMenuHovered(false);
            }, 400);
          }}
          onClick={() => {
            //
          }}
        >
          Export (coming soon)
        </MenuButton>
      </Menu>
      <StyledSaveButton disabled={!canSave} onClick={handleSave}>
        {canSave && <SaveIcon width={16} height={16} />}
        <span>{canSave ? 'Save' : 'Saving Disabled'}</span>
      </StyledSaveButton>
    </>
  );
};

export default observer(FileActionButtons);

/**
 * Central theme definition:
 * This can be accessed in any styled component using props.theme
 */

// @TODO: add type defs for theme
const theme = {
  appBgColor: '#e8ebed',
  borderRadius: 2,
  textColor: '#e0e0e0',
  accentTextColor: '#ffffff',
  accentBorderColor: '#00b6ff',
  accentBorderColor2: '#009bff',
  appbar: {
    bgColor: '#242424',
  },
  glass: {
    background: 'rgba(50, 50, 50, 0.8)',
    backdropFilter: 'saturate(180%) blur(20px)',
    border: '1px solid #666666',
    inputBgColor: '#3d3d3d',
  },
  lightGlass: {
    background: 'rgba(220, 220, 220, 0.8)',
    hoverBackground: 'rgba(200, 200, 200, 0.8)',
    backdropFilter: 'saturate(180%) blur(20px)',
    border: '1px solid #bbbbbb',
    inputBgColor: '#3d3d3d',
    color: '#777777',
  },
  toolbar: {
    buttonHeight: 34,
    buttonFontSize: 18,
    buttonColor: '#d3d3d3',
    hoveredButtonBgColor: '#292929',
    activeButtonBgColor: '#000000',
  },
  grid: {
    bgColor: '#ffffff',
    lineColor: '#dadada',
  },
};

export default theme;

import * as paper from 'paper';
import { Layers, ObjectSnap, Point } from 'features/editor/types';
import { ObjectModel } from 'state/models/Objects';
import rootStore from 'state/models/Root';
import { getSnappedPoint } from 'features/editor/utils/Grid';
import ObjectMetadata from 'objects/metadata';
import { getRotatedOffset } from 'features/editor/utils/Paper/Objects';
import { paperToBrowserCoordinates } from '../View';
import { APP_BAR_HEIGHT_PX } from 'features/editor/config';
import { createOrActivateLayer, getLayerByName } from '../Layers';
import { getGridOffset, paperPointFromGridOffset } from '../Grid';
import { getWallCellsContainedByRectangle } from '../Walls';
import { readGraphFromString } from '../../Graphlib/Serialize';

export const getNewPosition = (
  initalPoint: Point,
  dragDelta: paper.Point,
  snapX: ObjectSnap,
  snapY: ObjectSnap,
  width = 0,
  height = 0,
  rotation = 0
) => {
  const rotatedOffset = getRotatedOffset(
    width,
    height,
    rotation,
    snapX !== ObjectSnap.none || snapY !== ObjectSnap.none
  );

  const snappedPoint = getSnappedPoint(
    new paper.Point({
      x: initalPoint.x + dragDelta.x,
      y: initalPoint.y + dragDelta.y,
    }).add(rotatedOffset),
    snapX,
    snapY,
    width,
    height,
    rotation
  ).subtract(rotatedOffset);
  return { x: snappedPoint.x, y: snappedPoint.y };
};

let isDraggingSelectionBox = false;
let hasActiveSelection = false;
let activeSelectionInitialPoint = null as paper.Point | null;

export const clearActiveSelection = (options?: {
  preventClearingSelectedItems?: boolean;
}) => {
  hasActiveSelection = false;
  activeSelectionInitialPoint = null;
  getLayerByName(Layers.ghostWalls)?.removeChildren();
  getLayerByName(Layers.selectionBox)?.removeChildren();
  if (!options?.preventClearingSelectedItems) rootStore.selectedWalls.clear();
  if (!options?.preventClearingSelectedItems) rootStore.selectedObjects.clear();
};

/**
 * Drag event handler when the move tool is selected
 *
 * @param event
 */
export const handleMoveToolDrag = (event: paper.ToolEvent) => {
  if (isDraggingSelectionBox) {
    createOrActivateLayer(Layers.selectionBox, {
      removeChildren: true,
    });
    const rect = new paper.Shape.Rectangle(
      paperPointFromGridOffset(
        getSnappedPoint(
          getGridOffset(event.point),
          ObjectSnap.grid,
          ObjectSnap.grid
        )
      ),
      paperPointFromGridOffset(
        getSnappedPoint(
          getGridOffset(event.downPoint),
          ObjectSnap.grid,
          ObjectSnap.grid
        )
      )
    );
    // select objects
    const objectsLayer = createOrActivateLayer(Layers.objects);
    rootStore.selectedObjects.clear();
    objectsLayer.children.forEach((c) => {
      if (rect.bounds.intersects(c.bounds) && c.name) {
        rootStore.selectedObjects.addId(c.name);
      }
    });
    // select walls
    createOrActivateLayer(Layers.selectedWalls);
    const selectedCells = getWallCellsContainedByRectangle(
      rect,
      readGraphFromString(rootStore.file.undoable.walls.json)
    );
    rootStore.selectedWalls.setCells(selectedCells);
    // draw selection box
    rect.strokeWidth = (rootStore.file.sheet?.cellSizePixels || 20) / 10;
    rect.fillColor = new paper.Color('#008cea20');
    rect.strokeColor = new paper.Color('#008cea');

    return;
  }

  const totalDragDelta = event.point.subtract(event.downPoint);
  let xDelta = 0;
  let yDelta = 0;
  const cellSizePx = rootStore.file.sheet?.cellSizePixels || 10;

  if (hasActiveSelection) {
    const selectionBoxLayer = createOrActivateLayer(Layers.selectionBox);
    if (selectionBoxLayer.children[0] && activeSelectionInitialPoint) {
      const tl = paperPointFromGridOffset(
        getSnappedPoint(
          getGridOffset(activeSelectionInitialPoint.add(totalDragDelta)),
          ObjectSnap.grid,
          ObjectSnap.grid,
          selectionBoxLayer.children[0].bounds.width,
          selectionBoxLayer.children[0].bounds.height
        )
      );
      selectionBoxLayer.children[0].bounds.topLeft = tl;
      const d = tl.subtract(activeSelectionInitialPoint);
      xDelta = d.x;
      yDelta = d.y;
    }
    rootStore.selectedWalls.setDelta(
      Math.round(yDelta / cellSizePx),
      Math.round(xDelta / cellSizePx)
    );
  }

  if (!rootStore.selectedObjects.isEmpty) {
    rootStore.selectedObjects.ids.forEach((selectedObjectId) => {
      const committedData =
        rootStore.file.undoable.objects.getObjectData(selectedObjectId);
      if (committedData) {
        const hasWallOpening =
          !!ObjectMetadata[committedData.objectKey]?.wallOpening;
        const newPosition = hasActiveSelection
          ? {
              x: committedData.x + xDelta,
              y: committedData.y + yDelta,
            }
          : getNewPosition(
              { x: committedData.x, y: committedData.y },
              totalDragDelta,
              committedData.snapX,
              committedData.snapY,
              committedData.width * (rootStore.file.sheet?.PPU || 1),
              committedData.height * (rootStore.file.sheet?.PPU || 1) +
                (hasWallOpening
                  ? rootStore.file.sheet?.cellSizePixels || 0
                  : 0),
              committedData.rotation
            );
        const overridedData: ObjectModel = {
          ...committedData,
          ...newPosition,
        };

        rootStore.overridedObjects.setOverridedObject(overridedData);
      }
    });
  }
};

/**
 * Drag event handler when the move tool is selected
 *
 * @param event
 */
export const handleMoveToolMouseDown = (event: paper.ToolEvent) => {
  const isObject = event.item?.layer.name === Layers.objects;
  const isSelectionBox = event.item?.layer.name === Layers.selectionBox;

  if (
    !hasActiveSelection &&
    !event.modifiers.shift &&
    !rootStore.selectedObjects.isSelected(event.item?.name || '')
  ) {
    rootStore.selectedObjects.clear();
  }

  if (isObject) {
    rootStore.selectedObjects.addId(event.item?.name || '');
    isDraggingSelectionBox = false;
  } else {
    isDraggingSelectionBox = true;
  }

  if (hasActiveSelection) {
    if (!isSelectionBox) {
      clearActiveSelection();
    } else {
      isDraggingSelectionBox = false;
    }
  }

  // wait for right click flag to be set
  setTimeout(() => {
    if (isObject && rootStore.cursor.rightClick) {
      const position = paperToBrowserCoordinates(event.point);
      position.y -= APP_BAR_HEIGHT_PX;
      rootStore.setObjectContextMenu({
        objectId: event.item?.name || '',
        ...position,
      });
    } else {
      rootStore.closeObjectContextMenu();
    }
  }, 100);
};

/**
 * Mouseup event handler when the move tool is selected
 *
 * @param _event
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const handleMoveToolMouseUp = (_event: paper.ToolEvent) => {
  const selectionBoxLayer = createOrActivateLayer(Layers.selectionBox);
  if (isDraggingSelectionBox) {
    isDraggingSelectionBox = false;
    if (
      !rootStore.selectedObjects.isEmpty ||
      rootStore.selectedWalls.cells?.length
    ) {
      if (selectionBoxLayer.children[0]) {
        selectionBoxLayer.children[0].dashArray = [12, 16];
      }
      selectionBoxLayer.bringToFront();
      hasActiveSelection = true;
      activeSelectionInitialPoint = selectionBoxLayer.bounds.topLeft;
    } else {
      clearActiveSelection();
    }

    return;
  }

  clearActiveSelection({ preventClearingSelectedItems: true });
  rootStore.applyOverrides();
};

const UserAvatarSvg = ({
  width = 24,
  height = 24,
  strokeColor = '#fff',
  stops = [
    { stopColor: '#d53369', offset: '0%' },
    { stopColor: '#daae51', offset: '100%' },
  ],
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <linearGradient x1="0" y1="0" x2="100%" y2="100%" id="gradient">
      {stops.map((s, index) => (
        <stop key={index} stopColor={s.stopColor} offset={s.offset} />
      ))}
    </linearGradient>
    <circle
      cx="16"
      cy="16"
      r="15.5"
      fill="url(#gradient)"
      stroke={strokeColor}
    />
    <path
      d="M4 26C13.1861 19.4411 18.4357 19.2265 28 26"
      stroke={strokeColor}
    />
    <path
      d="M22.5 15C22.5 18.5423 19.64 21 16 21C12.36 21 9.5 18.5423 9.5 15C9.5 11.4101 12.4101 8.5 16 8.5C19.5899 8.5 22.5 11.4101 22.5 15Z"
      stroke={strokeColor}
    />
  </svg>
);

export default UserAvatarSvg;

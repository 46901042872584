import * as paper from 'paper';
import RootStore from 'state/models/Root';
import { APP_BAR_HEIGHT_PX } from 'features/editor/config';
import { Point as SimplePoint } from 'features/editor/types';

const centerSheetInView = (options?: { offsetX?: number }) => {
  const gridBoundingRect = paper.project.getItem({
    name: 'GRID_BOUNDING_RECT',
  });
  paper.view.center = gridBoundingRect.bounds.center;
  if (options?.offsetX) {
    paper.view.center = paper.view.center.add(
      new paper.Point(-options.offsetX / RootStore.view.zoom, 0)
    );
  }
};

export const fitToScreen = () => {
  if (RootStore && RootStore.file.sheet) {
    const defaultScale = Math.min(
      (window.innerHeight - APP_BAR_HEIGHT_PX - 64) /
        RootStore.file.sheet.heightPixels,
      (window.innerWidth - 64) / RootStore.file.sheet.widthPixels
    );
    paper.view.zoom = defaultScale;
    RootStore.view.setZoom(defaultScale);
    centerSheetInView();
  }
};

export const zoomBy = (step: number) => {
  if (RootStore && RootStore.file.sheet) {
    let newScale = paper.view.zoom + step;
    if (newScale < 0.01) newScale = 0.01;
    if (newScale > 5) newScale = 5;
    paper.view.zoom = newScale;
    RootStore.view.setZoom(newScale);
  }
};

export const setZoom = (value: number) => {
  if (RootStore && RootStore.file.sheet) {
    paper.view.zoom = value;
    RootStore.view.setZoom(value);
  }
};

export const showPrintPreview = () => {
  if (RootStore && RootStore.file.sheet) {
    const defaultScale = Math.min(
      (window.innerHeight - APP_BAR_HEIGHT_PX - 96) /
        RootStore.file.sheet.heightPixels,
      (window.innerWidth - 600) / RootStore.file.sheet.widthPixels
    );
    paper.view.zoom = defaultScale;
    RootStore.view.setZoom(defaultScale);
    centerSheetInView({
      offsetX: -200,
    });
    RootStore.view.setShowGrid(false);
    RootStore.ui.setExportPanel(true);
  }
};

export const paperToBrowserCoordinates = (p: paper.Point) => {
  const navOffset = new paper.Point([0, APP_BAR_HEIGHT_PX]);
  const clientPoint = paper.view.projectToView(p).add(navOffset);
  return {
    x: clientPoint.x,
    y: clientPoint.y,
  };
};

export const browserToPaperCoordinates = (p: SimplePoint) => {
  const clientPoint = new paper.Point(p);
  const navOffset = new paper.Point([0, APP_BAR_HEIGHT_PX]);
  return paper.view.viewToProject(clientPoint.subtract(navOffset));
};

export const gridToBrowserCoordinates = (p: paper.Point) => {
  const gridBoundingRect = paper.project.getItem({
    name: 'GRID_BOUNDING_RECT',
  });
  const paperPoint = p.add(gridBoundingRect.bounds.topLeft);
  return paperToBrowserCoordinates(paperPoint);
};

export const browserToGridCoordinates = (p: SimplePoint) => {
  const gridBoundingRect = paper.project.getItem({
    name: 'GRID_BOUNDING_RECT',
  });
  const paperPoint = browserToPaperCoordinates(p);
  return paperPoint.subtract(gridBoundingRect.bounds.topLeft);
};

import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Windows = <ObjectMap>{
  //
  // Windows
  //
  SINGLE_WINDOW: {
    key: 'SINGLE_WINDOW',
    title: 'Window',
    variant: 'single',
    type: ObjectType.window,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.grid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 20"', width: 20, height: 0 },
        { label: 'w: 24"', width: 24, height: 0 },
        { label: 'w: 28"', width: 28, height: 0 },
        { label: 'w: 32"', width: 32, height: 0 },
        { label: 'w: 36"', width: 36, height: 0 },
        { label: 'w: 40"', width: 40, height: 0 },
      ],
      metric: [
        { label: 'w: 508mm', width: 508, height: 0 },
        { label: 'w: 610mm', width: 610, height: 0 },
        { label: 'w: 711mm', width: 711, height: 0 },
        { label: 'w: 813mm', width: 813, height: 0 },
        { label: 'w: 914mm', width: 914, height: 0 },
        { label: 'w: 1016mm', width: 1016, height: 0 },
      ],
    },
    lockHeight: true,
  },
  DOUBLE_WINDOW: {
    key: 'DOUBLE_WINDOW',
    title: 'Window',
    variant: 'double',
    type: ObjectType.window,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.grid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 34"', width: 34, height: 0 },
        { label: 'w: 40"', width: 40, height: 0 },
        { label: 'w: 48"', width: 48, height: 0 },
        { label: 'w: 56"', width: 56, height: 0 },
        { label: 'w: 62"', width: 62, height: 0 },
        { label: 'w: 72"', width: 72, height: 0 },
      ],
      metric: [
        { label: 'w: 864mm', width: 864, height: 0 },
        { label: 'w: 1016mm', width: 1016, height: 0 },
        { label: 'w: 1219mm', width: 1219, height: 0 },
        { label: 'w: 1422mm', width: 1422, height: 0 },
        { label: 'w: 1524mm', width: 1524, height: 0 },
        { label: 'w: 1829mm', width: 1829, height: 0 },
      ],
    },
    lockHeight: true,
  },
  TRIPLE_WINDOW: {
    key: 'TRIPLE_WINDOW',
    title: 'Window',
    variant: 'triple',
    type: ObjectType.window,
    snapX: ObjectSnap.halfGrid,
    snapY: ObjectSnap.grid,
    snapRotation: 90,
    sizes: {
      us: [
        { label: 'w: 60"', width: 60, height: 0 },
        { label: 'w: 72"', width: 72, height: 0 },
        { label: 'w: 84"', width: 84, height: 0 },
      ],
      metric: [
        { label: 'w: 1524mm', width: 1524, height: 0 },
        { label: 'w: 1829mm', width: 1829, height: 0 },
        { label: 'w: 2134mm', width: 2134, height: 0 },
      ],
    },
    lockHeight: true,
  },
};

import { ObjectMap, ObjectSnap, ObjectType } from 'features/editor/types';

export const Desks = <ObjectMap>{
  DESK_SMALL: {
    key: 'DESK_SMALL',
    title: 'Desk',
    variant: 'small',
    type: ObjectType.desk,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: 'standard (w: 36", d: 30")', width: 36, height: 30 },
        { label: 'narrow (w: 36", d: 24")', width: 36, height: 24 },
        { label: 'deep (w: 36", d: 36")', width: 36, height: 36 },
      ],
      metric: [
        { label: 'standard (w: 914mm, d: 762mm)', width: 914, height: 762 },
        { label: 'narrow (w: 914mm, d: 610mm)', width: 914, height: 610 },
        { label: 'deep (w: 914mm, d: 914mm)', width: 914, height: 914 },
      ],
    },
  },
  DESK_MEDIUM: {
    key: 'DESK_MEDIUM',
    title: 'Desk',
    variant: 'medium',
    type: ObjectType.desk,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: 'standard (w: 42", d: 30")', width: 42, height: 30 },
        { label: 'narrow (w: 42", d: 24")', width: 42, height: 24 },
        { label: 'deep (w: 42", d: 36")', width: 42, height: 36 },
      ],
      metric: [
        { label: 'standard (w: 1067mm, d: 762mm)', width: 1067, height: 762 },
        { label: 'narrow (w: 1067mm, d: 610mm)', width: 1067, height: 610 },
        { label: 'deep (w: 1067mm, d: 914mm)', width: 1067, height: 914 },
      ],
    },
  },
  DESK_LARGE: {
    key: 'DESK_LARGE',
    title: 'Desk',
    variant: 'large',
    type: ObjectType.desk,
    snapX: ObjectSnap.none,
    snapY: ObjectSnap.none,
    snapRotation: 0,
    sizes: {
      us: [
        { label: 'standard (w: 60", d: 30")', width: 60, height: 30 },
        { label: 'narrow (w: 60", d: 24")', width: 60, height: 24 },
        { label: 'deep (w: 60", d: 36")', width: 60, height: 36 },
      ],
      metric: [
        { label: 'standard (w: 1524mm, d: 762mm)', width: 1524, height: 762 },
        { label: 'narrow (w: 1524mm, d: 610mm)', width: 1524, height: 610 },
        { label: 'deep (w: 1524mm, d: 914mm)', width: 1524, height: 914 },
      ],
    },
  },
};

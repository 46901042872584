const ArrowRightIcon = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={height}
    width={width}
    viewBox="0 0 24 24"
  >
    <path fill="currentColor" d="m10 17 5-5-5-5v10z"></path>
  </svg>
);

export default ArrowRightIcon;

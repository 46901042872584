const SaveIcon = ({
  width = 24,
  height = 24,
  color,
}: {
  width: number;
  height: number;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67 2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export default SaveIcon;

import { types, Instance, SnapshotIn } from 'mobx-state-tree';
import { angleOf, distanceOf } from 'features/editor/utils/Math';

const Point = types.model({
  x: types.number,
  y: types.number,
});

const DimensionLabel = types.model({
  id: types.string,
  position: Point,
  label: types.string,
});

const Dimension = types
  .model({
    id: types.string,
    start: Point,
    center: Point,
    end: Point,
    label: types.string,
    offset: types.maybe(types.number),
  })
  .views((self) => ({
    get angle() {
      return angleOf(self.start, self.end);
    },
    get length() {
      return distanceOf(self.start, self.end);
    },
  }));
export type DimensionInstance = Instance<typeof Dimension>;

const DimensionArr = types.array(Dimension);
export type DimensionArrSnapshotIn = SnapshotIn<typeof DimensionArr>;

const DimensionLabelArr = types.array(DimensionLabel);
export type DimensionLabelArrSnapshotIn = SnapshotIn<typeof DimensionLabelArr>;

const Dimensions = types
  .model({
    activeDrawing: types.maybeNull(DimensionArr),
    labels: types.maybeNull(DimensionLabelArr),
    selectedObjects: types.maybeNull(DimensionArr),
  })
  .actions((self) => ({
    setActiveDrawingDimensions(value: DimensionArrSnapshotIn) {
      self.activeDrawing = DimensionArr.create(value);
    },
    clearActiveDrawingDimensions() {
      self.activeDrawing = null;
    },
    setDimensionLabels(value: DimensionLabelArrSnapshotIn) {
      self.labels = DimensionLabelArr.create(value);
    },
    clearDimensionLabels() {
      self.labels = null;
    },
    setSelectedObjectDimensions(value: DimensionArrSnapshotIn) {
      self.selectedObjects = DimensionArr.create(value);
    },
    clearSelectedObjectDimensions() {
      self.selectedObjects = null;
    },
  }));

export type DimensionsInstance = Instance<typeof Dimensions>;

export default Dimensions;

import { useEffect, useRef, useState } from 'react';
import ArrowLeftIcon from 'features/editor/icons/ArrowLeftIcon';
import ArrowRightIcon from 'features/editor/icons/ArrowRightIcon';
import { ObjectData, ObjectType } from 'features/editor/types';
import ObjectPanelCard from '../ObjectPanelCard';
import {
  HorizontalScroll,
  RowHeader,
  ScrollLeftArrow,
  ScrollRightArrow,
  Wrapper,
} from './styles';
import { ObjectCardSkeleton } from '../ObjectPanelCard/styles';

interface ObjectPanelRowProps {
  objectType: ObjectType;
  meta: ObjectData[];
}

const ObjectPanelRow = ({ objectType, meta }: ObjectPanelRowProps) => {
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);
  const [skeletons, setSkeletons] = useState<number[]>([]);

  useEffect(() => {
    const s = [];
    for (let i = 0; i < 3 - meta.length; i++) {
      s.push(i);
    }
    setSkeletons(s);
  }, [meta.length]);

  return (
    <Wrapper>
      <RowHeader>
        {objectType}
        <span>{`(${meta.length})`}</span>
      </RowHeader>
      <HorizontalScroll
        ref={scrollAreaRef}
        onWheel={(e) => {
          if (scrollAreaRef.current && Math.abs(e.deltaX) > Math.abs(e.deltaY))
            scrollAreaRef.current.scrollLeft += e.deltaX;
        }}
      >
        <div className="inner">
          {meta.map((objectMeta) => (
            <ObjectPanelCard key={objectMeta.key} objectMeta={objectMeta} />
          ))}
          {skeletons.map((key) => (
            <ObjectCardSkeleton key={key} />
          ))}
        </div>
      </HorizontalScroll>
      <ScrollLeftArrow>
        <button
          onClick={() => {
            if (scrollAreaRef.current)
              scrollAreaRef.current.scrollLeft -= 124 + 16;
          }}
        >
          <ArrowLeftIcon />
        </button>
      </ScrollLeftArrow>
      <ScrollRightArrow>
        <button
          onClick={() => {
            if (scrollAreaRef.current)
              scrollAreaRef.current.scrollLeft += 124 + 16;
          }}
        >
          <ArrowRightIcon />
        </button>
      </ScrollRightArrow>
    </Wrapper>
  );
};

export default ObjectPanelRow;

import { types, SnapshotIn } from 'mobx-state-tree';

const Sheet = types.model({
  metric: types.boolean,

  widthPixels: types.number,
  heightPixels: types.number,

  printWidth: types.number,
  printHeight: types.number,

  gridRows: types.number,
  gridColumns: types.number,

  gridWidthPixels: types.number,
  gridHeightPixels: types.number,

  // gridUnitWidth: types.number,
  // gridUnitHeight: types.number,

  cellSizePixels: types.number,
  PPU: types.number,
});

export type SheetSnapshotIn = SnapshotIn<typeof Sheet>;

export default Sheet;

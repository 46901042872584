import { Path, Color } from 'paper';
import { removeCellsFromWallGraph } from 'features/editor/utils/Paper/Walls';
import RootStore from 'state/models/Root';
import { createOrActivateLayer } from 'features/editor/utils/Paper/Layers';
import { nodeIdFromCell } from 'features/editor/utils/Graphlib/Mutate';
import {
  cellPositionFromPoint,
  getCellPosition,
} from 'features/editor/utils/Grid';
import { Cell, CellPosition, Layers } from 'features/editor/types';
import { trackEraseToolCommitted } from 'analytics/editor';

const updateEraserTrail = (cellPosition: CellPosition) => {
  const nodeId = nodeIdFromCell(cellPosition);
  if (!RootStore.activeDrawing.penTrail.get(nodeId)) {
    RootStore.activeDrawing.addNodeToPenTrail(nodeId, cellPosition);

    const strokeWidth = (RootStore.file.sheet?.cellSizePixels || 18) / 22;

    const path = new Path.Rectangle({
      center: cellPosition.center,
      size: [
        RootStore.file.sheet?.cellSizePixels || 1,
        RootStore.file.sheet?.cellSizePixels || 1,
      ],
    });

    path.style = {
      ...path.style,
      strokeColor: RootStore.view.showGrid
        ? new Color(0.85, 0.85, 0.85, 1)
        : new Color(1, 1, 1, 1),
      fillColor: new Color(1, 1, 1, 1),
      strokeWidth: strokeWidth,
    };
  }
};

/**
 * Drag event handler when the erase tool is selected
 *
 * @param event
 */
export const handleEraseToolDrag = (event: paper.ToolEvent) => {
  createOrActivateLayer(Layers.activeDrawing);

  const { point, downPoint } = event;
  const cellPosition = cellPositionFromPoint(point);
  const downPointCell = cellPositionFromPoint(downPoint);

  if (
    RootStore.activeDrawing.continuousDrag &&
    cellPosition?.row === downPointCell?.row
  ) {
    const min = Math.min(cellPosition?.column || 0, downPointCell?.column || 0);
    const max = Math.max(cellPosition?.column || 0, downPointCell?.column || 0);
    for (let i = min; i <= max; i++) {
      const cp = getCellPosition({ row: cellPosition?.row || 0, column: i });
      if (cp) {
        updateEraserTrail(cp);
      }
    }
  } else if (
    RootStore.activeDrawing.continuousDrag &&
    cellPosition?.column === downPointCell?.column
  ) {
    const min = Math.min(cellPosition?.row || 0, downPointCell?.row || 0);
    const max = Math.max(cellPosition?.row || 0, downPointCell?.row || 0);
    for (let i = min; i <= max; i++) {
      const cp = getCellPosition({ row: i, column: cellPosition?.column || 0 });
      if (cp) {
        updateEraserTrail(cp);
      }
    }
  } else if (cellPosition) {
    RootStore.activeDrawing.setContinuousDrag(false);
    updateEraserTrail(cellPosition);
  }
};

/**
 * Drag event handler when the erase tool is selected
 *
 * @param event
 */
export const handleEraseToolMouseDown = (event: paper.ToolEvent) => {
  createOrActivateLayer(Layers.activeDrawing);
  RootStore.activeDrawing.setContinuousDrag(true);

  const { point } = event;
  const cellPosition = cellPositionFromPoint(point);

  if (cellPosition) {
    updateEraserTrail(cellPosition);
  }
};

/**
 * Mouseup event handler when the erase tool is selected
 *
 * @param event
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const handleEraseToolMouseUp = (_event: paper.ToolEvent) => {
  const activeDrawingLayer = createOrActivateLayer(Layers.activeDrawing);
  RootStore.activeDrawing.setContinuousDrag(false);

  const cells: Cell[] = [];
  RootStore.activeDrawing.penTrail.forEach((cell: Cell) => {
    cells.push(cell);
  });

  // if (cells.length === 0) {
  //   const { point } = event;
  //   const cellPosition = cellPositionFromPoint(point);
  //   if (cellPosition) cells.push(cellPosition);
  // }

  const newJSON = removeCellsFromWallGraph(
    cells,
    RootStore.file.undoable.walls.json
  );
  RootStore.file.undoable.walls.setWallJSON(newJSON);
  trackEraseToolCommitted();

  activeDrawingLayer.remove();
  RootStore.activeDrawing.clearPenTrail();
};

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { getObjectBrowserBounds } from 'features/editor/utils/Paper/Objects';
import { useLocalStore } from 'state';
import {
  Wrapper,
  Row,
  DimensionHorizontal,
  DimensionInput,
  DimensionInputWrapper,
  DimensionVertical,
  DimensionWidthInputWrapper,
  PreviewWrapper,
  WarningMessage,
} from './styles';
import ObjectPreview from '../ObjectPreview';
import ObjectMetadata from 'objects/metadata';
import { APP_BAR_HEIGHT_PX } from 'features/editor/config';
import { ObjectSnap } from 'features/editor/types';

const ObjectDimensionPopover = () => {
  const { objectDimensionPopover, file, view, closeObjectDimensionPopover } =
    useLocalStore();
  const [objectData, setObjectData] = useState(
    objectDimensionPopover
      ? file.undoable.objects.getObjectData(objectDimensionPopover)
      : null
  );
  const [heightInput, setHeightInput] = useState('0');
  const [widthInput, setWidthInput] = useState('0');
  const [snapWarning, setSnapWarning] = useState<ObjectSnap | null>(null);

  useEffect(() => {
    if (objectData) {
      if (objectData.rotation !== 90 && objectData.rotation !== 270) {
        setHeightInput(String(objectData.height));
        setWidthInput(String(objectData.width));
      } else {
        setHeightInput(String(objectData.width));
        setWidthInput(String(objectData.height));
      }
    }
  }, [objectData]);

  useEffect(() => {
    setObjectData(
      objectDimensionPopover
        ? file.undoable.objects.getObjectData(objectDimensionPopover)
        : null
    );
  }, [file.undoable.objects, objectDimensionPopover]);

  useEffect(() => {
    if (!objectDimensionPopover) {
      setSnapWarning(null);
    }
  }, [objectDimensionPopover]);

  if (!objectData || !objectDimensionPopover) return null;

  const PPU = file.sheet?.PPU || 1;
  const unit = file.sheet?.metric ? 'mm' : 'in';
  const wallThickness = (file.sheet?.cellSizePixels || 1) / PPU;
  const apparentWallThickness = (file.sheet?.cellSizePixels || 1) * view.zoom;
  const objectMeta = ObjectMetadata[objectData.objectKey];
  const hasWallOpening = objectMeta.wallOpening;
  const objectBounds = getObjectBrowserBounds(objectDimensionPopover);
  // const rotationIsSnapped = objectData.rotation % 90 === 0;
  const shiftX = 0;
  let previewWidth = objectData.width * PPU * view.zoom;
  let previewHeight = objectData.height * PPU * view.zoom;
  if (hasWallOpening) previewHeight *= 1.2;
  if (objectData.rotation === 90 || objectData.rotation === 270) {
    previewWidth = objectData.height * PPU * view.zoom;
    previewHeight = objectData.width * PPU * view.zoom;
    if (hasWallOpening) previewWidth *= 1.2;
  }

  const snapHeight = (
    height = parseFloat(heightInput),
    skipCheck?: boolean
  ) => {
    const isRotated = objectData.rotation === 90 || objectData.rotation === 270;
    const snapRule = !isRotated ? objectData.snapY : objectData.snapX;
    let snappedHeight = height;

    if (snapRule === ObjectSnap.grid) {
      snappedHeight =
        Math.round((height * PPU) / (file.sheet?.cellSizePixels || 1)) *
        wallThickness;
    } else if (snapRule === ObjectSnap.halfGrid) {
      snappedHeight =
        Math.round((height * PPU) / ((file.sheet?.cellSizePixels || 1) / 2)) *
        (wallThickness / 2);
    }

    if (objectMeta.preserveAspectRatio && !skipCheck) {
      const aspect =
        objectMeta.sizes.us[0].width / objectMeta.sizes.us[0].height;
      const newWidth = snappedHeight / aspect;
      snapWidth(newWidth, true);
    }

    setHeightInput(String(snappedHeight));
    return { snapped: String(snappedHeight), snapRule };
  };

  const snapWidth = (width = parseFloat(widthInput), skipCheck?: boolean) => {
    const isRotated = objectData.rotation === 90 || objectData.rotation === 270;
    const snapRule = !isRotated ? objectData.snapX : objectData.snapY;
    let snappedWidth = width;

    if (snapRule === ObjectSnap.grid) {
      snappedWidth =
        Math.round((width * PPU) / (file.sheet?.cellSizePixels || 1)) *
        wallThickness;
    } else if (snapRule === ObjectSnap.halfGrid) {
      snappedWidth =
        Math.round((width * PPU) / ((file.sheet?.cellSizePixels || 1) / 2)) *
        (wallThickness / 2);
    }

    if (objectMeta.preserveAspectRatio && !skipCheck) {
      const aspect =
        objectMeta.sizes.us[0].width / objectMeta.sizes.us[0].height;
      const newHeight = snappedWidth / aspect;

      snapHeight(newHeight, true);
    }

    setWidthInput(String(snappedWidth));
    return { snapped: String(snappedWidth), snapRule };
  };

  const handleBlur = (key: 'width' | 'height') => {
    const prevHeight = heightInput;
    const prevWidth = widthInput;
    if (key === 'height') {
      const snapResult = snapHeight();
      if (snapResult.snapped !== prevHeight) {
        setSnapWarning(snapResult.snapRule);
      } else {
        setSnapWarning(null);
      }
    } else {
      const snapResult = snapWidth();
      if (snapResult.snapped !== prevWidth) {
        setSnapWarning(snapResult.snapRule);
      } else {
        setSnapWarning(null);
      }
    }
  };

  const handleSave = () => {
    snapWidth();
    snapHeight(parseFloat(heightInput), true);
    const isRotated = objectData.rotation === 90 || objectData.rotation === 270;
    const newWidth = !isRotated
      ? parseFloat(widthInput)
      : parseFloat(heightInput);
    const newHeight = !isRotated
      ? parseFloat(heightInput)
      : parseFloat(widthInput);
    // @TODO: compute new x & y position
    file.undoable.objects.setObject({
      ...objectData,
      height: newHeight,
      width: newWidth,
    });
    closeObjectDimensionPopover();
  };

  const blurAll = () => {
    (document.activeElement as HTMLElement).blur();
  };

  return (
    <Wrapper
      top={objectBounds.topLeft.y - APP_BAR_HEIGHT_PX}
      left={objectBounds.topLeft.x + shiftX}
      minWidth={objectBounds.width * view.zoom}
      minHeight={objectBounds.height * view.zoom}
    >
      <Row>
        <PreviewWrapper width={previewWidth} height={previewHeight}>
          <div id="preview-inner-wrapper">
            <ObjectPreview
              objectKey={objectData.objectKey}
              height={objectData.height * PPU * view.zoom}
              width={objectData.width * PPU * view.zoom}
              objectData={objectData}
              wallThickness={apparentWallThickness}
            />
          </div>
        </PreviewWrapper>
        {(!objectMeta.lockHeight ||
          !(objectData.rotation === 0 || objectData.rotation === 180)) && (
          <>
            <DimensionHorizontal
              height={
                (objectData.rotation !== 90 && objectData.rotation !== 270
                  ? objectData.height
                  : objectData.width) *
                PPU *
                view.zoom
              }
              offset={
                hasWallOpening &&
                ((!objectData.flippedVertical && objectData.rotation === 180) ||
                  (objectData.flippedVertical && objectData.rotation === 0))
                  ? apparentWallThickness
                  : 0
              }
            />
            <DimensionInputWrapper
              height={
                (objectData.rotation !== 90 && objectData.rotation !== 270
                  ? objectData.height
                  : objectData.width) *
                PPU *
                view.zoom
              }
            >
              <DimensionInput
                type="number"
                value={heightInput}
                disabled={
                  objectData.rotation !== 90 && objectData.rotation !== 270
                    ? objectMeta.lockHeight
                    : false
                }
                onBlur={() => handleBlur('height')}
                onChange={(e) => {
                  setHeightInput(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    blurAll();
                  }
                }}
                style={
                  hasWallOpening &&
                  ((!objectData.flippedVertical &&
                    objectData.rotation === 180) ||
                    (objectData.flippedVertical && objectData.rotation === 0))
                    ? { marginTop: apparentWallThickness }
                    : undefined
                }
              />
              {unit}
            </DimensionInputWrapper>
          </>
        )}
      </Row>

      {(!objectMeta.lockHeight ||
        objectData.rotation === 0 ||
        objectData.rotation === 180) && (
        <>
          <Row>
            <DimensionVertical
              width={
                (objectData.rotation !== 90 && objectData.rotation !== 270
                  ? objectData.width
                  : objectData.height) *
                PPU *
                view.zoom
              }
              offset={
                hasWallOpening &&
                ((!objectData.flippedVertical && objectData.rotation === 90) ||
                  (objectData.flippedVertical && objectData.rotation === 270))
                  ? apparentWallThickness
                  : 0
              }
            />
          </Row>
          <Row>
            <DimensionWidthInputWrapper
              width={
                (objectData.rotation !== 90 && objectData.rotation !== 270
                  ? objectData.width
                  : objectData.height) *
                PPU *
                view.zoom
              }
            >
              <DimensionInput
                type="number"
                value={widthInput}
                onBlur={() => handleBlur('width')}
                disabled={
                  objectData.rotation === 90 || objectData.rotation === 270
                    ? objectMeta.lockHeight
                    : false
                }
                onKeyUp={(e) => {
                  if (e.key === 'Enter') {
                    blurAll();
                  }
                }}
                onChange={(e) => {
                  setWidthInput(e.target.value);
                }}
                style={
                  hasWallOpening &&
                  ((!objectData.flippedVertical &&
                    objectData.rotation === 90) ||
                    (objectData.flippedVertical && objectData.rotation === 270))
                    ? { marginLeft: apparentWallThickness }
                    : undefined
                }
              />
              {unit}
            </DimensionWidthInputWrapper>
          </Row>
        </>
      )}

      {snapWarning && (
        <Row>
          <WarningMessage>{`The dimension you entered was snapped to the nearest ${
            snapWarning === ObjectSnap.halfGrid ? 'half-' : ''
          }grid line.`}</WarningMessage>
        </Row>
      )}

      <Row>
        <button id="save-object-dimension" type="button" onClick={handleSave}>
          Save
        </button>
        <button type="button" onClick={() => closeObjectDimensionPopover()}>
          Cancel
        </button>
      </Row>
    </Wrapper>
  );
};

export default observer(ObjectDimensionPopover);

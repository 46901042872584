import React, { useCallback, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import mixpanel from 'mixpanel-browser';
import { auth, database } from '../firebase';
import { useLocalStore } from 'state';
import { ProjectsSnapshotIn } from 'state/models/UserProjects';

const AuthContext = React.createContext<{
  currentUser: firebase.User | null;
  userTier: number;
  signup: (email: string, password: string) => void;
  continueWithGoogle: () => void;
  login: (email: string, password: string) => void;
  sendPasswordResetEmail: (email: string) => void;
  signout: () => void;
  getUserProjects: () => void;
}>({
  currentUser: null,
  userTier: 0,
  signup: () => {},
  continueWithGoogle: () => {},
  login: () => {},
  sendPasswordResetEmail: () => {},
  signout: () => {},
  getUserProjects: () => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: React.FC = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<firebase.User | null>(null);
  const [userTier, setUserTier] = useState(0);
  const { ui, setUserProjects } = useLocalStore();

  const signup = async (email: string, password: string) => {
    await auth.createUserWithEmailAndPassword(email, password);
  };

  const login = async (email: string, password: string) => {
    await auth.signInWithEmailAndPassword(email, password);
  };

  const signout = async () => {
    await auth.signOut();
    setUserProjects(undefined);
  };

  const sendPasswordResetEmail = async (email: string) => {
    await auth.sendPasswordResetEmail(email);
  };

  const continueWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    await auth.signInWithPopup(provider);
  };

  const getUserProjects = useCallback(async () => {
    if (currentUser) {
      database.projects
        .where('userId', '==', currentUser.uid)
        .orderBy('modifiedAt', 'desc')
        .get()
        .then((querySnapshot) => {
          const projects: ProjectsSnapshotIn = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            projects.push({
              id: doc.id,
              title: data.title,
              modifiedAt: Number(data?.modifiedAt?.seconds || 1) * 1000,
            });
          });
          setUserProjects(projects);
        })
        .catch((error) => {
          console.log('Error getting documents: ', error);
        });
    }
  }, [currentUser, setUserProjects]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // check user tier
        database.users
          .doc(user.uid)
          .get()
          .then((res) => {
            const data = res.data();
            if (data?.tier || data?.tier === 0) {
              setUserTier(data.tier || 0);
              // set last login
              database.users.doc(user.uid).update({
                email: user.email || '',
                displayName: user.displayName || '',
                lastLogin: new Date(),
              });
            } else {
              // document doesn't exist
              database.users.doc(user.uid).set({
                userId: user.uid,
                tier: 0,
                email: user.email || '',
                displayName: user.displayName || '',
                lastLogin: new Date(),
              });
            }
          })
          .catch(() => {
            // document doesn't exist
            database.users.doc(user.uid).set({
              userId: user.uid,
              tier: 0,
              email: user.email || '',
              displayName: user.displayName || '',
              lastLogin: new Date(),
            });
          });
      } else {
        setUserTier(0);
      }
      setCurrentUser(user);
      if (user) {
        mixpanel.identify(user.uid);
        getUserProjects();
        ui.setWelcomeModal(false);
        ui.setLeftPanel(true);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [setUserProjects, getUserProjects, ui]);

  const value = {
    currentUser,
    userTier,
    signup,
    continueWithGoogle,
    login,
    signout,
    sendPasswordResetEmail,
    getUserProjects,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

import { observer } from 'mobx-react-lite';
import { useLocalStore } from 'state';
import {
  MOUSE_TOOLTIP_OFFSET_LEFT,
  MOUSE_TOOLTIP_OFFSET_TOP,
} from 'features/editor/config';
import { Wrapper } from './styles';

const MouseTooltip = () => {
  const { mouseTooltip, cursor, ui } = useLocalStore();

  if (!mouseTooltip.data) return null;
  const color = mouseTooltip.data.includes('°')
    ? ui.theme.mouseTooltipColor
    : ui.theme.dimensionColor;

  return (
    <Wrapper
      top={cursor.clientY + MOUSE_TOOLTIP_OFFSET_TOP}
      left={cursor.clientX + MOUSE_TOOLTIP_OFFSET_LEFT}
      color={color}
    >
      {mouseTooltip.data}
    </Wrapper>
  );
};

export default observer(MouseTooltip);
